import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd} from "@angular/router";

import * as $ from 'jquery';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  current_state: string = "";

  constructor(router:Router, activatedRoute:ActivatedRoute) { 
    router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        this.current_state = this.getCurrentState(router.routerState, router.routerState.root).join('-');
        console.log('current_state', this.current_state);
      }
    });
  }

  getCurrentState(state, parent) {
    var data = [];
    if(parent && parent.snapshot.data && parent.snapshot.data.stateName) {
      data.push(parent.snapshot.data.stateName);
    }

    if(state && parent) {
      data.push(... this.getCurrentState(state, state.firstChild(parent)));
    }
    return data;
  }

  	ngOnInit() {

      $(document).ready(function() {
    		$("#btn-toggler").click(function(e) {
          e.preventDefault();
          console.info("clicking the button");
          $("#div-toggler").toggleClass("moto-widget-menu-mobile-open");
        });

      }) 
  		 	

  	}

}
