import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-d3-core-transformers',
  templateUrl: './d3-core-transformers.component.html',
  styleUrls: ['./d3-core-transformers.component.css']
})
export class D3CoreTransformersComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

}
