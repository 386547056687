<section id="section-content" class="content page-3 moto-section" data-widget="section" data-container="section">
  	
	
	<div data-css-name="moto-container_content_56420832" class="moto-widget moto-widget-container moto-container_content_56420832" data-widget="container" data-container="container">
      <div class="moto-widget moto-widget-slider moto-preset-2 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-left-auto" data-widget="slider" data-preset="2" data-spacing="aala">
        <ul class="moto-widget-slider-list" data-moto-slider-options='{&quot;itemsCount&quot;:3,&quot;slideshowEnabled&quot;:true,&quot;slideshowDelay&quot;:5,&quot;slideshowAnimationType&quot;:&quot;fade&quot;,&quot;showNextPrev&quot;:false,&quot;showPaginationDots&quot;:true,&quot;showSlideCaptions&quot;:true}'>
          <li><img src="./assets/images/our-manufacturing-bannerimg.jpg" style="visibility: visible;" /></li>
          
        </ul>
      </div>
    </div>
	
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-12" data-container="container">
            <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default wow fadeInRight moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="lala">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p style="text-align: center;" class="moto-text_system_4">Our Manufacturing<br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
	
	
	
	
	
	<div data-css-name="moto-container_content_56431b79" class="moto-widget moto-widget-container moto-container_content_56431b79" data-widget="container" data-container="container">
      <div class="moto-widget moto-widget-spacer moto-preset-default  moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="mama">
	  	<div class="greybox">
			<span class="t1">5</span><span class="t2"> Manufacturing Units in India</span><br>
			<span class="t1">40</span> <span class="t2"> Years of Experience</span><br>
			<span class="t1">5,00,000+</span> <span class="t2"> Transformers in Network</span><br>
			<span class="t1">40+</span> <span class="t2"> Countries with Network of Agents</span><br>
		</div>
        <div class="moto-widget-spacer-block" style="height: 35px;"></div>
      </div>
      
    </div>
	
	<div class="clr40"></div>
	
	<div class="moto-widget-text-content moto-widget-text-editable">
		<div class="container">
			<div class="row">
				<p class="moto-text_normal">
		Kotsons has ISO 9001:2015, ISO 14001:2015 & OHSAS 45001:2018 certification, is setting new benchmarks in quality and versatility. The state-of-the-art KOTSONS manufacturing units are fully equipped with the latest automated machinery and backed by a proficient team of well experienced engineers practicing processes that produce best in class quality, leaving almost no room for insufficiencies. Whether Single/Three phase, Distribution & Power Transformers, Dry Type Nomex Transformer or Oil Filled CRGO Steel and Amorphous Metal Transformers, each product is uniquely designed according to individual factors such as voltage, power, climate, system topography, sound level and many more. <br><br>
Enforcing stringent safety measures at every step, KOTSONS offers a safe working environment for all its employees that in turn contributes to increased efficiency and quality produce. The in-house team focuses not only on manufacturing but continuously endeavors design and innovate cutting edge technology to deliver solutions that surpass customer expectations. <br><br>
Manufactured as per internationally recognised standards, KOTSONS are largest Indian suppliers of Oil-filled and Dry Type Nomex® CRGO Silicon Steel and Amorphous Metal Transformers.
		</p>
			</div>
		</div>
	</div>
	
	<div class="clr40"></div>
   
    <div class="container">

    <div class="row boderbottom">
                         
            
    <div class="col-lg-6 nospace wow fadeInLeft"><img src="./assets/images/our-manufacturing-img2.jpg" /></div>
      <div class="col-lg-6 nospace wow fadeInRight">
     
      <strong>Design</strong>

      <p align="justify">
        KOTSONS has a team of highly qualified, skilled and well experienced design engineers, software engineers and drafters, who are fully trained in advanced 2D & 3D CAD software and other areas. All 3D models are analyzed for dimensional accuracy & less mechanical stresses. Later shop floor drawings are auto generated from these 3D models. These drawings are compatible to our CNC HD Plasma Cutting machines and other most modern machines to manufacture the right component at first go.
      </p>
     <p align="justify">Software wing of our engineering department has successfully developed computer programs for Electrical design calculations enabling millions of iterative calculations converging to a very optimum design. This is done exclusively for National and International tenders where capitalization of losses parameters is vital. Kotsons vision is to achieve complete set of design calculations, bill of materials and constructional drawings, all with a single click of a mouse.</p>
     <p align="justify">KOTSONS has a variety of design options for different types of conductor and core materials. A massive data bank has already been established based on the previous test results.</p>
         	</div>
        	</div>

            
<div class="row boderbottom">
    <div class="col-lg-6 nospace2 wow fadeInLeft">
     <ol class="bold"><li>CORE - Amorphous (Wound)</li></ol>
        <p align="justify">The core is of high quality amorphous ribbon imported from Hitachi Metals (USA), having very low loss formed into wound cores of rectangular shape, bolted together to the frames firmly to prevent vibration and noise. The core is designed to ensure permanency of the core loss with continuous working of transformers. Suitable provisions are made in the bottom core clamp / bottom plate of the transformer to arrest the movement of active part. 
</p>
		<ol>
        	<li>With the use of Berger machine, Precise dimensional control is achieved in core cutting along with stacking, resulting in bonded edge for core integrity and thereby increasing the transformer’s reliability.</li>
            <li>The assembled amorphous cores are then annealed in inert atmosphere for stress relieving and lower eddy current losses.</li>
            <li>All assembled cores are tested for pre no load losses before proceeding for core coil assembly process.</li>
        </ol>
        </div>
 <div class="col-lg-6 nospace2 wow fadeInRight"><img src="./assets/images/our-manufacturing-img9.jpg"></div>
         	        	</div>
                        
  
  <div class="row boderbottom">
  <div class="col-lg-6 nospace wow fadeInLeft"><img src="./assets/images/our-manufacturing-img4.jpg"></div>
    <div class="col-lg-6 nospace2 wow fadeInRight">
     	<ol start="2" class="bold"><li>CORE - CRGO (Stacked/Wound/3D Wound)</li></ol>
        <p align="justify">The core is of high quality silicon steel (Si-Fe) coated with carlite procured from internationally reputed brands, having low loss and formed into wound cores of rectangular shapes or cut-to-length stacked form with step-lap construction, bolted together to the frames firmly to prevent vibration and noise. The core is designed to ensure permanency of the core loss with continuous working of the transformers. Suitable provisions are made in the bottom core clamp / bottom plate of the transformer to arrest the movement of active part. 
</p>
		<ol>
        	<li>With the use of Uni-Core machine for wound cores, diagonal slitting & close core loops Winding machine for 3D wound cores & CNC cut-to-length machine for stacked cores with precise dimensional control is achieved in core cutting / forming along with winding / stacking resulting in burr-free edge for core integrity and thereby increasing the transformer’s reliability.
</li>
            <li>The assembled wound cores are than annealed in inert atmosphere for stress relieving and lower eddy current losses.</li>
            <li>All assembled cores are tested for pre no load losses before proceeding for core coil assembly process.</li>
        </ol>
        </div>
        </div>  
        
 <div class="row boderbottom">       
 	     	<div class="col-lg-6 nospace wow fadeInLeft">
     		<strong>3. Winding</strong>
     					<p align="justify">HV and LV windings are wound from Copper / Aluminum conductors with
polyesterimide enamel (Class B) insulation / DPC. The enamel covering conform
to IEC 63017 Part-8. The windings are progressively wound (HV over LV) in single
one piece rectangular / circular construction for better voltage regulation and
mechanical strength. The inter layer insulation is of Epoxy Dotted Paper. Winding
are done in clean atmosphere to prevent possible accumulation of dust particles.
The coils are further processed for dimensional control through automatic roller
pressing winding machine, which improves bonding and short circuit withstanding
capability. The provision of ducts in winding are made in order to control
temperature gradient and rise for both winding & oil.</p>
     					<p align="justify">Windings can be made with Copper / Aluminum with Foil / Strip / Round wire of
any combination to suit customer requirements. Accurately located taps and a
large winding cross section keeps unbalanced ampere-turns to minimum.
Unbalanced ampere-turns create forces during short circuits that push the high
voltage and low voltage coils apart vertically. By minimizing
the ampere-turn unbalance, vertical forces are correspondingly reduced, thus
making the design stronger under short circuit stresses. The large areas are
presented by the layer-type winding result in a low ground capacity, which gives a
nearly straight line surge distribution throughout the winding.</p>
     					<p align="justify">All windings are checked and tested for all quality parameters and quality reports
for windings & material characteristics data are maintained for verification.</p>
         	</div>
  <div class="col-lg-6 nospace2 wow fadeInRight"><img src="./assets/images/our-manufacturing-img8.jpg"></div>          
        	</div>
            
            
   	<div class="row boderbottom">
  	<div class="col-lg-6 nospace wow fadeInLeft"><img src="./assets/images/our-manufacturing-img1.jpg"></div>
   	<div class="col-lg-6 nospace2 wow fadeInRight">
     	<strong>4.	Core Coil Assembly</strong>
        <p align="justify">Core Coil Assembly is done in two methods, according to shape of the core. In stack core construction, core is kept steady in vertical direction and coils are lowered from the top. Whereas in wound core construction, coils are kept steady in horizontal direction and core is inserted into the coils. In case of 3D core coil assembly, the core is fixed on a special winding machine and the winding takes place on each core limb individually.  
</p>
		<p align="justify">Core & Coil assembly for distribution / power transformers uses special insulating materials like crepe paper tubes & multi paper covered conductors for Oil-filled and fiberglass & Nomex® insulation for Dry Type and are very strong and robust in construction to withstand short-circuit forces. They are adequately clamped with the tank so that no movement is possible within the tank because of transport jerks or short-circuit forces.</p>
        <p align="justify">After CCA is ready then HV/LV routing is done in accordance with the required Vector Group and type of bushing / cable termination.</p>
        </div>
        </div>   
        
    <div class="row boderbottom">
  	
   	<div class="col-lg-6 nospace wow fadeInLeft">
     	<strong>5.	Tank Up</strong>
        <p align="justify">Oil-filled - The core and coil assembly is dried in an oven at a temperature of 90°C - 110°C, to improve insulation resistance and remove ingress of moisture in the insulation materials. The dried core coil assembly is boxed up in a pre-fabricated MS Tank, and vacuum process is applied for filling oil in the transformer tanks. Oil-filled in transformer will be PCB free Mineral oil / Midel 7131 / Silicon oil / Ester oil / Nynas Nytro etc. as per customer’s requirements.
</p>
		<p align="justify">Dry Type - The core and coil assembly is enclosed in metallic enclosure designed and fabricated in accordance with NEMA or customer’s specification and HV/LV connections are covered with heat shrinkable sleeves & epoxy terminal boards.</p>
         
        </div>
     <div class="col-lg-6 nospace2 wow fadeInRight"><img src="./assets/images/our-manufacturing-img6.jpg"></div>
        </div>   
                                  
          
          <div class="row boderbottom">
  	<div class="col-lg-6 nospace wow fadeInLeft"><img src="./assets/images/our-manufacturing-img7.jpg"></div>
   	<div class="col-lg-6 nospace2 wow fadeInRight">
     	<strong>6.	Testing & Inspection</strong>
        <p align="justify">All Routine / Type tests as per the relevant standards / customer specifications are conducted. Every transformer is pressure tested, and then, the job is finished and kept ready for dispatch. Special tests like noise test / seismic test / partial discharge etc. can be done at third party labs like KEMA / CPRI / ERDA etc. as per customer requirements.</p>

        </div>
        </div> 
        
        <div class="row">
  	
         	<div class="col-lg-6 wow fadeInLeft">
           	<strong>7.	Packing & Dispatch</strong>
            <p align="justify">
              KOTSONS has complete in-house facilities for packing the finished goods with modern machinery compatible for any kind of transport (i.e. road / rail / sea etc.). Third Party inspection as per customer requirements and fumigation in containers and according to International Standards for Phytosanitary Measures-15 (ISPM-15) is done for all our exports.
            </p>
          </div>

          <div class="col-lg-6 nospace2 wow fadeInRight">
            <img src="./assets/images/our-manufacturing-img5.jpg">
          </div>

        </div> 
         
        
      </div>
   
	  <!-- </div> -->

    
    <!--<div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-6" data-container="container">
            <div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default wow fadeInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                
                </p>
              </div>
            </div> 
            <div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default wow fadeInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              <div class="moto-widget-text-content moto-widget-text-editable">
               
		<img src="./assets/images/our-manufacturing-img2.jpg"><br><br>
		
		<p class="moto-text_normal">
			<strong class="moto-color3">1.	CORE - Amorphous (Wound)</strong><br>
The core is of high quality amorphous ribbon imported from Hitachi Metals (USA), having very low loss formed into wound cores of rectangular shape, bolted together to the frames firmly to prevent vibration and noise. The core is designed to ensure permanency of the core loss with continuous working of transformers. Suitable provisions are made in the bottom core clamp / bottom plate of the transformer to arrest the movement of active part. <br><br>

1.With the use of Berger machine, Precise dimensional control is achieved in core cutting along with stacking, resulting in bonded edge for core integrity and thereby increasing the transformer’s reliability.<br>
2.The assembled amorphous cores are then annealed in inert atmosphere for stress relieving and lower eddy current losses.<br>
3.All assembled cores are tested for pre no load losses before proceeding for core coil assembly process.<br>
</ol>
		</p>
		
		
		<div class="clr20"></div>
		<hr>
		<img src="./assets/images/our-manufacturing-img3.jpg"><br><br>
		
		<p class="moto-text_normal">
			<strong class="moto-color3">1.	CORE - Amorphous (Wound)</strong><br>

The core is of high quality amorphous ribbon imported from Hitachi Metals (USA), having very low loss formed into wound cores of rectangular shape, bolted together to the frames firmly to prevent vibration and noise. The core is designed to ensure permanency of the core loss with continuous working of  transformers. Suitable provisions are made in the bottom core clamp / bottom plate of the transformer to arrest the movement of active part. <br><br>
1.	With the use of Berger machine, Precise  dimensional control is achieved in core cutting along with stacking, resulting in bonded edge 	for core integrity and thereby increasing the transformer’s reliability.<br><br>
2.	The assembled amorphous cores are then annealed in inert 	atmosphere for stress relieving and lower eddy current losses.<br><br>
3.	All assembled cores are tested for pre no load losses before proceeding 	for core coil assembly process.
		</p>
		
		<div class="clr20"></div>
		
		
		<img src="./assets/images/our-manufacturing-img4.jpg"><br><br>
		
		<p class="moto-text_normal">
			<strong class="moto-color3">2.	CORE - CRGO (Stacked/Wound/3D Wound)</strong><br>

The core is of high quality silicon steel (Si-Fe) coated with carlite procured from internationally reputed brands, having low loss and formed into wound cores of rectangular shapes or cut-to-length stacked form with step-lap construction, bolted together to the frames firmly to prevent vibration and noise. The core is designed to ensure permanency of the core loss with continuous working of the transformers. Suitable provisions are made in the bottom core clamp / bottom plate of the transformer to arrest the movement of active part. <br><br>
1.	With the use of Uni-Core machine for wound cores, diagonal slitting & close core loops Winding machine for 3D wound cores & CNC cut-to-length machine for stacked cores with precise dimensional control is achieved in core cutting / forming along with winding / stacking resulting in burr-free edge for core integrity and thereby increasing the transformer’s reliability.<br><br>
2.	The assembled wound cores are than annealed in inert atmosphere for stress relieving and lower eddy current losses.<br><br>
3.	All assembled cores are tested for pre no load losses before proceeding for core coil assembly process.
		</p>
		
		<div class="clr20"></div>
		
		
		<img src="./assets/images/our-manufacturing-img8.jpg"><br><br>
		
		<p class="moto-text_normal">
			<strong class="moto-color3">3.	Winding</strong><br>

HV and LV windings are wound from Copper / Aluminum conductors with polyesterimide enamel (Class B) insulation / DPC. The enamel covering conform to IEC 63017 Part-8. The windings are progressively wound (HV over LV) in single one piece rectangular / circular construction for better voltage regulation and mechanical strength. The inter layer insulation is of Epoxy Dotted Paper. Winding are done in clean atmosphere to prevent possible accumulation of dust particles. The coils are further processed 
		</p>
		
		
		
              </div>
            </div> 
          </div>
          <div class="moto-cell col-sm-6" data-container="container">
            <div class="moto-widget moto-widget-image moto-preset-default moto-align-center moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto wow fadeInRight" data-preset="default" data-spacing="aasa" data-widget="image">
              
			  
			  
			  <p class="moto-text_normal">
			  
<strong class="moto-color3">Design</strong><br>			  	
KOTSONS has a team of highly qualified, skilled and well experienced design engineers, software engineers and drafters, who are fully trained in advanced 2D & 3D CAD software and other areas. All 3D models are analyzed for dimensional accuracy & less mechanical stresses. Later shop floor drawings are auto generated from these 3D models. These drawings are compatible to our CNC HD Plasma Cutting machines and other most modern machines to manufacture the right component at first go.<br>
Software wing of our engineering department has successfully developed computer programs for Electrical design calculations enabling millions of iterative calculations converging to a very optimum design. This is done exclusively for National and International tenders where capitalization of losses parameters is vital. Kotsons vision is to achieve complete set of design calculations, bill of materials and constructional drawings, all with a single click of a mouse.<br>
KOTSONS has a variety of design options for different types of conductor and core materials. A massive data bank has already been established based on the previous test results.<br>


			  </p>
			  
		<div class="clr20"></div>
			  <img src="./assets/images/our-manufacturing-img1.jpg" class="moto-widget-image-picture" data-id="134" title=""  alt="" draggable="false"> 
			  
			  
			  <div class="clr20"></div>
			  
			  
			  <p class="moto-text_normal">
			  
			  	
 <strong class="moto-color3">4.	Core Coil Assembly</strong><br>
Core Coil Assembly is done in two methods, according to shape of the core. In stack core construction, core is kept steady in vertical direction and coils are lowered from the top. Whereas in wound core construction, coils are kept steady in horizontal direction and core is inserted into the coils. In case of 3D core coil assembly, the core is fixed on a special winding machine and the winding takes place on each core limb individually. <br><br>
Core & Coil assembly for distribution / power transformers uses special insulating materials like crepe paper tubes & multi paper covered conductors for Oil-filled and fiberglass & Nomex® insulation for Dry Type and are very strong and robust in construction to withstand short-circuit forces. They are adequately clamped with the tank so that no movement is possible within the tank because of transport jerks or short-circuit forces.<br><br>
After CCA is ready then HV/LV routing is done in accordance with the required Vector Group and type of bushing / cable termination.


			  </p>
			  
		<div class="clr20"></div>
			  <img src="./assets/images/our-manufacturing-img6.jpg" class="moto-widget-image-picture" data-id="134" title=""  alt="" draggable="false"> 
			  
			  
			  
			  
		<div class="clr20"></div>
		
		
		 <p class="moto-text_normal">
			  
			  	
 <strong class="moto-color3">5.	Tank Up</strong><br>
 
Oil-filled - The core and coil assembly is dried in an oven at a temperature of 90°C - 110°C, to improve insulation resistance and remove ingress of moisture in the insulation materials. The dried core coil assembly is boxed up in a pre-fabricated MS Tank, and vacuum process is applied for filling oil in the transformer tanks. Oil-filled in transformer will be PCB free Mineral oil / Midel 7131 / Silicon oil / Ester oil / Nynas Nytro etc. as per customer’s requirements.<br><br>
Dry Type - The core and coil assembly is enclosed in metallic enclosure designed and fabricated in accordance with NEMA or customer’s specification and HV/LV connections are covered with heat shrinkable sleeves & epoxy terminal boards.
			  </p>
			  
		<div class="clr20"></div>
			  <img src="./assets/images/our-manufacturing-img7.jpg" class="moto-widget-image-picture" data-id="134" title=""  alt="" draggable="false"> 
			  
			  
			  
			  <div class="clr20"></div>
		
		
		 <p class="moto-text_normal">
			  
			  	
<strong class="moto-color3">6.	Testing & Inspection</strong><br>
All Routine / Type tests as per the relevant standards / customer specifications are conducted. Every transformer is pressure tested, and then, the job is finished and kept ready for dispatch. Special tests like noise test / seismic test / partial discharge etc. can be done at third party labs like KEMA / CPRI / ERDA etc. as per customer requirements.			  </p>
			  
		<div class="clr20"></div>
			  <img src="./assets/images/our-manufacturing-img5.jpg" class="moto-widget-image-picture" data-id="134" title=""  alt="" draggable="false"> 
			  
			  
			  
		<div class="clr20"></div>
		
		<p class="moto-text_normal">
			  
			  	
<strong class="moto-color3">7.	Packing & Dispatch</strong><br>
KOTSONS has complete in-house facilities for packing the finished goods with modern machinery compatible for any kind of transport (i.e. road / rail / sea etc.). Third Party inspection as per customer requirements and fumigation in containers and according to International Standards for Phytosanitary Measures-15 (ISPM-15) is done for all our exports. </p>
			  
            </div>
		</div>
		
		
		
		  
		  
		  
		  
        </div>
		
		
		
		
      </div>
    </div> --> 
    
    <div class="clr40"></div>
</section>