<div class="alert alert-success alert-msg" *ngIf="showSuccess == true">
  <strong>Success!</strong> {{ successMsg }}
</div>

<div class="alert alert-danger alert-msg" *ngIf="showError == true">
  <strong>Danger!</strong> {{ errorMsg }}
</div>

<section id="section-content" class="content page-3 moto-section" data-widget="section" data-container="section">
<div class="moto-widget moto-widget-row row-fixed" data-widget="row"><div class="container-fluid">
<div class="row">
<div class="moto-cell col-sm-12" data-container="container">
<div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default wow fadeInRight moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="lala">
    <div class="moto-widget-text-content moto-widget-text-editable"><p style="text-align: center;" class="moto-text_system_4">Apply Online<br></p></div>
</div>

<div class="moto-widget moto-widget-contact_form moto-preset-default moto-widget-empty moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto " data-preset="default" data-widget="contact_form" data-type="" data-spacing="aala">

<form class="moto-widget-contact_form-form" role="form" name="contactForm" id="contactForm" onSubmit="return(validate());" [formGroup]="applyOnlineForm" (ngSubmit)="submitApplyOnline();" ngNativeValidate>

      <div class="moto-widget-contact_form-group">
	  
      <div class="row applyform">
	  	<div class="col-lg-10 col-md-offset-1">
			<div class="col-lg-6">
				<div class="label">Name</div>
				<div><input type="text" name="name" formControlName="name" required /></div>
			</div>
			<div class="col-lg-6">
				<div class="label">Phone</div>
				<div><input type="text" name="mobile" id="number" maxlength="12" formControlName="mobile" required /></div>

				<!-- onKeyPress="return isNumber(event)" -->
			</div>
			<div class="clr30"></div>
			<div class="col-lg-6">
				<div class="label">Date of Birth</div>
				<div><input type="date" class="custom-input-box" name="dob"  id="datepicker" formControlName="dob" required /></div>
			</div>
			<div class="col-lg-6">
				<div class="label">Email</div>
				<div><input type="email" name="email" formControlName="email" required style="width: 100%;border: 1px solid #b2b3b3!important;padding: 9px 17px 10px 16px;outline: none!important;" />
				</div>
			</div>
			<div class="clr30"></div>
			<div class="col-lg-6">
				<div class="label">Marital Status&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<input type="radio" name="marital_status" id="maritalStatus" value="0" formControlName="marital_status">&nbsp;Married&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<input type="radio" name="marital_status" id="maritalStatus" value="1" formControlName="marital_status">&nbsp;Unmarried</div>
			</div>
			<div class="clr30"></div>
			<div class="col-lg-6">
				<div class="label">State</div>
				<div><input type="text" name="state" formControlName="state" required /></div>
			</div>
			<div class="col-lg-6">
				<div class="label">City</div>
				<div><input type="text" name="city" formControlName="city" required /></div>
			</div>
			<div class="clr30"></div>
			<div class="col-lg-6">
				<div class="label">Work Experience</div>
				<div>Year&nbsp;&nbsp;&nbsp;&nbsp;
					<select class="year" name="exp_year" formControlName="exp_year" required>
						<option selected="">-Year-</option>
				  		<option value="1">1</option>
					  	<option value="2">2</option>
					  	<option value="3">3</option>
					  	<option value="4">4</option>
					  	<option value="5">5</option>
					  	<option value="6">6</option>
					  	<option value="7">7</option>
					  	<option value="8">8</option>
					  	<option value="9">9</option>
					  	<option value="10">10</option>
					  	<option value="above 10">above 10 Year</option>

					</select>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Month&nbsp;&nbsp;&nbsp;&nbsp;
					<select class="year" name="exp_month" formControlName="exp_month" required>
					  	<option selected="">-Month-</option>
					  	<option value="1">1</option>
					  	<option value="2">2</option>
					  	<option value="3">3</option>
					  	<option value="4">4</option>
					  	<option value="5">5</option>
			 		 	<option value="6">6</option>
					  	<option value="7">7</option>
					  	<option value="8">8</option>
					  	<option value="9">9</option>
					  	<option value="10">10</option>
					  	<option value="11">11</option>
				  
					</select>
				</div>

			</div>
			<div class="col-lg-6">
				<div class="label">Post Applied</div>
				<div><input type="text" name="post_applied" formControlName="post_applied" required /><!--<select name="post">
				  <option value="">-Select-</option>
				  <option value="post1">post1</option>
				  <option value="post2">post2</option>
				  <option value="post3">post3</option>
				</select>--></div>
			</div>
			<div class="clr30"></div>
			<div class="col-lg-6">
				<div class="label">Department</div>
				<div><input type="text" name="department" formControlName="department" required /></div>
			</div>
			<div class="col-lg-6">
				<div class="label">Education</div>
				<div><input type="text" name="education" formControlName="education" required /><!--<select name="education">
				  <option value="">-Select-</option>
				  <option value="matriculation">Matriculation</option>
				  <option value="intermediate">Intermediate</option>
				  <option value="Graduation">Graduation</option>
                  <option value="Post-Graduation">Post-Graduation</option>
                  <option value="Post-Graduation">Others</option>
				</select>--></div>
			</div>
			<div class="clr30"></div>
			<div class="col-lg-6">
				
				<div class="label">Upload Resume</div>
				<div>
					<input type="file" id="image" name="image" class="form-input" placeholder='File' (change)="uploadFile($event)" required />
				</div>

			</div>
			<div class="col-lg-6">
				<div>
				  <button type="submit" name="button" id="button" value="Submit" class="moto-widget-button-link moto-size-medium moto-link">
				  	Submit
				  </button>
				</div>
                
			</div>
		</div>
	  </div>
	   

         </div>
      
      
      
    </form>
  </div>
</div>



</div></div></div>









  
</section>