<section id="section-content" class="content page-3 moto-section" data-widget="section" data-container="section">

	<div class="container-fluid">

		<div data-css-name="moto-container_content_56432492" class="moto-widget moto-widget-container moto-container_content_56432492" data-widget="container" data-container="container">
			<div class="moto-widget moto-widget-row row-fixed" data-widget="row">
				<div class="container-fluid">
					<div class="row">
						<div class="moto-cell col-sm-12" data-container="container">
							<div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default                     wow fadeInLeft moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="lala">
								<div class="moto-widget-text-content moto-widget-text-editable">
									<p style="text-align: center; text-transform:uppercase;" class="moto-text_system_4">Current openings<br>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="moto-widget moto-widget-row row-fixed" data-widget="row">
				<div class="container-fluid">
					<div class="row">
						<div class="moto-cell col-sm-4" data-container="container">
							<div data-animation="fadeInUp" class="moto-widget moto-widget-text moto-preset-default                    wow fadeInUp moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aasa">
								<div class="moto-widget-text-content moto-widget-text-editable">
									<p class="moto-text_system_6">GM - Works<br>
									</p>
								</div>
							</div>
							<div data-animation="fadeInUp" class="moto-widget moto-widget-text moto-preset-default                  wow fadeInUp moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
								<div class="moto-widget-text-content moto-widget-text-editable">
									<p class="moto-text_system_7">LOCATION: <a class="moto-link" data-action="url" href="https://goo.gl/maps/UGjEibuFyA82" target="_self">Alwar</a><br>
									</p>
								</div>
							</div>
							<div data-animation="fadeInUp" class="moto-widget moto-widget-text moto-preset-default                  wow fadeInUp moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sama">
								<div class="moto-widget-text-content moto-widget-text-editable">
									<p class="moto-text_normal"><b>JOB POSTED ON:</b> 26 Dec’15</p>
								</div>
							</div>
							<div class="moto-widget moto-widget-button moto-preset-4 moto-align-left moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto wow fadeInUp" data-widget="button" data-preset="4" data-spacing="aama"> 
								<a href="" routerLink="/apply"  data-action="page" class="moto-widget-button-link moto-size-medium moto-link"
								data-anchor=""
								data-size="medium">
									<span class="fa moto-widget-theme-icon"></span> <span class="moto-widget-button-label">APPLY</span>
								</a> 
							</div>
						</div>

						<div class="moto-cell col-sm-4" data-container="container">
							<div data-animation="fadeInUp" class="moto-widget moto-widget-text moto-preset-default                   wow fadeInUp moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aasa">
								<div class="moto-widget-text-content moto-widget-text-editable">
									<p class="moto-text_system_6">ASM/RSM - Works<br>
									</p>
								</div>
							</div>

							<div data-animation="fadeInUp" class="moto-widget moto-widget-text moto-preset-default                 wow fadeInUp moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
								<div class="moto-widget-text-content moto-widget-text-editable">
									<p class="moto-text_system_7">LOCATION: <a class="moto-link" data-action="url" href="https://goo.gl/maps/UGjEibuFyA82" target="_self">Alwar</a></p>
								</div>
							</div>

							<div data-animation="fadeInUp" class="moto-widget moto-widget-text moto-preset-default                  wow fadeInUp moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sama">
								<div class="moto-widget-text-content moto-widget-text-editable">
									<p class="moto-text_normal"><b>JOB POSTED ON:</b> 26 Dec’15<br>
									</p>
								</div>
							</div>

							<div class="moto-widget moto-widget-button moto-preset-4 moto-align-left moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto wow fadeInUp" data-widget="button" data-preset="4" data-spacing="aama"> <a href="" routerLink="/apply"  data-action="page" class="moto-widget-button-link moto-size-medium moto-link"
								data-anchor=""
								data-size="medium"><span class="fa moto-widget-theme-icon"></span> <span class="moto-widget-button-label">APPLY</span></a> 
							</div>
						</div>

						<div class="moto-cell col-sm-4" data-container="container">
							<div data-animation="fadeInUp" class="moto-widget moto-widget-text moto-preset-default                   wow fadeInUp moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aasa">
								<div class="moto-widget-text-content moto-widget-text-editable">
									<p class="moto-text_system_6">Asst. Manager / D.M.<br>
									</p>
								</div>
							</div>

							<div data-animation="fadeInUp" class="moto-widget moto-widget-text moto-preset-default                 wow fadeInUp moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
								<div class="moto-widget-text-content moto-widget-text-editable">
									<p class="moto-text_system_7">LOCATION: <a class="moto-link" data-action="url" href="https://goo.gl/maps/UGjEibuFyA82" target="_self">Alwar</a><br>
									</p>
								</div>
							</div>

							<div data-animation="fadeInUp" class="moto-widget moto-widget-text moto-preset-default                  wow fadeInUp moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sama">
								<div class="moto-widget-text-content moto-widget-text-editable">
									<p class="moto-text_normal"><b>JOB POSTED ON:</b> 26 Dec’15<br>
									</p>
								</div>
							</div>

							<div class="moto-widget moto-widget-button moto-preset-4 moto-align-left moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto wow fadeInUp" data-widget="button" data-preset="4" data-spacing="aala"> <a href="" routerLink="/apply"  data-action="page" class="moto-widget-button-link moto-size-medium moto-link"
								data-anchor=""
								data-size="medium"><span class="fa moto-widget-theme-icon"></span> <span class="moto-widget-button-label">APPLY</span></a> 
							</div>
							<div class="moto-widget moto-widget-spacer moto-preset-default                            moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="saaa">
								<div class="moto-widget-spacer-block" style="height: 1px;"></div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>

</section>