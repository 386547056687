<section id="section-content" class="content page-3 moto-section" data-widget="section" data-container="section">
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-12" data-container="container">
            <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default                              wow fadeInRight moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="lala">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p style="text-align: center;" class="moto-text_system_4">About us<br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-6" data-container="container">
            <div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default wow fadeInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_normal text-justify">KPL (KOTSONS Pvt. Ltd.) established its transformer manufacturing unit
back in 1979 at Alwar, India to produce distribution & power transformers of
33 KV class voltage, to meet the needs of public utilities organizations and
of private industrial sectors in India. It was KPL’s vision of becoming
integrated global transformer manufacturing company that the company's
manufacturing facilities were later expanded and second & third plant was
setup at Agra (U.P) and Bazpur (Uttaranchal), India and product range was
expanded up to 25 MVA with 33 KV class, to cater the needs of
international demands.<br /><br />
Today, with over more than three & half decades of experience in the
manufacturing, KPL has grown in geographical reach, market size and
product range to become one of the leading transformer manufacturing
company. Our products comprehensively fulfilled the requirements of local
and international standard specifications and their stringent requirements.<br /><br />
Kotsons has ISO 9001:2015, ISO 14001:2015 & OHSAS 45001:2018 certification and commits itself to
introduce and maintain a quality system that ensures quality products and
services to total satisfaction of the customer. The quality control department
controls and monitor all quality control documents and carries out its
inspection at all strategic points in the production process. TQM (Total
Quality Management) programme is in place, which is backed by strong in
house R&D and a crew of service engineers for providing after sales
services.<br /><br />
At transformer's quality & soundness point, KPL's transformers upto 1600
kVA are type tested for temperature rise test, impulse test and short circuit
test at world's renowned testing laboratory KEMA, THE NETHERLANDS.<br /><br />
At KPL, R&D is a continuous process and department has consistently
produced innovative concepts that have now become industry standards. </p>
              </div>
            </div>
          </div>
          <div class="moto-cell col-sm-6" data-container="container">
            <div class="moto-widget moto-widget-image moto-preset-default moto-align-center moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto wow fadeInRight" data-preset="default" data-spacing="aasa" data-widget="image">
              <div class="moto-widget-image-link"> <img src="./assets/images/mt-0234-about-us-img1.jpg" class="moto-widget-image-picture" data-id="134" title=""  alt="" draggable="false"> </div>

            </div>
            <p class="moto-text_normal text-justify">A highly qualified and experienced technical personnel keeps an eye upon
the latest development in technology and the product to supply a prime
quality product at competitive price.<br /><br />
KPL has in house facilities for conducting all routine tests as per IEC, DIN,
BS, ANSI, SABS, BIS, CENELEC, GOST, NEMA & IS and temperature rise
type test.<br /><br />
<strong>New Era : Transformers to grab Green Energy</strong><br /><br />
We are proud to announce that our R&D Team has developed super low
loss amorphous/CRGO transformers to cater SOLAR & WIND power
sector. These are with special designs and fittings to sustain load variations
and to meet stringent customer requirements & standards.
</p>
          </div>
        </div>
      </div>
    </div>
    <div class="moto-widget moto-widget-row" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-12" data-container="container">
            <div class="moto-widget moto-widget-spacer moto-preset-default                            moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="laaa">
              <div class="moto-widget-spacer-block" style="height: 1px;"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div data-css-name="moto-container_content_56433db6" class="moto-widget moto-widget-container moto-container_content_56433db6" data-widget="container" data-container="container">
      <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
        <div class="container-fluid">
          <div class="row">
            <div class="moto-cell col-sm-12" data-container="container">
              <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default                           wow fadeInRight moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="lala">
                <div class="moto-widget-text-content moto-widget-text-editable">
                  <p style="text-align: center;" class="moto-text_system_4">Infrastructure<br>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
        <div class="container">
          <div class="row">
          <table class="table table-striped aboutustbl">
    <thead>
      <tr>
        <th width="25%">PLANT</th>
        <th width="25%">YEAR</th>
        <th width="25%">Land Area<br>(Sq. Mtrs.)</th>
		<th width="25%">Covered Area<br>(Sq.Ft.)</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>ALWAR (Unit-1)</td>
        <td>1979 </td>
        <td>6000</td>
		<td>45000</td>
      </tr>
      <tr>
        <td>AGRA (Unit-1)</td>
        <td>1991</td>
        <td>8800</td>
		<td>60000</td>
      </tr>
<!--       <tr>
        <td>AGRA (Unit-2)</td>
        <td>2005</td>
        <td>3200</td>
		<td>25000</td>
      </tr> -->
	  <tr>
        <td>BAZPUR</td>
        <td>2006</td>
        <td>26000</td>
		<td>70000</td>
      </tr>
      <tr>
        <td>ALWAR (Unit-2)</td>
        <td>2008 </td>
        <td>50000</td>
		<td>150000</td>
      </tr>
    </tbody>
  </table>  
            
            
          </div>
        </div>
      </div>
      
      <div class="clr60"></div>
    </div>
    <div data-stellar-background-ratio="0.5" data-css-name="moto-container_content_564319e0" class="moto-widget moto-widget-container moto-parallax" data-widget="container" data-container="container" style="overflow:hidden; height:720px; background:#abafae;">
    <div id="container_video" align="center">
    		<video autoplay loop muted >
                    <source src="./assets/video/Kotsons-Timeline-Video.mp4" type="video/mp4">
					<!--<source src="video/KotsonsTimeline.webm" type="video/webm">-->
                </video>
      			<div class="content_video" style="padding-top:230px;">
                    We have rich experience in<br> exporting
                </div>
      </div>
      
    </div>
    <!--<div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-12" data-container="container">
            <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default                      wow fadeInRight moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="lala">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p style="text-align: center;" class="moto-text_system_4">Our team<br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!--<div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-3" data-container="container">
            <div class="moto-widget moto-widget-image moto-preset-default moto-align-center moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto " data-preset="default" data-spacing="aama" data-widget="image">
              <div class="moto-widget-image-link"> <img src="./assets/images/mt-0234-about-us-team-img1.jpg" class="moto-widget-image-picture" data-id="136" title=""  alt="" draggable="false"> </div>
            </div>
            <div class="moto-widget moto-widget-text moto-preset-default                      moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="mama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_system_9">PAWAN KUMAR JAIN<br>
                </p>
              </div>
            </div>
            <div class="moto-widget moto-widget-text moto-preset-default                      moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_normal">Managing Director<br>
                </p>
              </div>
            </div>
          </div>
          <div class="moto-cell col-sm-3" data-container="container">
            <div class="moto-widget moto-widget-image moto-preset-default moto-align-center moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto " data-preset="default" data-spacing="aama" data-widget="image">
              <div class="moto-widget-image-link"> <img src="./assets/images/mt-0234-about-us-team-img2.jpg" class="moto-widget-image-picture" data-id="137" title=""  alt="" draggable="false"> </div>
            </div>
            <div class="moto-widget moto-widget-text moto-preset-default                       moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="mama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_system_9">MOHNISH JAIN<br>
                </p>
              </div>
            </div>
            <div class="moto-widget moto-widget-text moto-preset-default                     moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_normal">Managing Director<br>
                </p>
              </div>
            </div>
          </div>
          <div class="moto-cell col-sm-3" data-container="container">
            <div class="moto-widget moto-widget-image moto-preset-default moto-align-center moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto " data-preset="default" data-spacing="aama" data-widget="image">
              <div class="moto-widget-image-link"> <img src="./assets/images/mt-0234-about-us-team-img3.jpg" class="moto-widget-image-picture" data-id="138" title=""  alt="" draggable="false"> </div>
            </div>
            <div class="moto-widget moto-widget-text moto-preset-default                      moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="mama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_system_9">NAME<br>
                </p>
              </div>
            </div>
            <div class="moto-widget moto-widget-text moto-preset-default                     moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_normal">Designation<br>
                </p>
              </div>
            </div>
          </div>
          <div class="moto-cell col-sm-3" data-container="container">
            <div class="moto-widget moto-widget-image moto-preset-default moto-align-center moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto " data-preset="default" data-spacing="aama" data-widget="image">
              <div class="moto-widget-image-link"> <img src="./assets/images/mt-0234-about-us-team-img4.jpg" class="moto-widget-image-picture" data-id="139" title=""  alt="" draggable="false"> </div>
            </div>
            <div class="moto-widget moto-widget-text moto-preset-default                      moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="mama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_system_9">NAME<br>
                </p>
              </div>
            </div>
            <div class="moto-widget moto-widget-text moto-preset-default                     moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aala">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_normal">Designation<br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
   
    
   
    
  </section>