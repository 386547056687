<section id="section-content" class="content page-3 moto-section" data-widget="section" data-container="section">
<div class="moto-widget moto-widget-row row-fixed" data-widget="row"><div class="container-fluid">
<div class="row">
<div class="moto-cell col-sm-12" data-container="container">
<div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default                              wow fadeInRight moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="lala">
    <div class="moto-widget-text-content moto-widget-text-editable"><p style="text-align: center;" class="moto-text_system_4">Current openings<br></p></div>
</div>



<div class="moto-widget moto-widget-row row-fixed" data-widget="row">
        <div class="container">
          <div class="row">
          <table class="table table-striped openings">
    <thead>
      <tr>
        <th width="25%" style="padding-top:10px;">JOB POSTED ON</th>
        <th width="50%">POSITION</th>
        <th width="25%">LOCATION</th>
      </tr>
    </thead>
    <!--<tbody>
      <tr>
        <td>26 Dec’15</td>
        <td>GM - Works</td>
        <td>Alwar</td>
      </tr>
      <tr>
        <td>26 Dec’15</td>
        <td>ASM/RSM - Works</td>
        <td>Alwar</td>
      </tr>
      <tr>
        <td>26 Dec’15</td>
        <td>Asst. Manager / Deputy Manager</td>
        <td>Alwar</td>
      </tr>
    </tbody>-->
  </table>  
            
            
          </div>
        </div>
      </div>


<div class="clr100"></div>


</div></div></div></div>









  
</section>