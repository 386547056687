import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chairman-message',
  templateUrl: './chairman-message.component.html',
  styleUrls: ['./chairman-message.component.css']
})
export class ChairmanMessageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  
  }

}
