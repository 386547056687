<section id="section-content" class="content page-3 moto-section" data-widget="section" data-container="section">
  	
	
	
	
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-12" data-container="container">
            <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default                              wow fadeInRight moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="lala">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p style="text-align: center;" class="moto-text_system_4">Completely Self Protected<br>
Distribution Transformers<br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-6" data-container="container">
            
            <div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default                              wow fadeInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_normal">
				
				
				Applicable Standards : IEC / ANSI /BS / IS / EN /GOST <br>
Wide range of CSP transformers in accordance to above standards with different combinations can be manufactured. The CSP transformers are generally designed and manufactured to comply with the IEC publications 60076. CSP feature can be provided in the following range of transformers.<br><br>
•	Single Phase: 5kVA-167 kVA,  <br>
•	Three Phase: 10 kVA-400 kVA,<br><br>
<strong class="moto-color3">Insulation level   £ 12 KV   £ 24 KV  £ 36 KV</strong><br>
•	Indoor / Outdoor installation<br>
•	Conductor : Copper or Aluminum as per customer requirement<br>
•	CRGO / Amorphous as per customer requirement<br>
•	Hermetically sealed type (with gas cushion / fully filled) / Conventional type<br>&nbsp; (with Conservator) <br>
•	Color : as per customer requirement<br>
•	Cooling arrangement : Corrugation or Pressed steel Radiators<br>
•	Maximum ambient temperature : as per customer specification<br>
•	Top oil Temperature rise : as per customer specification<br>
•	Average winding temperature rise : as per customer specification<br><br>

<strong class="moto-color3">Concept of CSP transformers</strong><br>
CSP technology is more known for high performance distribution transformers which mitigate the operation and maintenance problems associated with conventional transformers. CSP technology enables a transformer to protect itself from secondary faults, lightning protection, persistent severe overloads, and provides visual warning of the overload conditions. CSP technology also protects the distribution system to which it is connected, in case of its failure. This means, that in case of failure of the transformer, it gets isolated from the system.<br><br>

<strong class="moto-color3">Components and benefits of the CSP System</strong><br>
CSP System has four essential components. They are:<br>
1.	Oil immersed Circuit Breaker for overload and secondary fault 	protection<br>
2.	Signal Light for indication during overload condition on transformer<br>
3.	HV Fuse for over-current, protection by isolating a failed transformer 	from the system<br>


				
				
				</p><br><br>
		<img src="./assets/images/csp-img2.jpg"><br><br>
		
		<p class="moto-text_normal">
<strong class="moto-color3">Oil Immersed Secondary (Low Voltage) Circuit Breaker </strong><br>
The oil immersed circuit breaker is installed on the secondary (low voltage) side of the transformer. It provides the entire over current protection to the transformer and responds to secondary faults external to
		</p>
              </div>
            </div>
          </div>
          <div class="moto-cell col-sm-6" data-container="container">
            <div class="moto-widget moto-widget-image moto-preset-default moto-align-center moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto wow fadeInRight" data-preset="default" data-spacing="aasa" data-widget="image">
              <div class="moto-widget-image-link"> <img src="./assets/images/csp-img1.jpg" class="moto-widget-image-picture" data-id="134" title=""  alt="" draggable="false"> </div>
			  
			  
			  
			  
			  <div class="clr20"></div>
			  
			  <p class="moto-text_normal">
			  



the transformer by tripping and prevents any thermal damage occurring to the transformer.<br><br>
Besides protecting from secondary fault, the breaker also provides thermal protection to the transformer. It is an electro mechanical device with three major elements. These elements are:<br><br>
•	Temperature sensing through the use of bimetallic strips which are 	built into the breaker such that the load current flows through them. 	The bimetallic strips responds thermally to the temperature of the 	transformer oil and also to the temperature changes created by the 	flow of the load current through them<br>
•	Latching and tripping functions<br>
•	The signal light latch<br>
• 	The emergency control assembly<br>
• 	The magnetic trip device<br>
• 	It works as Current interrupter<br><br>

<strong class="moto-color3">Signal Light</strong><br>
The signal circuit is mechanically connected to the circuit breaker latching and bimetal systems through an auxiliary contact. A signal light is mounted on the wall of the transformer tank. It gives a visual external indication when the transformer oil temperature reaches a specified preset value of temperature during overload condition. Once the signal light glows then it can be turned off only by manually operating the external handle of the circuit breaker.<br><br>

<strong class="moto-color3">Primary (High Voltage Fuse) Expulsion Fuse for System Protection</strong><br>
In a CSP transformer, the primary fuse is placed in oil and connected in series with the primary winding.  This expulsion fuse is to protect that part of the electrical distribution system, which is ahead of the transformer from faults which occur inside of the distribution transformer. If a fault occurs in the windings or some other part of the transformer, it will cause abnormally large currents to flow, resulting in the fuse to melt open and clear the circuit. Thus, the fault is limited only to those customers who are served by this particular transformer and service is maintained on the rest of the system.<br><br>

<strong class="moto-color3">Surge Arrester</strong><br>
The surge arrester are mounted near to the high voltage bushings to shorten the ground lead connection between the arrester and the transformer reducing the lightning impulse voltage stress on the transformer winding. The surge arrester diverts the flow of surge to earth by changing its impedance characteristics from high resistance to low resistance.




			  </p>
            </div>
		</div>
		
		
		
		  
		  
		  
		  
        </div>
		
		
		
		
      </div>
    </div>
    <div class="moto-widget moto-widget-row" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-12" data-container="container">
            <div class="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="laaa">
              <div class="moto-widget-spacer-block" style="height: 1px;"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
	
	
	
	
	<div data-stellar-background-ratio="0.5" data-css-name="moto-container_content_56436675" class="moto-widget moto-widget-container moto-parallax moto-container_content_56436675" data-widget="container" data-container="container">
    <div class="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="lama">
      <div class="moto-widget-spacer-block" style="height: 35px;"></div>
    </div>
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-2" data-container="container"></div>
          <div class="moto-cell col-sm-8" data-container="container">
            <div data-animation="fadeIn" class="moto-widget moto-widget-text moto-preset-default wow fadeIn moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sasa">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p style="text-align: center;" class="moto-text_system_5">Quality service<br>
                </p>
                <p style="text-align: center;" class="moto-text_system_5">is our aim<br>
                </p>
              </div>
            </div>
          </div>
          <div class="moto-cell col-sm-2" data-container="container"></div>
        </div>
      </div>
    </div>
    <div class="moto-widget moto-widget-spacer moto-preset-default              moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="lama">
      <div class="moto-widget-spacer-block" style="height: 35px;"></div>
    </div>
  </div>
    </section>