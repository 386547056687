import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  	constructor(private http: HttpClient) { }

  	uploadFile(file_data) {
  		// console.log(file_data);

		let headers: HttpHeaders = new HttpHeaders();
		// headers = headers.append( 'Content-Type', 'application/json' );
	  	// headers = headers.append('Authorization', 'Bearer ' + this.auth_hash);

		return this.http.post<any>(environment.apiUrl + '/api/files', file_data, { "headers": headers } );
  	}

  	submitContact(contact_data) {

		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append( 'Content-Type', 'application/json' );
		// headers = headers.append( 'Authorization', 'Bearer ' + this.auth_hash );

		return this.http.post<any>(environment.apiUrl + "/api/contacts", contact_data, { "headers": headers });

	}

	submitApplyOnline(apply_data) {

		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append( 'Content-Type', 'application/json' );
		// headers = headers.append( 'Authorization', 'Bearer ' + this.auth_hash );

		return this.http.post<any>(environment.apiUrl + "/api/apply", apply_data, { "headers": headers });

	}


}
