<section id="section-content" class="content page-5 moto-section" data-widget="section" data-container="section">
  <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
    <div class="container-fluid">
      <div class="row">
        <div class="moto-cell col-sm-12" data-container="container">
          <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default wow fadeInRight moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="lala">
            <div class="moto-widget-text-content moto-widget-text-editable">
              <p style="text-align: center;" class="moto-text_system_4">Products overview<br>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
    <div class="container-fluid">
      <div class="row">
        <div class="moto-cell col-sm-4" data-container="container">
          <div class="moto-widget moto-widget-image moto-preset-default moto-align-center moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto " data-preset="default" data-spacing="aama" data-widget="image">
            <div class="moto-widget-image-link"> <img src="./assets/images/mt-0234-products-img1.jpg" class="moto-widget-image-picture" data-id="153" title=""  alt="" draggable="false"> </div>
          </div>
          <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sasa">
            <div class="moto-widget-text-content moto-widget-text-editable">
              <p class="moto-text_system_14"><a class="moto-link" data-action="url" href="" routerLink="/single-phase-transformers" target="_self">COMPLETELY SELF PROTECTED
DISTRIBUTION TRANSFORMERS</a></p>
            </div>
          </div>
          <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sama">
            <div class="moto-widget-text-content moto-widget-text-editable">
              <p class="moto-text_normal">Wide range of CSP transformers in accordance to above<br>
              </p>
			  <div class="clr20"></div>
            </div>
          </div>
          <div class="moto-widget moto-widget-button moto-preset-3 moto-align-left moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto " data-widget="button" data-preset="3" data-spacing="aala"> <a href="" routerLink="/single-phase-transformers"   data-action="page" class="moto-widget-button-link moto-size-medium moto-link"
            data-anchor=""
         data-size="medium"><span class="fa moto-widget-theme-icon"></span> <span class="moto-widget-button-label">READ MORE</span></a> </div>
          <div class="moto-widget moto-widget-spacer moto-preset-default" data-widget="spacer" data-preset="default" data-spacing="">
            <div class="moto-widget-spacer-block" style="height: 8px;"></div>
          </div>
        </div>
        <div class="moto-cell col-sm-4" data-container="container">
          <div class="moto-widget moto-widget-image moto-preset-default moto-align-center moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto " data-preset="default" data-spacing="aama" data-widget="image">
            <div class="moto-widget-image-link"> <img src="./assets/images/mt-0234-products-img2.jpg" class="moto-widget-image-picture" data-id="154" title=""  alt="" draggable="false"> </div>
          </div>
          <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sasa">
            <div class="moto-widget-text-content moto-widget-text-editable">
              <p class="moto-text_system_14"><a class="moto-link" data-action="url" href="" routerLink="/three-phase-transformers" target="_self">THREE PHASE DISTRIBUTION
TRANSFORMERS</a></p>
            </div>
          </div>
          <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sama">
            <div class="moto-widget-text-content moto-widget-text-editable">
              <p class="moto-text_normal">KOTSONS manufactures a wide range of distribution and medium power transformers</p>
			  <div class="clr20"></div>
            </div>
          </div>
          <div class="moto-widget moto-widget-button moto-preset-3 moto-align-left moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto " data-widget="button" data-preset="3" data-spacing="aala"> <a href="" routerLink="/three-phase-transformers"   data-action="page" class="moto-widget-button-link moto-size-medium moto-link"
            data-anchor=""
         data-size="medium"><span class="fa moto-widget-theme-icon"></span> <span class="moto-widget-button-label">READ MORE</span></a> </div>
          <div class="moto-widget moto-widget-spacer moto-preset-default" data-widget="spacer" data-preset="default" data-spacing="">
            <div class="moto-widget-spacer-block" style="height: 8px;"></div>
          </div>
        </div>
        <div class="moto-cell col-sm-4" data-container="container">
          <div class="moto-widget moto-widget-image moto-preset-default moto-align-center moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto " data-preset="default" data-spacing="aama" data-widget="image">
            <div class="moto-widget-image-link"> <img src="./assets/images/mt-0234-products-img3.jpg" class="moto-widget-image-picture" data-id="155" title=""  alt="" draggable="false"> </div>
          </div>
          <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sasa">
            <div class="moto-widget-text-content moto-widget-text-editable">
              <p class="moto-text_system_14"><a class="moto-link" data-action="url" href="" routerLink="/special-purpose-transformers" target="_self">POWER TRANSFORMERS</a></p>
            </div>
          </div>
          <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sama">
            <div class="moto-widget-text-content moto-widget-text-editable">
              <p class="moto-text_normal">KOTSONS manufactures a wide range of power transformers upto 25 MVA with voltage </p>
			  <div class="clr20"></div>
            </div>
          </div>
          <div class="moto-widget moto-widget-button moto-preset-3 moto-align-left moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto " data-widget="button" data-preset="3" data-spacing="aala"> <a href="" routerLink="/special-purpose-transformers"   data-action="page" class="moto-widget-button-link moto-size-medium moto-link"
            data-anchor=""
         data-size="medium"><span class="fa moto-widget-theme-icon"></span> <span class="moto-widget-button-label">READ MORE</span></a> </div>
          <div class="moto-widget moto-widget-spacer moto-preset-default" data-widget="spacer" data-preset="default" data-spacing="">
            <div class="moto-widget-spacer-block" style="height: 8px;"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
    <div class="container-fluid">
      <div class="row">
        <div class="moto-cell col-sm-4" data-container="container">
          <div class="moto-widget moto-widget-image moto-preset-default moto-align-center moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto " data-preset="default" data-spacing="aama" data-widget="image">
            <div class="moto-widget-image-link"> <img src="./assets/images/mt-0234-products-img4.jpg" class="moto-widget-image-picture" data-id="156" title=""  alt="" draggable="false"> </div>
          </div>
          <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sasa">
            <div class="moto-widget-text-content moto-widget-text-editable">
              <p class="moto-text_system_14"><a class="moto-link" data-action="url" href="" routerLink="/3d-core-transformers" target="_self">AMORPHOUS CORE TRANSFORMERS</a></p>
			  <div class="clr20"></div>
            </div>
          </div>
          <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sama">
            <div class="moto-widget-text-content moto-widget-text-editable">
              <p class="moto-text_normal">KOTSONS has set up state-of-the-art amorphous metal core transformer manufacturing facility</p>
			  <div class="clr20"></div>
            </div>
          </div>
          <div class="moto-widget moto-widget-button moto-preset-3 moto-align-left moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto " data-widget="button" data-preset="3" data-spacing="aala"> <a href="" routerLink="/3d-core-transformers"  data-action="page" class="moto-widget-button-link moto-size-medium moto-link"
            data-anchor=""
         data-size="medium"><span class="fa moto-widget-theme-icon"></span> <span class="moto-widget-button-label">READ MORE</span></a> </div>
          <div class="moto-widget moto-widget-spacer moto-preset-default" data-widget="spacer" data-preset="default" data-spacing="">
            <div class="moto-widget-spacer-block" style="height: 8px;"></div>
          </div>
        </div>
        <div class="moto-cell col-sm-4" data-container="container">
          <div class="moto-widget moto-widget-image moto-preset-default moto-align-center moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto " data-preset="default" data-spacing="aama" data-widget="image">
            <div class="moto-widget-image-link"> <img src="./assets/images/mt-0234-products-img5.jpg" class="moto-widget-image-picture" data-id="157" title=""  alt="" draggable="false"> </div>
          </div>
          <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sasa">
            <div class="moto-widget-text-content moto-widget-text-editable">
              <p class="moto-text_system_14"><a class="moto-link" data-action="url" href="" routerLink="/power-transformers" target="_self">3D CORE TRANSFORMERS</a></p>
			  <div class="clr20"></div>
            </div>
          </div>
          <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sama">
            <div class="moto-widget-text-content moto-widget-text-editable">
              <p class="moto-text_normal">KOTSONS Pvt. Ltd., as a part of their continuous efforts towards promotion </p>
			  <div class="clr20"></div>
            </div>
          </div>
          <div class="moto-widget moto-widget-button moto-preset-3 moto-align-left moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto " data-widget="button" data-preset="3" data-spacing="aala"> <a href="" routerLink="/power-transformers"   data-action="page" class="moto-widget-button-link moto-size-medium moto-link"
            data-anchor=""
         data-size="medium"><span class="fa moto-widget-theme-icon"></span> <span class="moto-widget-button-label">READ MORE</span></a> </div>
          <div class="moto-widget moto-widget-spacer moto-preset-default" data-widget="spacer" data-preset="default" data-spacing="">
            <div class="moto-widget-spacer-block" style="height: 8px;"></div>
          </div>
        </div>
        <div class="moto-cell col-sm-4" data-container="container">
          <div class="moto-widget moto-widget-image moto-preset-default moto-align-center moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto " data-preset="default" data-spacing="aama" data-widget="image">
            <div class="moto-widget-image-link"> <img src="./assets/images/mt-0234-products-img6.jpg" class="moto-widget-image-picture" data-id="158" title=""  alt="" draggable="false"> </div>
          </div>
          <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sasa">
            <div class="moto-widget-text-content moto-widget-text-editable">
              <p class="moto-text_system_14">
                <a class="moto-link" data-action="url" href="" routerLink="/dry-type-transformers" target="_self">DRY TYPE TRANSFORMERS
                </a>
			           <!-- <div class="clr20"></div> -->
              </p>
            </div>
          </div>
          <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sama">
            <div class="moto-widget-text-content moto-widget-text-editable">
              <p class="moto-text_normal">KOTSONS was one of the first Indian manufacturer to use the DuPont ReliatraN®</p>
			  <div class="clr20"></div>
            </div>
          </div>
          <div class="moto-widget moto-widget-button moto-preset-3 moto-align-left moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto " data-widget="button" data-preset="3" data-spacing="aala"> <a href="" routerLink="/dry-type-transformers" data-action="page" class="moto-widget-button-link moto-size-medium moto-link"
            data-anchor=""
         data-size="medium"><span class="fa moto-widget-theme-icon"></span> <span class="moto-widget-button-label">READ MORE</span></a> </div>
          <div class="moto-widget moto-widget-spacer moto-preset-default" data-widget="spacer" data-preset="default" data-spacing="">
            <div class="moto-widget-spacer-block" style="height: 8px;"></div>
          </div>
        </div>
		<div class="clr"></div>
        <div class="moto-cell col-sm-4" data-container="container">
          <div class="moto-widget moto-widget-image moto-preset-default moto-align-center moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto " data-preset="default" data-spacing="aama" data-widget="image">
            <div class="moto-widget-image-link"> <img src="./assets/images/mt-0234-products-img7.jpg" class="moto-widget-image-picture" data-id="158" title=""  alt="" draggable="false"> </div>
          </div>
          <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sasa">
            <div class="moto-widget-text-content moto-widget-text-editable">
              <p class="moto-text_system_14"><a class="moto-link" data-action="url" href="" routerLink="/amorphous-core-transformers" target="_self">SPECIAL PURPOSE TRANSFORMERS</a></p>
			  <div class="clr20"></div>
            </div>
          </div>
          <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sama">
            <div class="moto-widget-text-content moto-widget-text-editable">
              <p class="moto-text_normal">Special Purpose Transformers (SPMs) are explained by its name itself</p>
			  <div class="clr20"></div>
            </div>
          </div>
          <div class="moto-widget moto-widget-button moto-preset-3 moto-align-left moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto " data-widget="button" data-preset="3" data-spacing="aala"> <a href="" routerLink="/amorphous-core-transformers"   data-action="page" class="moto-widget-button-link moto-size-medium moto-link"
            data-anchor=""
         data-size="medium"><span class="fa moto-widget-theme-icon"></span> <span class="moto-widget-button-label">READ MORE</span></a> </div>
          <div class="moto-widget moto-widget-spacer moto-preset-default" data-widget="spacer" data-preset="default" data-spacing="">
            <div class="moto-widget-spacer-block" style="height: 8px;"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div data-stellar-background-ratio="0.5" data-css-name="moto-container_content_56436675" class="moto-widget moto-widget-container moto-parallax moto-container_content_56436675" data-widget="container" data-container="container">
    <div class="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="lama">
      <div class="moto-widget-spacer-block" style="height: 35px;"></div>
    </div>
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-2" data-container="container"></div>
          <div class="moto-cell col-sm-8" data-container="container">
            <div data-animation="fadeIn" class="moto-widget moto-widget-text moto-preset-default wow fadeIn moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sasa">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p style="text-align: center;" class="moto-text_system_5">Quality service<br>
                </p>
                <p style="text-align: center;" class="moto-text_system_5">is our aim<br>
                </p>
              </div>
            </div>
          </div>
          <div class="moto-cell col-sm-2" data-container="container"></div>
        </div>
      </div>
    </div>
    <div class="moto-widget moto-widget-spacer moto-preset-default              moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="lama">
      <div class="moto-widget-spacer-block" style="height: 35px;"></div>
    </div>
  </div>
  
</section>