<section id="section-content" class="content page-3 moto-section" data-widget="section" data-container="section">
  	
	
	
	
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-12" data-container="container">
            <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default                              wow fadeInRight moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="lala">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p style="text-align: center;" class="moto-text_system_4">Power Transformers
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-6" data-container="container">
            
            <div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default                              wow fadeInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_normal">
				<strong class="moto-color3">Applicable Standards : IEC / ANSI /BS / IS / SABS/ CENELEC /GOST </strong><br>
KOTSONS manufactures wide range of power transformers upto 25 MVA with voltage class of 36 kV. These transformers are generally free breathing type. Oil filled in transformer is PCB free Mineral Oil / Midel 7131 / Silicon Oil / Nynas Nytro, etc. as per customer’s requirements. Wide range of transformers in accordance to above standards with different combinations can be manufactured. The transformers are generally designed and manufactured to comply with the IEC publications 60076. <br><br>

<strong class="moto-color3">From 3150 kVA to 25000 kVA</strong><br>
<strong class="moto-color3">Insulation level  £ 24 kV  £ 36 kV</strong><br>
•	Indoor / Outdoor installation<br>
•	Conductor : Copper or Aluminum as per customer’s requirement<br>
•	Core : Generally CRGO type <br>
•	Amorphous core type can be supplied up to 5000 kVA<br>
•	3 D Core type can be supplied up to 5000 kVA <br>
•	Maximum ambient temperature : as per customer’s specification<br>
•	Top Oil Temperature rise : as per customer’s specification<br>
•	Average winding temperature rise : as per customer’s specification<br>
•	Conventional Conservator Free Breathing type (with dehumidifier / with<br>
	&nbsp;&nbsp;air cell (Bladders))
•	Color : as per customer’s requirement<br>
•	Cooling : ONAN/ONAF as per customer’s requirement<br>
•	Cooling arrangement : Corrugation or Pressed Steel Radiators<br>
•	Altitude : as per customer’s requirement<br>
•	On Load Tap Changer with RTCC & AVR, can be provided above 500 kVA, 
	if<br> &nbsp;&nbsp;required<br>
•	Safety devices can also be provided upon request<br>
•	Other optional fittings as required can be provided upon request


				
				
				</p><br><br>
		<br><br>
		
		
              </div>
            </div>
          </div>
          <div class="moto-cell col-sm-6" data-container="container">
            <div class="moto-widget moto-widget-image moto-preset-default moto-align-center moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto wow fadeInRight" data-preset="default" data-spacing="aasa" data-widget="image">
              <div class="moto-widget-image-link"> <img src="./assets/images/power-transformers-img1.jpg" class="moto-widget-image-picture" data-id="134" title=""  alt="" draggable="false"> </div>
			  
			  <div class="clr30"></div>
			  
			  <div class="moto-widget-image-link"> <img src="./assets/images/power-transformers-img2.jpg" class="moto-widget-image-picture" data-id="134" title=""  alt="" draggable="false"> </div>
			  
			  
			  
			  
			  
			  
			  
            </div>
		</div>
		
		
		
		  
		  
		  
		  
        </div>
		
		
		
		
      </div>
    </div>
    <div class="moto-widget moto-widget-row" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-12" data-container="container">
            <div class="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="laaa">
              <div class="moto-widget-spacer-block" style="height: 1px;"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
	
	
	
	
	<div data-stellar-background-ratio="0.5" data-css-name="moto-container_content_power" class="moto-widget moto-widget-container moto-parallax moto-container_content_power" data-widget="container" data-container="container">
    <div class="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="lama">
      <div class="moto-widget-spacer-block" style="height: 35px;"></div>
    </div>
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-2" data-container="container"></div>
          <div class="moto-cell col-sm-8" data-container="container">
            <div data-animation="fadeIn" class="moto-widget moto-widget-text moto-preset-default wow fadeIn moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sasa">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p style="text-align: center;" class="moto-text_system_5">Quality service<br>
                </p>
                <p style="text-align: center;" class="moto-text_system_5">is our aim<br>
                </p>
              </div>
            </div>
          </div>
          <div class="moto-cell col-sm-2" data-container="container"></div>
        </div>
      </div>
    </div>
    <div class="moto-widget moto-widget-spacer moto-preset-default              moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="lama">
      <div class="moto-widget-spacer-block" style="height: 35px;"></div>
    </div>
  </div>
</section>