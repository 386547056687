<section id="section-content" class="content page-3 moto-section" data-widget="section" data-container="section">
<div class="moto-widget moto-widget-row row-fixed" data-widget="row"><div class="container-fluid">
<div class="row">
<div class="moto-cell col-sm-12" data-container="container">
<div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default                              wow fadeInRight moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="lala">
    <div class="moto-widget-text-content moto-widget-text-editable"><p style="text-align: center; text-transform:uppercase;" class="moto-text_system_4">Apply online<br></p></div>
</div>

<div class="moto-widget moto-widget-contact_form moto-preset-default moto-widget-empty moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto " data-preset="default" data-widget="contact_form" data-type="" data-spacing="aala">
  <div ng-controller="widget.ContactForm.Controller" ng-init="hash = '1@yEv/OnWWz7d2wmFA34GjQB9bV9RfnQ5jQs83aPxVqOP3mTmEedmCQf1eO4CTH84OuLVjKh31cyzTD39R/2TETv8LvoenxnFo1jk9+DT25hQk7697qBuUqdrPssNZqbwC12zv+1QBoBjLtF18MJqI1v59S0QU2MjB1GcJpALwiG8='" class="ng-scope">
    <form class="moto-widget-contact_form-form ng-pristine ng-invalid ng-invalid-required" role="form" name="contactForm" ng-submit="submit()" novalidate>
      <div ng-show="sending" class="contact-form-loading ng-hide"></div>
      <div class="moto-widget-contact_form-group">
        <label for="field_name" class="moto-widget-contact_form-label">Name</label>
        <input type="text" class="moto-widget-contact_form-field moto-widget-contact_form-input ng-pristine ng-valid ng-touched" placeholder="Name " ng-model-options="{ updateOn: 'blur' }" name="name" id="field_name" ng-model="message.name">
        <span class="moto-widget-contact_form-field-error ng-hide" ng-show="contactForm.name.$invalid &amp;&amp; !contactForm.name.$pristine &amp;&amp; !contactForm.name.emailInvalid">Field is required</span> <span class="moto-widget-contact_form-field-error ng-hide" ng-show="contactForm.name.emailInvalid &amp;&amp; !contactForm.name.$pristine">Incorrect email</span> 
        </div>
        
        
      <div class="moto-widget-contact_form-group">
        <label for="field_email" class="moto-widget-contact_form-label">Email</label>
        <input type="text" class="moto-widget-contact_form-field moto-widget-contact_form-input ng-pristine ng-untouched ng-invalid ng-invalid-required" placeholder="Email *" ng-blur="validate('email')" required ng-model-options="{ updateOn: 'blur' }" name="email" id="field_email" ng-model="message.email">
        <span class="moto-widget-contact_form-field-error ng-hide" ng-show="contactForm.email.$invalid &amp;&amp; !contactForm.email.$pristine &amp;&amp; !contactForm.email.emailInvalid">Field is required</span> <span class="moto-widget-contact_form-field-error ng-hide" ng-show="contactForm.email.emailInvalid &amp;&amp; !contactForm.email.$pristine">Incorrect email</span> </div>
      <div class="moto-widget-contact_form-group">
        <label for="field_phone" class="moto-widget-contact_form-label">Phone</label>
        <input type="text" class="moto-widget-contact_form-field moto-widget-contact_form-input ng-pristine ng-untouched ng-valid" placeholder="Phone " ng-model-options="{ updateOn: 'blur' }" name="phone" id="field_phone" ng-model="message.phone">
        <span class="moto-widget-contact_form-field-error ng-hide" ng-show="contactForm.phone.$invalid &amp;&amp; !contactForm.phone.$pristine &amp;&amp; !contactForm.phone.emailInvalid">Field is required</span> <span class="moto-widget-contact_form-field-error ng-hide" ng-show="contactForm.phone.emailInvalid &amp;&amp; !contactForm.phone.$pristine">Incorrect email</span> </div>
        
      <div class="moto-widget-contact_form-group">
        <label for="field_name" class="moto-widget-contact_form-label">Date of Birth</label>
        <input type="text" class="moto-widget-contact_form-field moto-widget-contact_form-input ng-pristine ng-valid ng-touched" placeholder="Date of Birth " ng-model-options="{ updateOn: 'blur' }" name="name" id="field_dob" ng-model="message.name">
        <span class="moto-widget-contact_form-field-error ng-hide" ng-show="contactForm.name.$invalid &amp;&amp; !contactForm.name.$pristine &amp;&amp; !contactForm.name.emailInvalid">Field is required</span> <span class="moto-widget-contact_form-field-error ng-hide" ng-show="contactForm.name.emailInvalid &amp;&amp; !contactForm.name.$pristine">Incorrect email</span> 
        </div> 
      
      <div class="moto-widget-contact_form-group">
        <label for="field_name" class="moto-widget-contact_form-label">Marital Status</label>
        <input type="text" class="moto-widget-contact_form-field moto-widget-contact_form-input ng-pristine ng-valid ng-touched" placeholder="Marital Status " ng-model-options="{ updateOn: 'blur' }" name="name" id="field_mstatus" ng-model="message.name">
        <span class="moto-widget-contact_form-field-error ng-hide" ng-show="contactForm.name.$invalid &amp;&amp; !contactForm.name.$pristine &amp;&amp; !contactForm.name.emailInvalid">Field is required</span> <span class="moto-widget-contact_form-field-error ng-hide" ng-show="contactForm.name.emailInvalid &amp;&amp; !contactForm.name.$pristine">Incorrect email</span> 
        </div>  
        
        <div class="moto-widget-contact_form-group">
        <label for="field_name" class="moto-widget-contact_form-label">Post Applied</label>
        <select class="moto-widget-contact_form-field moto-widget-contact_form-input ng-pristine ng-valid ng-touched">
        	<option selected="selected">Post Applied</option>
            <option>ASM/RSM - Works</option>
            <option>GM - Works</option>
            <option>Asst. Manager / DM</option>
        </select>
        <span class="moto-widget-contact_form-field-error ng-hide" ng-show="contactForm.name.$invalid &amp;&amp; !contactForm.name.$pristine &amp;&amp; !contactForm.name.emailInvalid">Field is required</span> <span class="moto-widget-contact_form-field-error ng-hide" ng-show="contactForm.name.emailInvalid &amp;&amp; !contactForm.name.$pristine">Incorrect email</span> 
        </div>
        
        <div class="moto-widget-contact_form-group">
        <label for="field_name" class="moto-widget-contact_form-label">Education</label>
        <input type="text" class="moto-widget-contact_form-field moto-widget-contact_form-input ng-pristine ng-valid ng-touched" placeholder="Education" ng-model-options="{ updateOn: 'blur' }" name="name" id="field_education" ng-model="message.name">
        <span class="moto-widget-contact_form-field-error ng-hide" ng-show="contactForm.name.$invalid &amp;&amp; !contactForm.name.$pristine &amp;&amp; !contactForm.name.emailInvalid">Field is required</span> <span class="moto-widget-contact_form-field-error ng-hide" ng-show="contactForm.name.emailInvalid &amp;&amp; !contactForm.name.$pristine">Incorrect email</span> 
        </div> 
        
        
        <div class="moto-widget-contact_form-group">
        <label for="field_name" class="moto-widget-contact_form-label">Work Experience</label>
        <input type="text" class="moto-widget-contact_form-field moto-widget-contact_form-input ng-pristine ng-valid ng-touched" placeholder="Work Experience" ng-model-options="{ updateOn: 'blur' }" name="name" id="field_work_experience" ng-model="message.name">
        <span class="moto-widget-contact_form-field-error ng-hide" ng-show="contactForm.name.$invalid &amp;&amp; !contactForm.name.$pristine &amp;&amp; !contactForm.name.emailInvalid">Field is required</span> <span class="moto-widget-contact_form-field-error ng-hide" ng-show="contactForm.name.emailInvalid &amp;&amp; !contactForm.name.$pristine">Incorrect email</span> 
        </div> 
        
        <div class="moto-widget-contact_form-group">
        <label for="field_name" class="moto-widget-contact_form-label">Upload Resume</label>
        <input type="file" value="Upload" class="moto-widget-contact_form-field moto-widget-contact_form-input ng-pristine ng-valid ng-touched" placeholder="Upload Resume" ng-model-options="{ updateOn: 'blur' }" name="name" id="field_upload" ng-model="message.name">
        <span class="moto-widget-contact_form-field-error ng-hide" ng-show="contactForm.name.$invalid &amp;&amp; !contactForm.name.$pristine &amp;&amp; !contactForm.name.emailInvalid">Field is required</span> <span class="moto-widget-contact_form-field-error ng-hide" ng-show="contactForm.name.emailInvalid &amp;&amp; !contactForm.name.$pristine">Incorrect email</span> 
        </div> 
        
        
        
        
      <div class="moto-widget-contact_form-group">
        <label for="field_message" class="moto-widget-contact_form-label">Message</label>
        <textarea class="moto-widget-contact_form-field moto-widget-contact_form-textarea ng-pristine ng-untouched ng-valid" rows="3" placeholder="Message " ng-model-options="{ updateOn: 'blur' }" name="message" id="field_message" ng-model="message.message"></textarea>
        <span class="moto-widget-contact_form-field-error ng-hide" ng-show="contactForm.message.$invalid &amp;&amp; !contactForm.message.$pristine">Field is required</span> </div>
      <div class="moto-widget-contact_form-success ng-hide" ng-show="emailSent"> Your message was sent successfully </div>
      <div class="moto-widget-contact_form-danger ng-hide" ng-show="emailError"> Sorry, your message was not sent </div>
      <div class="moto-widget-contact_form-buttons">
        <div class="moto-widget moto-widget-button moto-preset-3 moto-align-left" data-preset="3" data-align="left"> <a ng-click="submit();" class="moto-widget-button-link moto-size-medium" data-size="medium"><span class="fa moto-widget-theme-icon"></span><span class="moto-widget-button-label">SUBMIT</span></a> </div>
        <button type="submit" class="hidden"></button>
      </div>
    </form>
  </div>
</div>

<p class="moto-text_normal">
    <br><br><br>
</p>

</div></div></div></div>






<div class="moto-widget moto-widget-row" data-widget="row"><div class="container-fluid"><div class="row"><div class="moto-cell col-sm-12" data-container="container"><div class="moto-widget moto-widget-spacer moto-preset-default                            moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="laaa">
    <div class="moto-widget-spacer-block" style="height: 1px;"></div>
</div></div></div></div></div>




  
</section>