<footer id="section-footer" class="footer moto-section" data-widget="section" data-container="section" data-moto-sticky="{mode:'smallHeight', direction:'bottom'}">
  <div data-css-name="moto-container_footer_56432c77" class="moto-widget moto-widget-container moto-container_footer_56432c77" data-widget="container" data-container="container">
    <div class="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="laaa">
      <div class="moto-widget-spacer-block" style="height: 5px;"></div>
    </div>
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-8" data-container="container">
            <div class="moto-widget moto-widget-row" data-widget="row">
              <div class="container-fluid">
                <div class="row">
                  <div class="moto-cell col-sm-8" data-container="container">
                    <div class="moto-widget moto-widget-text moto-preset-default                 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aasa">
                      <div class="moto-widget-text-content moto-widget-text-editable">
                        <p class="moto-text_system_8">COMPANY<br>
                        </p>
                      </div>
                    </div>
                    <div class="moto-widget moto-widget-text moto-preset-default                moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
                      <div class="moto-widget-text-content moto-widget-text-editable">
                        <p class="moto-text_system_10">
                          <a class="moto-link" data-action="url" href="" routerLink="/" target="_self">Home</a><br>
                          <a class="moto-link" data-action="url" href="" routerLink="/about-us" target="_self">About Us</a><br>
                          <a class="moto-link" data-action="url" href="" routerLink="/products" target="_self">Products</a><br>
                          <a class="moto-link" data-action="url" href="" routerLink="/downloads" target="_self">Downloads</a><br>
						              <a class="moto-link" data-action="url" href="" routerLink="/current-openings" target="_self">Career</a><br>
                          <!-- <a class="moto-link" data-action="url" href="#" target="_self">Media</a><br> -->
                          <a class="moto-link" data-action="url" href="" routerLink="/contacts" target="_self">Contact Us</a><br>
                        </p>
                      </div>
                    </div>
                    <div class="moto-widget moto-widget-text moto-preset-default               moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="masa">
                      <div class="moto-widget-text-content moto-widget-text-editable">
                        <p class="moto-text_system_8">PRODUCTS</p>
                      </div>
                    </div>
                    <div class="moto-widget moto-widget-text moto-preset-default             moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
                      <div class="moto-widget-text-content moto-widget-text-editable">
                        <p class="moto-text_system_10">
                          <a class="moto-link" data-action="url" href="" routerLink="/single-phase-transformers" target="_self">Completely Self Protectetd Distribution Transformers</a><br>
                          <a class="moto-link" data-action="url" href="" routerLink="/three-phase-transformers" target="_self">Three Phase Distribution Transformers</a><br>
                          <a class="moto-link" data-action="url" href="" routerLink="/power-transformers" target="_self">Power Transformers</a><br>
                          <a class="moto-link" data-action="url" href="" routerLink="/amorphous-core-transformers" target="_self">Amorphous Core Transformers</a><br>
                          <a class="moto-link" data-action="url" href="" routerLink="/3d-core-transformers" target="_self">3D Core Transformers</a><br>
                          <a class="moto-link" data-action="url" href="" routerLink="/dry-type-transformers" target="_self">Dry Type Transformers</a><br>
                          <a class="moto-link" data-action="url" href="" routerLink="/special-purpose-transformers" target="_self">Special Purpose Transformers</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  
                  <div class="moto-cell col-sm-3" data-container="container">
                    
                    
                    <div class="moto-widget moto-widget-text moto-preset-default              moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="masa">
                      <div class="moto-widget-text-content moto-widget-text-editable">
                        <p class="moto-text_system_8">FOLLOW US<br>
                        </p>
                      </div>
                    </div>
                    <div class="moto-widget moto-widget-row" data-widget="row">
                      <div class="container-fluid">
                        <div class="row">
                          <div class="moto-cell col-sm-3" data-container="container">
                            <div class="moto-widget moto-widget-social-links moto-preset-default  moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto " data-widget="social_links" data-preset="default" data-align="{&quot;desktop&quot;:&quot;&quot;,&quot;tablet&quot;:&quot;&quot;,&quot;mobile-v&quot;:&quot;&quot;,&quot;mobile-h&quot;:&quot;&quot;}" data-spacing="aaaa">
                              <ul class="moto-widget-social-links-list">
                                <li class="moto-widget-social-links-item"> 
                                  <a href="https://www.facebook.com/kotsonskpl/" class="moto-widget-social-links-link moto-widget-social-links-link_facebook" data-provider="facebook" target="_blank"></a> </li>
                              </ul>
                            </div>
                          </div>
                          <div class="moto-cell col-sm-9" data-container="container">
                            <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aasa">
                              <div class="moto-widget-text-content moto-widget-text-editable">
                                <p class="moto-text_system_10"><a class="moto-link" data-action="url" href="https://www.facebook.com/kotsonskpl/" target="_blank">Facebook</a><br>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="moto-widget moto-widget-row" data-widget="row">
                      <div class="container-fluid">
                        <div class="row">
                          <div class="moto-cell col-sm-3" data-container="container">
                            <div class="moto-widget moto-widget-social-links moto-preset-default  moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto " data-widget="social_links" data-preset="default" data-align="{&quot;desktop&quot;:&quot;&quot;,&quot;tablet&quot;:&quot;&quot;,&quot;mobile-v&quot;:&quot;&quot;,&quot;mobile-h&quot;:&quot;&quot;}" data-spacing="aaaa">
                              <ul class="moto-widget-social-links-list">
                                <li class="moto-widget-social-links-item"> 
                                  <a href="https://www.linkedin.com/company/kotsonskpl/" class="moto-widget-social-links-link moto-widget-social-links-link_linkedin" data-provider="linkedin" target="_blank"></a> </li>
                              </ul>
                            </div>
                          </div>
                          <div class="moto-cell col-sm-9" data-container="container">
                            <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aasa">
                              <div class="moto-widget-text-content moto-widget-text-editable">
                                <p class="moto-text_system_10"><a class="moto-link" data-action="url" href="https://www.linkedin.com/company/kotsonskpl/" target="_blank">LinkedIn</a><br>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="moto-widget moto-widget-row" data-widget="row">
                      <div class="container-fluid">
                        <div class="row">
                          <div class="moto-cell col-sm-3" data-container="container">
                            <div class="moto-widget moto-widget-social-links moto-preset-default  moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto " data-widget="social_links" data-preset="default" data-align="{&quot;desktop&quot;:&quot;&quot;,&quot;tablet&quot;:&quot;&quot;,&quot;mobile-v&quot;:&quot;&quot;,&quot;mobile-h&quot;:&quot;&quot;}" data-spacing="aaaa">
                              <ul class="moto-widget-social-links-list">
                                <li class="moto-widget-social-links-item"> 
                                  <a href="https://www.instagram.com/kotsonskpl/" class="moto-widget-social-links-link moto-widget-social-links-link_instagram" data-provider="instagram" target="_blank"></a> </li>
                              </ul>
                            </div>
                          </div>
                          <div class="moto-cell col-sm-9" data-container="container">
                            <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aasa">
                              <div class="moto-widget-text-content moto-widget-text-editable">
                                <p class="moto-text_system_10"><a class="moto-link" data-action="url" href="https://www.instagram.com/kotsonskpl/" target="_blank">Instagram</a><br>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="moto-widget moto-widget-row" data-widget="row">
                      <div class="container-fluid">
                        <div class="row">
                          <div class="moto-cell col-sm-3" data-container="container">
                            <div class="moto-widget moto-widget-social-links moto-preset-default  moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto "
     data-widget="social_links" data-preset="default" data-align="{&quot;desktop&quot;:&quot;&quot;,&quot;tablet&quot;:&quot;&quot;,&quot;mobile-v&quot;:&quot;&quot;,&quot;mobile-h&quot;:&quot;&quot;}" data-spacing="aaaa">
                              <ul class="moto-widget-social-links-list">
                                <li class="moto-widget-social-links-item"> <a href="https://www.pinterest.com/kotsons" class="moto-widget-social-links-link moto-widget-social-links-link_pinterest" data-provider="pinterest" target="_blank"></a> </li>
                              </ul>
                            </div>
                          </div>
                          <div class="moto-cell col-sm-9" data-container="container">
                            <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aasa">
                              <div class="moto-widget-text-content moto-widget-text-editable">
                                <p class="moto-text_system_10"><a class="moto-link" data-action="url" href="https://www.pinterest.com/kotsons" target="_blank">Pinterest</a><br>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="moto-widget moto-widget-row" data-widget="row">
                      <div class="container-fluid">
                        <div class="row">
                          <div class="moto-cell col-sm-3" data-container="container">
                            <div class="moto-widget moto-widget-social-links moto-preset-default  moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto "
     data-widget="social_links" data-preset="default" data-align="{&quot;desktop&quot;:&quot;&quot;,&quot;tablet&quot;:&quot;&quot;,&quot;mobile-v&quot;:&quot;&quot;,&quot;mobile-h&quot;:&quot;&quot;}" data-spacing="aaaa">
                              <ul class="moto-widget-social-links-list">
                                <li class="moto-widget-social-links-item"> <a href="https://plus.google.com/kotsons" class="moto-widget-social-links-link moto-widget-social-links-link_googleplus" data-provider="googleplus" target="_blank"></a> </li>
                              </ul>
                            </div>
                          </div>
                          <div class="moto-cell col-sm-9" data-container="container">
                            <div class="moto-widget moto-widget-text moto-preset-default             moto-spacing-top-auto moto-spacing-right-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aasa">
                              <div class="moto-widget-text-content moto-widget-text-editable">
                                <p class="moto-text_system_10"><a class="moto-link" data-action="url" href="https://plus.google.com/kotsons" target="_blank">Google +</a><br>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <div class="moto-widget moto-widget-row" data-widget="row">
                      <div class="container-fluid">
                        <div class="row">
                          <div class="moto-cell col-sm-3" data-container="container">
                            <div class="moto-widget moto-widget-social-links moto-preset-default  moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto " data-widget="social_links" data-preset="default" data-align="{&quot;desktop&quot;:&quot;&quot;,&quot;tablet&quot;:&quot;&quot;,&quot;mobile-v&quot;:&quot;&quot;,&quot;mobile-h&quot;:&quot;&quot;}" data-spacing="aaaa">
                              <ul class="moto-widget-social-links-list">
                                <li class="moto-widget-social-links-item"> 
                                  <a href="https://twitter.com/kotsons" class="moto-widget-social-links-link moto-widget-social-links-link_twitter" data-provider="twitter" target="_blank"></a> 
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="moto-cell col-sm-9" data-container="container">
                            <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
                              <div class="moto-widget-text-content moto-widget-text-editable">
                                <p class="moto-text_system_10"><a class="moto-link" data-action="url" href="https://www.twitter.com/kotsons" target="_blank">Twitter</a><br>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="moto-cell col-sm-4" data-container="container">
            <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aasa">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p style="text-align: right;" class="moto-text_system_8">CONTACT<br>
                </p>
              </div>
            </div>
            <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p style="text-align: right;" class="moto-text_system_15">
                	<strong>Head Office and Unit-1</strong> <a id="newdelhi" class="t14">

                  <br>
                  <strong>Alwar :</strong> 217A, 218 to 220 & 230A MIA, Desula,Alwar-301030 Rajasthan, INDIA<br>  
                  Tel.: +91-144-2881210, 2881211<br>
                  Email : kotsons@kotsons.com<br></a>
                </p>
              </div>
            </div>
            <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="masa">
              <div class="moto-widget-text-content moto-widget-text-editable">
                
                
              </div>
            </div>
            <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p style="text-align: right;" class="moto-text_system_15 t14">
                  <strong>Unit II</strong><br>
                  <strong>Agra :</strong> C-21, U.P.S.I.D.C., Site-C, Sikandra, Agra-282007, U.P., INDIA<br>
                  Tel.: +91-562-2641422, 264-1675 <br>
                  Email : kotsons@kotsons.com<br>
                </p>
              </div>
            </div>
            
            <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="masa">
              <div class="moto-widget-text-content moto-widget-text-editable">
                
              </div>
            </div>
            <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p style="text-align: right;" class="moto-text_system_15"><strong>Registered Office</strong><br>
                  <strong>New Delhi :</strong> A-208 IInd Floor, R.G City Centre, <br>
                  Motia Khan, Pahar Ganj, New Delhi-110055, INDIA.<br>
                  Tel : +91-011-43537540 <br>
                  Email : kotsons@kotsons.com<br><br>

                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="mama">
      <div class="moto-widget-spacer-block" style="height: 35px;"></div>
    </div>
  </div>
  <div data-css-name="moto-container_footer_564337de" class="moto-widget moto-widget-container moto-container_footer_564337de" data-widget="container" data-container="container">
    <div class="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="mama">
      <div class="moto-widget-spacer-block" style="height: 20px;"></div>
    </div>
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-6" data-container="container">
            <div class="moto-widget moto-widget-image moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto " data-preset="default" data-spacing="aasa" data-widget="image"> <a class="moto-widget-image-link moto-link" href="/"   data-action="page"> <img src="./assets/images/mt-0234-home-logo-img2.png" class="moto-widget-image-picture" data-id="133" title="" alt="" draggable="false"> </a> </div>
          </div>
          <div class="moto-cell col-sm-6" data-container="container">
            <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="saaa">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_system_13" style="text-align: right;"> Copyrights © 2019. KOTSONS Pvt. Ltd. All rights reserved. Developed by <a href="https://www.gortnm.in" target="_blank">Gortnm IT.</a><br>

                </p>
              </div>
            </div>
            <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p style="text-align: right;" class="moto-text_system_11">
                  <a class="moto-link">Press</a>&nbsp; |&nbsp; 
                  <a class="moto-link">Terms</a>&nbsp; |&nbsp;
                  <!-- data-action="url" href="" target="_blank" -->
                  <a class="moto-link" data-action="url" href="" routerLink="/our-customers">Clients</a>&nbsp; |&nbsp; 
                  <a class="moto-link">Partners</a>&nbsp; |&nbsp; 
                  <a class="moto-link">Privacy Policy</a>&nbsp; |&nbsp; 
                  <a class="moto-link">Support</a><br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="masa">
      <div class="moto-widget-spacer-block" style="height: 2px;"></div>
    </div>
  </div>
  <div data-css-name="moto-container_footer_56433ac6" class="moto-widget moto-widget-container moto-container_footer_56433ac6" data-widget="container" data-container="container">
    <div class="moto-widget moto-widget-map moto-preset-default   moto-align-center moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="map" data-preset="default" data-align="center" data-spacing="aaaa" data-width="100">
      <div class="moto-widget-cover"></div>
      
<!--<iframe data-zoom="12" data-address="Glasgow" class="moto-widget-map-frame" style="border: 0px none; width: 100%; height: 382px;" src="//maps.google.com/maps?q=Glasgow&amp;z=12&amp;t=m&amp;output=embed" frameborder="0" height="200" width="100"> </iframe>-->
      
<!-- <iframe id="mapholder" data-zoom="12" data-address="Glasgow" class="moto-widget-map-frame" src="http://tinyurl.com/zpm3u4v" width="100%" height="382" frameborder="0" style="border:0" allowfullscreen></iframe> -->
      
<!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.432733020384!2d77.20557341549143!3d28.64675899018675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd684754d667%3A0x9062a6d0ccdf8f7d!2sR+G+City+Center%2C+4+Community+Center%2C+Motia+Khan+%2C+Paharganj%2C+DB+Gupta+Road%2C+Motia+Khan%2C+Sadar+Bazaar%2C+New+Delhi%2C+Delhi+110055!5e0!3m2!1sen!2sin!4v1450703866385" width="600" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>-->
      
      
    </div>
  </div>
</footer>

<div data-moto-back-to-top-button class="moto-back-to-top-button"></div>