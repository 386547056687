<section id="section-content" class="content page-3 moto-section" data-widget="section" data-container="section">
  
  
  
  	<div data-css-name="moto-container_content_56420832" class="moto-widget moto-widget-container moto-container_content_56420832" data-widget="container" data-container="container">
      <div class="moto-widget moto-widget-slider moto-preset-2 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-left-auto" data-widget="slider" data-preset="2" data-spacing="aala">
        <ul class="moto-widget-slider-list" data-moto-slider-options='{&quot;itemsCount&quot;:3,&quot;slideshowEnabled&quot;:true,&quot;slideshowDelay&quot;:5,&quot;slideshowAnimationType&quot;:&quot;fade&quot;,&quot;showNextPrev&quot;:false,&quot;showPaginationDots&quot;:true,&quot;showSlideCaptions&quot;:true}'>
          <li><img src="./assets/images/technical-consultancy-bannerimg.jpg" style="visibility: visible;"></li>
          
        </ul>
      </div>
    </div>
  
  
  
  
  
  
  
  
  
  
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-12" data-container="container">
            <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default                              wow fadeInRight moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="lala">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p style="text-align: center;" class="moto-text_system_4">Technical Consultancy<br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-6" data-container="container">
            
            <div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default                              wow fadeInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_normal">We are providing Technical Consultancy to set-up Repair/Manufacturing shop for Power & Distribution Transformers. At present we are providing Technical Consultancy to many foreign companies to set-up Repair/Manufacturing shop. We undertake the whole project which includes following steps :<br><br>
•	Preparation of project report<br>
•	Determination of size of covered & uncovered land for Repair/Manufacturing<br>&nbsp;&nbsp; shop.<br>
•	Supply of Plant & Machinery.<br>
•	Commissioning of Plant & Machinery.<br>
•	Sourcing and procurement of raw materials<br>
•	Technology for starting commercial production.<br><br>

Please contact us at <a href="https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&to=sales@kotsons.com" target="_blank">sales@kotsons.com</a> for more information.

<!-- For more information Contact Our Marketing Manager <br><strong>csingh@kotsons.com</strong>
 -->
 </p>
              </div>
            </div>
          </div>
          <div class="moto-cell col-sm-6" data-container="container">
            <div class="moto-widget moto-widget-image moto-preset-default moto-align-center moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto wow fadeInRight" data-preset="default" data-spacing="aasa" data-widget="image">
              <div class="moto-widget-image-link"> <img src="./assets/images/technical-consultancy-img.jpg" class="moto-widget-image-picture" data-id="134" title=""  alt="" draggable="false"> </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="moto-widget moto-widget-row" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-12" data-container="container">
            <div class="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="laaa">
              <div class="moto-widget-spacer-block" style="height: 1px;"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>