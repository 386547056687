<section id="section-content" class="content page-3 moto-section" data-widget="section" data-container="section">
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-12" data-container="container">
            <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default                              wow fadeInRight moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="lala">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p style="text-align: center;" class="moto-text_system_4">Dry Type Transformers<br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-6" data-container="container">
            <div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default wow fadeInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_normal"><strong class="moto-color3">Open Ventilated Dry Type (OVDT) Vacuum Pressure Impregnated (VPI) Eco Friendly Transformers</strong><br><br>
KOTSONS was one of the first Indian manufacturer to use the DuPont ReliatraN® branded solution and develop Ventilated Dry Type Transformers in collaboration with E. I. du Pont de Nemours and Company, U.S.A.<br><br>
KOTSONS Eco Friendly VP transformer coils are vacuum pressure impregnated in high temperature varnish. The process includes complete submersion in varnish under vacuum and pressure and regulated curing using PLC controlled equipment to ensure consistency. The finished coils are effectively protected against moisture, dirt, and most industrial contaminants. A 220°C Class NOMEX® Listed insulation system is used on KOTSONS OVDT VPI transformers regardless of specified temperature rating. This system accommodates a standard temperature rise of 155°C. Optional temperature rises of 90°C and 115°C and fan cooling allow for unsurpassed overload capacity.<br><br><br>

<strong class="moto-color3">Salient Features & Advantages of OVDT VPI Dry Transformers:</strong><br>
1.	Excellent mechanical and short-circuit strength due to the VPI 	Process<br>
2.	No danger of fire or explosion. No liquids to leak hence no flammable 	liquid & no oil pollution<br>
3.	Less weight than comparable cast resin units<br>
4.	Low total ownership costs and low initial costs<br>
5.	Use of  NOMEX® UL® Certified Insulation Materials, listed 220°C insulation system, regardless of temperature rating<br>
6.	VPI transformers are non-explosive with high resistance to flame and 	do not require vaults, containment dikes, or expensive fire suppression systems<br>
7.	Low Partial Discharge - VPI process ensures void free insulation system<br>
8.	Moisture Ingress Resistant & Immune to polluted atmosphere<br>
9.	Easy to repair (open coils) due to OVDT Design<br>
10.	Eco Friendly, No harm to environment at the end of Life</p>
              </div>
            </div>
          </div>
          <div class="moto-cell col-sm-6" data-container="container">
            <div class="moto-widget moto-widget-image moto-preset-default moto-align-center moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto wow fadeInRight" data-preset="default" data-spacing="aasa" data-widget="image">
              <div class="moto-widget-image-link"> <img src="./assets/images/drytype-img1.jpg" class="moto-widget-image-picture" data-id="134" title=""  alt="" draggable="false"> </div>
			  
			  

            </div>
            
          </div>
        </div>
      </div>
    </div>
    <div class="clr30"></div>
    <div data-css-name="moto-container_content_56433db6" class="moto-widget moto-widget-container moto-container_content_56433db6" data-widget="container" data-container="container">
      <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
        <div class="container-fluid">
          <div class="row">
            <div class="moto-cell col-sm-12" data-container="container">
				<div class="clr50"></div>
              <p style="text-align: left; font-size:20px;"><strong>Temperature Rise / Overload Capacity :</strong><br>
                  </p>
				  <div class="clr30"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
        <div class="container">
          <div class="row">
          <table class="table table-striped amorphoustbl">
    <thead>
      <tr>
        <th width="25%">Temperature Rise</th>
        <th width="25%">Based Rated kVA</th>
        <th width="25%">Rated kVA at 155°C</th>
		<th width="25%">Fan Cooled kVA</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>155°C</td>
        <td>1000</td>
        <td>1000</td>
		<td>1333</td>
      </tr>
      <tr>
        <td>115°C</td>
        <td>1000</td>
        <td>1150</td>
		<td>1530</td>
      </tr>
      <tr>
        <td>90°C</td>
        <td>1000</td>
        <td>1350</td>
		<td>1800</td>
      </tr>
	</tbody>
  </table>  
            
            
          </div>
        </div>
      </div>
      <div class="clr60"></div>
    </div>
	<div class="clr60"></div>
	<div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-6" data-container="container">
            <div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default wow fadeInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_normal"><strong class="moto-color3">Standard VPI Range & Features:</strong><br>
•	Up to 5000kVA & up to 33kV<br>
•	Aluminum / Copper Windings<br>
•	With Dry Type On Load Tap Changer<br>
•	Step-Lap mitered Core / Amorphous Metal Core<br>
•	220°C insulation system - 155°C average temperature rise<br>
•	Vacuum pressure impregnation with high temperature varnish<br>
•	Vibration isolation pads between Core, Coil and Enclosure<bR>
•	Base equipped with Jacking Pads and designed for Rolling or Skidding the<br> &nbsp;&nbsp;Enclosure in any direction<br>
•	Suitable for Package Substation installation<br>
•	100% QC Partial Discharge Test</p>
              </div>
            </div>
          </div>
          <div class="moto-cell col-sm-6" data-container="container">
            <div class="moto-widget moto-widget-image moto-preset-default moto-align-center moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto wow fadeInRight" data-preset="default" data-spacing="aasa" data-widget="image">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_normal"><strong class="moto-color3">Options & Accessories</strong><br>
•	UL listing<br>
•	IEEE 344 - Seismic Certification<br>
•	NEMA 3R enclosurer<br>
•	90°C or 115°C average temperature rise<br>
•	Copper windings<br>
•	Provisions for Future Fan Cooling (FFA)<br>
•	Electronic temperature monitor<br>
•	Increased basic impulse levels<br>
•	Loss optimized designs<br>
•	Air-filled terminal chambers<br>
•	Special paint colors
</p>
              </div>
			  

            </div>
            
          </div>
        </div>
      </div>
	  
	  
	  
	  
	  
    </div>
    
   
   <div class="clr60"></div>
    
    
  <div data-stellar-background-ratio="0.5" data-css-name="moto-container_content_drytype" class="moto-widget moto-widget-container moto-parallax moto-container_content_drytype" data-widget="container" data-container="container">
    <div class="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="lama">
      <div class="moto-widget-spacer-block" style="height: 35px;"></div>
    </div>
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-2" data-container="container"></div>
          <div class="moto-cell col-sm-8" data-container="container">
            <div data-animation="fadeIn" class="moto-widget moto-widget-text moto-preset-default wow fadeIn moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sasa">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p style="text-align: center;" class="moto-text_system_5">Quality service<br>
                </p>
                <p style="text-align: center;" class="moto-text_system_5">is our aim<br>
                </p>
              </div>
            </div>
          </div>
          <div class="moto-cell col-sm-2" data-container="container"></div>
        </div>
      </div>
    </div>
    <div class="moto-widget moto-widget-spacer moto-preset-default              moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="lama">
      <div class="moto-widget-spacer-block" style="height: 35px;"></div>
    </div>
  </div> 
    
   
    
  </section>