<!-- <!DOCTYPE html>
<html lang="en" data-ng-app="website">
<head>
<meta http-equiv="Refresh" content="0; url=http://www.anbelecon.com/kotsons.com/contacts.html">
<title>Kotsons</title>
</head>
<body>
</body>
</html> -->

<div class="alert alert-success alert-msg" *ngIf="showSuccess == true">
  <strong>Success!</strong> {{ successMsg }}
</div>

<div class="alert alert-danger alert-msg" *ngIf="showError == true">
  <strong>Danger!</strong> {{ errorMsg }}
</div>

<section id="section-content" class="content page-3 moto-section" data-widget="section" data-container="section">

    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-12" data-container="container">
            <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default wow fadeInRight moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="lala" style="padding-top: 48px; padding-bottom: 32px;">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p style="text-align: center;" class="moto-text_system_4">CONTACT US
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

		<!-- <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
			<div class="container-fluid">
			<div class="row">

				<div class="moto-cell col-sm-12" data-container="container">
					<div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default wow fadeInRight moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="lala">
						<div class="moto-widget-text-content moto-widget-text-editable"><p style="text-align: center;" class="moto-text_system_4">Contact us<br></p></div>
					</div>

					<div class="moto-widget moto-widget-contact_form moto-preset-default moto-widget-empty moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto " data-preset="default" data-widget="contact_form" data-type="" data-spacing="aala">

						<form class="moto-widget-contact_form-form" role="form" name="contactForm" onsubmit="return(validate());" [formGroup]="addContactForm" (ngSubmit)="submitContact();" ngNativeValidate>
							<div>
								
								<div class="moto-widget-contact_form-group">
									<label for="field_name" class="moto-widget-contact_form-label">Name</label>
									<input type="text" formControlName="name" class="moto-widget-contact_form-field moto-widget-contact_form-input" placeholder="" name="name" id="field_name" required />
								</div>
								
								<div class="moto-widget-contact_form-group">
									<label for="field_email" class="moto-widget-contact_form-label">Email</label>
									<input type="email" class="moto-widget-contact_form-field moto-widget-contact_form-input" formControlName="email" placeholder="" name="email" id="field_email" required />
								</div>
								
								<div class="moto-widget-contact_form-group">
									<label for="field_phone" class="moto-widget-contact_form-label">Phone</label>
									<input type="text" class="moto-widget-contact_form-field moto-widget-contact_form-input" formControlName="mobile" placeholder="" name="mobile" maxlength="14" id="number" required />
								</div>
								
								<div class="moto-widget-contact_form-group">
									<label for="field_message" class="moto-widget-contact_form-label">Message</label>
									<textarea class="moto-widget-contact_form-field moto-widget-contact_form-textarea" rows="3" placeholder="Message" formControlName="message" name="message" id="field_message" required></textarea>
								</div>
								
								<div class="moto-widget-contact_form-buttons">
									
									<button type="submit" value="Submit" class="moto-widget moto-widget-button moto-preset-3 moto-align-left" [disabled]="contactLoader == true">
										<span *ngIf="contactLoader == true">
											<i class="fa fa-spinner fa-spin"></i>&nbsp;&nbsp;
										</span> Submit
									</button>
								</div>
							</div>
						</form>
						
					</div>
				</div>

			</div>

		</div>

	</div> -->




<div class="moto-widget moto-widget-row row-fixed" data-widget="row" style="margin-top: 30px;">

	<div class="container-fluid">

		<div class="row">

			<div class="col-sm-6">

				<div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default wow fadeInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
					<div class="moto-widget-text-content moto-widget-text-editable"><p class="moto-text_system_9">KOTSONS PVT. LTD.<br></p>
					</div>
				</div>

				<p class="moto-text_normal">
					Manufacturer and Exporters of Single & Three Phase Distribution & Power Transformer, Dry Type Transformer, Oil Filled Transformer, Amorphous Core Transformer, Special Purpose Transformer, 3D Core Transformer. <br><br>

<!-- 					KPL (KOTSONS Pvt. Ltd.) established its Transformer Manufacturing unit
					back in 1979 at Alwar, India to produce Distribution & Power Transformers of
					33 KV class voltage, to meet the needs of Public Utilities Organizations and
					of Private Industrial Sectors in India. It was KPL’s vision of becoming
					integrated Global Transformer Manufacturing Company that the company's
					manufacturing facilities were later expanded and second & third plant was
					setup at Agra (U.P) and Bazpur (Uttaranchal), India and product range was
					expanded up to 25 MVA with 33 KV class, to cater the needs of
					International demands. -->
				</p>

				<p class="moto-text_normal" style="font-size: 18px;"><strong>Follow Us On</strong></p>

				<p style="color: #333333;">
					<a href="https://www.facebook.com/kotsonskpl/" target="_blank" style="margin-right: 18px; margin-top: 20px; display: inline-block; color: #333333;">
						<i class="fa fa-facebook" style="color: #333333; font-size: 24px;"></i>
						&nbsp;@kotsonskpl
					</a>
					<a href="https://www.linkedin.com/company/kotsonskpl/" target="_blank" style="margin-right: 18px; margin-top: 20px; display: inline-block; color: #333333;">
						<i class="fa fa-linkedin" style="color: #333333; font-size: 24px;"></i>
						&nbsp;@kotsonskpl
					</a>
					<a href="https://twitter.com/kotsons" target="_blank" style="margin-right: 18px; margin-top: 20px; display: inline-block; color: #333333;">
						<i class="fa fa-twitter" style="color: #333333; font-size: 24px;"></i>
						&nbsp;@kotsons
					</a>
					<a href="https://www.instagram.com/kotsonskpl/" target="_blank" style="margin-right: 18px; margin-top: 20px; display: inline-block; color: #333333;">
						<i class="fa fa-instagram" style="color: #333333; font-size: 24px;"></i>
						&nbsp;@kotsonskpl
					</a>
				</p>

				<p class="moto-text_normal">
					
					<br><br>
					<strong style="font-size: 17px;">We're here to help !</strong> Please do contact us for your Needs and for Technical Support for your Purchase and Product Related Query.

<!-- 					KPL (KOTSONS Pvt. Ltd.) established its Transformer Manufacturing unit
					back in 1979 at Alwar, India to produce Distribution & Power Transformers of
					33 KV class voltage, to meet the needs of Public Utilities Organizations and
					of Private Industrial Sectors in India. It was KPL’s vision of becoming
					integrated Global Transformer Manufacturing Company that the company's
					manufacturing facilities were later expanded and second & third plant was
					setup at Agra (U.P) and Bazpur (Uttaranchal), India and product range was
					expanded up to 25 MVA with 33 KV class, to cater the needs of
					International demands. -->

				</p>
			</div>

			<div class="moto-cell col-sm-6" data-container="container">

				<div class="moto-widget moto-widget-image moto-preset-default moto-align-center moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto wow fadeInRight" data-preset="default" data-spacing="aasa" data-widget="image" style="margin-bottom: 30px;">

					<div class="moto-widget-image-link">
						<img src="./assets/images/contact-us-img.jpg" class="moto-widget-image-picture" data-id="134" title=""  alt="" draggable="false">

					</div>
			
				</div>

			</div>

		</div>
	</div>
</div>


<div class="moto-widget moto-widget-row row-fixed" data-widget="row">

	<div class="container-fluid">

		<div class="row padding-20">

			<div class="moto-cell col-sm-12" data-container="container">
				<div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default wow fadeInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto " data-widget="text" data-preset="default" data-spacing="aama">

					<div class="moto-widget-text-content moto-widget-text-editable">
						<p class="moto-text_normal">

							<span class="kotsons-heading"><strong>Kotsons Pvt Ltd.</strong></span><br>
							
						</p>
					</div>
				</div>
			</div>

			<div class="moto-cell col-sm-4" data-container="container">
				<div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default wow fadeInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto " data-widget="text" data-preset="default" data-spacing="aama">

					<div class="moto-widget-text-content moto-widget-text-editable">
						<p class="moto-text_normal">

							
							<strong>Head Office and Unit-1</strong><br>
							<strong>Alwar :</strong> 217A, 218 to 220 & 230A MIA, Desula,Alwar-301030 Rajasthan, INDIA<br>  
							<strong>Tel.:</strong> +91-144-2881210, 2881211<br>
							<strong>Email :</strong> kotsons@kotsons.com
							
						</p>
					</div>
				</div>
			</div>

			<div class="moto-cell col-sm-4" data-container="container">
				<div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default wow fadeInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto " data-widget="text" data-preset="default" data-spacing="aama">

					<div class="moto-widget-text-content moto-widget-text-editable">
						<p class="moto-text_normal">

							<strong>Unit II</strong><br>
							<strong>Agra :</strong> C-21, U.P.S.I.D.C., Site-C, Sikandra, Agra-282007, U.P., INDIA<br>
							<strong>Tel.:</strong> +91-562-2641422, 2641818 <br>
							<strong>Email :</strong> kotsons@kotsons.com
							
						</p>
					</div>
				</div>
			</div>


			<div class="moto-cell col-sm-4" data-container="container">
				<div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default wow fadeInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto " data-widget="text" data-preset="default" data-spacing="aama">

					<div class="moto-widget-text-content moto-widget-text-editable">
						<p class="moto-text_normal">

							<strong>Registered Office</strong><br>
							<strong>New Delhi :</strong> A-208 IInd Floor, R.G City Centre, <br>
							Motia Khan, Pahar Ganj, New Delhi-110055, INDIA.<br>
							<strong>Tel :</strong> +91-011-43537540 <br>
							<strong>Email :</strong> kotsons@kotsons.com
							
						</p>
					</div>
				</div>
			</div>

		</div>
	</div>
</div>



<div class="moto-widget moto-widget-row row-fixed bottom-margin-20" data-widget="row">

	<div class="container-fluid">

		<div class="row padding-20">

			<div class="moto-cell col-sm-12" data-container="container">
				<div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default                              wow fadeInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">

					<div class="moto-widget-text-content moto-widget-text-editable">
						<p class="moto-text_normal kotsons-heading">
					
							<strong>Marketing Offices ( Northern, Southern and Western Region )</strong><br>

						</p>
					</div>
				</div>
				
			</div>

			<div class="moto-cell col-sm-4" data-container="container">
				<div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default                              wow fadeInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">

					<div class="moto-widget-text-content moto-widget-text-editable">
						<p class="moto-text_normal">
					
							<strong>Delhi :</strong><br>
							<strong>Mobile :</strong> +91 9958867224, 9971669006<br>
							<strong>Email :</strong> Delhi.Marketing@kotsons.com

						</p>
					</div>
				</div>
			</div>

			<div class="moto-cell col-sm-4" data-container="container">
				<div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default                              wow fadeInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">

					<div class="moto-widget-text-content moto-widget-text-editable">
						<p class="moto-text_normal">

							<strong>Agra :</strong><br>
							<strong>Mobile :</strong> +91 7060032757, 8979000233 <br>
							<strong>Email :</strong> Sales@kotsons.com

						</p>
					</div>
				</div>
			</div>


			<div class="moto-cell col-sm-4" data-container="container">
				<div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default                              wow fadeInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">

					<div class="moto-widget-text-content moto-widget-text-editable">
						<p class="moto-text_normal">

							<strong>Alwar :</strong><br>
							<strong>Mobile :</strong> +91 8003398872, 8003398857<br>
							<strong>Email :</strong> Exports@kotsons.com

						</p>
					</div>
				</div>
			</div>

			<div class="moto-cell col-sm-6" data-container="container" style="margin-top: 20px;">
				<div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default                              wow fadeInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">

					<div class="moto-widget-text-content moto-widget-text-editable">
						<p class="moto-text_normal">

							<span class="kotsons-heading"><strong>Marketing Offices ( Eastern Region )</strong></span><br>
							<strong>Kolkata : </strong><br>
							<strong>Mobile :</strong> +91 8003398879, 8003398889<br>
							<strong>Email :</strong> Sales.kolkata@kotsons.com, Sales1.kolkata@kotsons.com

						</p>
					</div>
				</div>
			</div>

		</div>
	</div>
</div>


<div class="moto-widget moto-widget-row row-fixed" data-widget="row">

	<div class="container-fluid">

		<div class="row padding-20">

			<div class="moto-cell col-sm-6" data-container="container">
				<div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default                              wow fadeInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">

					<div class="moto-widget-text-content moto-widget-text-editable">
						<p class="moto-text_normal">
						
							<span class="kotsons-heading"><strong>For AMC, Services & Repair Support, Customer Care</strong></span><br>
							<strong>Mobile :</strong> +91 - 7060032757, 8979000231<br>
							<strong>Email :</strong> Qc@kotsons.com

						</p>
					</div>
				</div>
				
			</div>

			<div class="moto-cell col-sm-6" data-container="container">
				<div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default                              wow fadeInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">

					<div class="moto-widget-text-content moto-widget-text-editable">
						<p class="moto-text_normal">
					
						<span class="kotsons-heading"><strong>International - Export Enquires</strong></span><br>
						<strong>Mobile :</strong> +91 8003398872, 8003398857<br>
						<strong>Email :</strong> Exports@kotsons.com

						</p>
					</div>
				</div>
			</div>

		</div>
	</div>
</div>


	<div class="moto-widget moto-widget-row row-fixed" data-widget="row">
		<div class="container-fluid">
			<div class="row">

				<div class="moto-cell col-sm-12" data-container="container">
					<div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default wow fadeInRight moto-spacing-top-large moto-spacing-right-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="lala">
						<div class="moto-widget-text-content moto-widget-text-editable"><p style="text-align: center;" class="moto-text_system_4">Contact us<br></p></div>
					</div>

					<div class="moto-widget moto-widget-contact_form moto-preset-default moto-widget-empty moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto " data-preset="default" data-widget="contact_form" data-type="" data-spacing="aala">

						<form class="moto-widget-contact_form-form" role="form" name="contactForm" onsubmit="return(validate());" [formGroup]="addContactForm" (ngSubmit)="submitContact();" ngNativeValidate>
							<div>
								
								<div class="moto-widget-contact_form-group">
									<label for="field_name" class="moto-widget-contact_form-label">Name</label>
									<input type="text" formControlName="name" class="moto-widget-contact_form-field moto-widget-contact_form-input" placeholder="" name="name" id="field_name" pattern="[a-zA-Z\s]+" required />
								</div>
								
								<div class="moto-widget-contact_form-group">
									<label for="field_email" class="moto-widget-contact_form-label">Email</label>
									<input type="email" class="moto-widget-contact_form-field moto-widget-contact_form-input" formControlName="email" placeholder="" name="email" id="field_email" required />
								</div>
								
								<div class="moto-widget-contact_form-group">
									<label for="field_phone" class="moto-widget-contact_form-label">Phone</label>
									<input type="text" class="moto-widget-contact_form-field moto-widget-contact_form-input" formControlName="mobile" placeholder="" name="mobile" maxlength="14" id="number" pattern="[0-9]+" required />
								</div>
								
								<div class="moto-widget-contact_form-group">
									<label for="field_message" class="moto-widget-contact_form-label">Message</label>
									<textarea class="moto-widget-contact_form-field moto-widget-contact_form-textarea" rows="3" placeholder="Message" formControlName="message" name="message" id="field_message" required></textarea>
								</div>
								
								<div class="moto-widget-contact_form-buttons">
									
									<button type="submit" value="Submit" class="moto-widget moto-widget-button moto-preset-3 moto-align-left" [disabled]="contactLoader == true">
										<span *ngIf="contactLoader == true">
											<i class="fa fa-spinner fa-spin"></i>&nbsp;&nbsp;
										</span> Submit
									</button>
								</div>
							</div>
						</form>
						
					</div>
				</div>

			</div>

		</div>

	</div>
			<!-- <div class="moto-widget moto-widget-row" data-widget="row"><div class="container-fluid"><div class="row"><div class="moto-cell col-sm-12" data-container="container"><div class="moto-widget moto-widget-spacer moto-preset-default                            moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="laaa">
				<div class="moto-widget-spacer-block" style="height: 1px;"></div>
			</div></div></div></div></div>

			 <div data-css-name="moto-container_footer_56433ac6" class="moto-widget moto-widget-container moto-container_footer_56433ac6" data-widget="container" data-container="container">
				<div class="moto-widget moto-widget-map moto-preset-default   moto-align-center moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="map" data-preset="default" data-align="center" data-spacing="aaaa" data-width="100">
					<div class="moto-widget-cover"></div>
					
					<iframe data-zoom="12" data-address="Glasgow" class="moto-widget-map-frame" src="http://tinyurl.com/zpm3u4v" width="100%" height="382" frameborder="0" style="border:0" allowfullscreen></iframe>
					
				</div>
			</div> -->
			
		</section>