<section id="section-content" class="content page-3 moto-section" data-widget="section" data-container="section">
  	
	
	
	
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-12" data-container="container">
            <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default                              wow fadeInRight moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="lala">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p style="text-align: center;" class="moto-text_system_4">3D Core Transformers<br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-6" data-container="container">
            
            <div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default                              wow fadeInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_normal">KOTSONS Pvt. Ltd., as a part of their continuous efforts towards promotion of energy efficient and green technology environment has added a new product to their portfolio, the 3D Core transformers.<br><br>
3D Core is a structure with 3 identical single frame continuously wound by several trapezoid tape; three pillars of the core shape a equilateral triangle and the 3 phases are symmetrical. 3-phase magnetic circuits of the core are completely balanced; the magnetization direction is identical with the direction of rolling of silicon steel, magnetic flux distribution is even with neither lap nor seam; there is neither obvious high resistance area nor distortion of density of magnetic flux at the seams.</p><br><br>
		<img src="./assets/images/3d-img3.jpg"><br><br>
		
		<p class="moto-text_normal">


<strong class="moto-color3">Advantages of 3D Transformers over conventional stack core / wound core type transformers:</strong><br>
3D Core transformer is an energy-saving transformer with better structure and performance. It has the following outstanding benefits: <br>
•	Reduced Material- Less Weight<br>
•	Reduced Energy Loss-Energy Saver<br>
•	3-Phase Equilibrium- Equal Load<br>
•	Low No-load Current<br>
•	Low Noise - Installation anywhere<br>
•	Overload Capacity-Natural Cooling<br>
•	Strong Emergent Short-circuit Protection<br>
•	Low Strength of Electromagnetic Field<br>
•	Low Current Harmonic Spectrum<br>
•	Anti-theft<br><br>

<strong class="moto-color3">Applicable standards : IEC / ANSI / BS / IS / SABS / CENELEC / GOST</strong>
Wide range of transformers in accordance to above standards with different combinations can be manufactured. The transformers are generally designed and manufactured to comply with the IEC publications 60076.<br><br>

<strong class="moto-color3">Range : From 50 kVA to 5000 kVA</strong><br>
<strong class="moto-color3">Insulation level  £ 12 kV  £  24 kV  £ 36 kV</strong><br>
•	Indoor / Outdoor installation<br>
•	Conductor : Copper or Aluminum as per customer’s requirement

		</p>
              </div>
            </div>
          </div>
          <div class="moto-cell col-sm-6" data-container="container">
            <div class="moto-widget moto-widget-image moto-preset-default moto-align-center moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto wow fadeInRight" data-preset="default" data-spacing="aasa" data-widget="image">
              <div class="moto-widget-image-link"> <img src="./assets/images/3d-img1.jpg" class="moto-widget-image-picture" data-id="134" title=""  alt="" draggable="false"> </div>
			  
			  
			  
			  
			  <div class="clr30"></div>
			  
			  <img src="./assets/images/3d-img2.jpg" class="moto-widget-image-picture" data-id="134" title=""  alt="" draggable="false">
			  
			  <div class="clr30"></div>
			  
			  <p class="moto-text_normal">
			  
			  
•	CSP (Completely Self Protected) feature can be provided up to 400 kVA <br>
•	Maximum ambient temperature : as per customer’s specification <br>
•	Top Oil Temperature rise : as per customer’s specification<br>
•	Average winding temperature rise : as per customer’s specification<br>
•	Hermetically sealed type (with gas cushion / fully filled) / Conventional type<br>&nbsp;&nbsp; (with Conservator)<br>
•	Color : as per customer’s requirement<br>
•	Cooling : ONAN/ONAF as per customer’s requirement<br>
•	Cooling arrangement : Corrugation or Pressed steel Radiators<br>
•	On Load Tap Changer with RTCC & AVR, can be provided above 500 kVA	if<br>&nbsp;&nbsp; required<br>
•	Safety devices can also be provided upon request<br>
•	Other optional fittings as required can be provided upon request
			  </p>
            </div>
		</div>
		
		
		
		  
		  
		  
		  
        </div>
		
		
		
		
      </div>
    </div>
	<div data-css-name="moto-container_content_56420832" class="moto-widget moto-widget-container moto-container_content_56420832" data-widget="container" data-container="container">
      <div class="moto-widget moto-widget-slider moto-preset-2 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-left-auto" data-widget="slider" data-preset="2" data-spacing="aala">
        <ul class="moto-widget-slider-list" data-moto-slider-options='{&quot;itemsCount&quot;:3,&quot;slideshowEnabled&quot;:true,&quot;slideshowDelay&quot;:5,&quot;slideshowAnimationType&quot;:&quot;fade&quot;,&quot;showNextPrev&quot;:false,&quot;showPaginationDots&quot;:true,&quot;showSlideCaptions&quot;:true}'>
          <li><img src="./assets/images/3d-banner-img.jpg" style="visibility: visible;"></li>
          
        </ul>
      </div>
    </div>
    
    </section>