<section id="section-content" class="content page-1 moto-section" data-widget="section" data-container="section">

  <div data-css-name="moto-container_content_56420832" class="moto-widget moto-widget-container moto-container_content_56420832" data-widget="container" data-container="container">

    <mdb-carousel class="carousel slide carousel-fade" [animation]="'slide'">
        
      <mdb-carousel-item>
        <div class="view w-100">
          <img class="d-block w-100" src="./assets/images/mt-0234-home-slide03.jpg" alt="Second slide">
          <div class="rgba-black-strong waves-light" mdbWavesEffect></div>
        </div>
        <div class="carousel-caption">
          <h1 class="h3-responsive" style="font-weight: bold;">We are the best in the field of manufacturing & supply of Transformers</h1>
        </div>
      </mdb-carousel-item>

      <mdb-carousel-item>
        <div class="view w-100">
          <img class="d-block w-100" src="./assets/images/mt-0234-home-slide02.jpg" alt="Second slide">
          <div class="rgba-black-strong waves-light" mdbWavesEffect></div>
        </div>
        <div class="carousel-caption">
          <h1 class="h3-responsive" style=" font-weight: bold;">ISO 9001:2015, ISO 14001:2015, OHSAS 45001:2018 Certified Company</h1>
        </div>
      </mdb-carousel-item>
      <mdb-carousel-item>
        <div class="view w-100">
          <img class="d-block w-100" src="./assets/images/mt-0234-home-slide01.jpg" alt="Third slide">
          <div class="rgba-black-slight waves-light" mdbWavesEffect></div>
        </div>
        <div class="carousel-caption">
          <h1 class="h3-responsive" style="font-weight: bold;">Quality is our primary concern for your satisfaction</h1>
        </div>
      </mdb-carousel-item>

    </mdb-carousel>

  </div>

    <!-- <div data-css-name="moto-container_content_56420832" class="moto-widget moto-widget-container moto-container_content_56420832" data-widget="container" data-container="container">
      <div class="moto-widget moto-widget-slider moto-preset-2 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto  moto-widget-slider-loader" data-widget="slider" data-preset="2" data-spacing="aala">
        <ul class="moto-widget-slider-list" data-moto-slider-options='{&quot;itemsCount&quot;:3,&quot;slideshowEnabled&quot;:true,&quot;slideshowDelay&quot;:5,&quot;slideshowAnimationType&quot;:&quot;fade&quot;,&quot;showNextPrev&quot;:false,&quot;showPaginationDots&quot;:true,&quot;showSlideCaptions&quot;:true}'>
          <li><img src="./assets/images/mt-0234-home-slide03.jpg"  title="We are the best in the fields of manufacture & supply  of transformers"  alt="mt-0234-home-slide01"/></li>
          <li><img src="./assets/images/mt-0234-home-slide02.jpg"  title="ISO 9001:2000 & ISO 14001:2004 certification from Det Norske Veritas"  alt="mt-0234-home-slide02"/></li>
          <li><img src="./assets/images/mt-0234-home-slide01.jpg"  title="Quality is our primary concern for your satisfaction"  alt="mt-0234-home-slide03"/></li>
        </ul>
      </div>
    </div> -->
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row" style="margin-top: 80px;">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-12" data-container="container">
            <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default                                              wow fadeInRight moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aala">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p style="text-align: center;" class="moto-text_system_4">Downloads<br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-4 tcenter" data-container="container">
            <div class="moto-widget moto-widget-image moto-preset-default moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto wow fadeInUp" data-preset="default" data-spacing="aama" data-widget="image">
              <div class="moto-widget-image-link"> <img src="./assets/images/company_profile_icon.png" class="moto-widget-image-picture" data-id="91" title=""  alt="" draggable="false"> </div>
            </div>
            <div data-animation="fadeInUp" class="moto-widget moto-widget-text moto-preset-default                                              wow fadeInUp moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="mama">
            	<div class="clr40"></div>
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_system_9"><a class="moto-link" data-action="url" href="#" target="_self">COMPANY PROFILE</a><br>
                </p>
              </div>
              <div class="clr30"></div>
            </div>
            <div data-animation="fadeInUp" class="moto-widget moto-widget-text moto-preset-default                                          wow fadeInUp moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                
              </div>
            </div>
            <div data-spacing="sala" data-preset="3" data-widget="button" class="tcenter moto-widget moto-widget-button moto-preset-3 moto-align-left moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto wow fadeInRight" style="visibility: visible; animation-name: fadeInRight;"> <a data-size="medium" data-anchor="" class="moto-widget-button-link moto-size-medium moto-link" data-action="page" href="./assets/images/Profile.pdf" target="_blank"><span class="fa moto-widget-theme-icon"></span> <span class="moto-widget-button-label">CLICK HERE TO DOWNLOAD</span></a> </div>
          </div>

          <div class="moto-cell col-sm-4 tcenter" data-container="container">
            <div class="moto-widget moto-widget-image moto-preset-default moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto wow fadeInUp" data-preset="default" data-spacing="aama" data-widget="image">
              <div class="moto-widget-image-link"> <img src="./assets/images/product_catalogue_icon.png" class="moto-widget-image-picture" data-id="92" title=""  alt="" draggable="false"> </div>
            </div>
            <div data-animation="fadeInUp" class="moto-widget moto-widget-text moto-preset-default                                           wow fadeInUp moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="mama">
              <div class="clr40"></div>
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_system_9"><a class="moto-link" data-action="url" href="#" target="_self">PRODUCT BROCHURE</a><br>
                </p>
              </div>
              <div class="clr30"></div>
            </div>
            <div data-animation="fadeInUp" class="moto-widget moto-widget-text moto-preset-default                                         wow fadeInUp moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                
              </div>
            </div>
            
           <div data-spacing="sala" data-preset="3" data-widget="button" class="tcenter moto-widget moto-widget-button moto-preset-3 moto-align-left moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto wow fadeInRight" style="visibility: visible; animation-name: fadeInRight;"> 
            <a data-size="medium" data-anchor="" href="./assets/images/kotsons-product-brochure.pdf" class="moto-widget-button-link moto-size-medium moto-link" data-action="page"><span class="fa moto-widget-theme-icon"></span> <span class="moto-widget-button-label">CLICK HERE TO DOWNLOAD</span></a> </div> 
            
            
          </div>

          <div class="moto-cell col-sm-4 tcenter" data-container="container">
            <div class="moto-widget moto-widget-image moto-preset-default moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto wow fadeInUp" data-preset="default" data-spacing="aama" data-widget="image">
              <div class="moto-widget-image-link"> <img src="./assets/images/product_catalogue_icon.png" class="moto-widget-image-picture" data-id="93" title=""  alt="" draggable="false"> </div>
            </div>
            <div data-animation="fadeInUp" class="moto-widget moto-widget-text moto-preset-default                                           wow fadeInUp moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="mama">
              
              <div class="clr40"></div>
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_system_9"><a class="moto-link" data-action="url" href="#" target="_self">PRODUCT CATALOGUE</a><br>
                </p>
              </div>
              <div class="clr30"></div>
              
            </div>
            <div data-animation="fadeInUp" class="moto-widget moto-widget-text moto-preset-default                                         wow fadeInUp moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              
            </div>
            <div data-spacing="sala" data-preset="3" data-widget="button" class="tcenter moto-widget moto-widget-button moto-preset-3 moto-align-left moto-spacing-top-small moto-spacing-right-auto moto-spacing-left-auto wow fadeInRight" style="visibility: visible; animation-name: fadeInRight;"> <a data-size="medium" data-anchor="" class="moto-widget-button-link moto-size-medium moto-link" data-action="page" href="./assets/images/Broucher.pdf" target="_blank"><span class="fa moto-widget-theme-icon"></span> <span class="moto-widget-button-label">CLICK HERE TO DOWNLOAD</span></a> </div> 
            <!--<div class="clr30"></div>
            <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_normal">Our Product Catalog which includes a range transformers such as Single / Three phase, Distribution & Power Transformer, Dry Type Nomex Transformer, Oil Filled Transformers, Package Substation or Electrical Equipments <br>
                </p>
              </div> -->
              <div class="clr30"></div>
            <div class="moto-widget moto-widget-spacer moto-preset-default" data-widget="spacer" data-preset="default" data-spacing="">
              <div class="moto-widget-spacer-block" style="height: 6px;"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </section>