<div class="title">
	<h1>Mail Demo Page</h1>
</div>
<div class="mail">
	<form action='php/mail.php' method='post' id='mailForm' enctype='multipart/form-data'>
		<table>
			<tr>
				<td class="label"> Name : </td>
				<td><input type="text" id="name" name="name" class="form-input" placeholder='User Name'/>
					<div id="invalid-name" class="error_msg"></div>
				</td>
			</tr>
			<tr>
				<td class="label"> E-mail : </td>
				<td><input type="email" id="email" name="email" class="form-input" placeholder='E-Mail'/>
					<div id="invalid-email" class="error_msg"></div>
				</td>
			</tr>
			<tr>
				<td class="label"> From E-mail : </td>
				<td><input type="email" id="femail" name="femail" class="form-input" placeholder='From E-Mail'/>
					<div id="invalid-femail" class="error_msg"></div>
				</td>
			</tr>

			<tr>
				<td class="label"> Phone : </td>
				<td><input type="tel" id="phone" name="phone" class="form-input" placeholder='Phone'/>
					<div id="invalid-phone" class="error_msg"></div>
				</td>
			</tr>
			<tr>
				<td class="label"> Image : </td>
				<td><input type="file" id="image" name="image" class="form-input" placeholder='Image' accept="image/*"> <div id="invalid-image" class="error_msg"></div></td>

			</tr>

			<tr>
				<td class="label"> Message : </td>
				<td>
					<textarea cols="27" rows="5" id="message" name="message" placeholder='Message'></textarea>

					<div id="invalid-message" class="error_msg"></div></td>

				</tr>

				<tr>
					<td colspan="2"> <input type="submit" value="Send Mail!" id='submit_btn' name="submit_btn" class="submit_btn"/></td>
				</tr>
			</table>
		</form>
	</div>