import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-amorphous-core-transformers',
  templateUrl: './amorphous-core-transformers.component.html',
  styleUrls: ['./amorphous-core-transformers.component.css']
})
export class AmorphousCoreTransformersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  	
  }

}
