<section id="section-content" class="content page-3 moto-section" data-widget="section" data-container="section">
  	
	
	<div data-css-name="moto-container_content_56420832" class="moto-widget moto-widget-container moto-container_content_56420832" data-widget="container" data-container="container">
      <div class="moto-widget moto-widget-slider moto-preset-2 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-left-auto" data-widget="slider" data-preset="2" data-spacing="aala">
        <ul class="moto-widget-slider-list" data-moto-slider-options='{&quot;itemsCount&quot;:3,&quot;slideshowEnabled&quot;:true,&quot;slideshowDelay&quot;:5,&quot;slideshowAnimationType&quot;:&quot;fade&quot;,&quot;showNextPrev&quot;:false,&quot;showPaginationDots&quot;:true,&quot;showSlideCaptions&quot;:true}'>
          <li><img src="./assets/images/our-achievements-bannerimg.jpg"  style="visibility: visible;"></li>
          
        </ul>
      </div>
    </div>
	
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-12" data-container="container">
            <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default                              wow fadeInRight moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="lala">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p style="text-align: center;" class="moto-text_system_4">Our Achievements<br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          
		
          
		<div class="col-lg-10 col-md-10 col-md-offset-1 achievements">
			<div class="col-lg-3 col-md-3 tcenter bcolor"><span class="year">1979</span><br>First Manufacturing Plant at Alwar </div>
			<div class="col-lg-3 col-md-3 tcenter"><span class="year">1991</span><br>Second Manufacturing Plant at  Agra </div>
			<div class="col-lg-3 col-md-3 tcenter bcolor"><span class="year">1997</span><br>First Export Order </div>
			<div class="col-lg-3 col-md-3 tcenter"><span class="year">1998</span><br>ISO 9001 Certification </div>
		<div class="clr"></div>
		</div>
		<div class="col-lg-10 col-md-10 col-md-offset-1 achievements">
			<div class="col-lg-3 col-md-3 tcenter"><span class="year">1998</span><br>Export House Recognition</div>
			<div class="col-lg-3 col-md-3 tcenter bcolor"><span class="year">2002</span><br>Transformer from 100kVA to 1000kVA type<br>tested at KEMA, The Netherlands</div>
			<div class="col-lg-3 col-md-3 tcenter"><span class="year">2003</span><br>Niryat Shree Award for<br>year 2000-01 & Excellence<br>Export Performance Award<br>for the year 2001-02 by<br>Honorable Vice President<br>of India</div>
			<div class="col-lg-3 col-md-3 tcenter bcolor"><span class="year">2004</span><br>Manufacturing Plant of<br>Dry Type Transformers<br>with Dupont </div>
		<div class="clr"></div>
		</div>
		<div class="col-lg-10 col-md-10 col-md-offset-1 achievements">
			<div class="col-lg-3 col-md-3 tcenter bcolor"><span class="year">2004</span><br>Manufacturing Plant of Single Phase Wound Core Transformers at Alwar </div>
			<div class="col-lg-3 col-md-3 tcenter"><span class="year">2005</span><br>Manufacturing Plant for Amorphous Metal Core Transformers at Alwar </div>
			<div class="col-lg-3 col-md-3 tcenter bcolor"><span class="year">2005</span><br>Manufacturing Plant for Transformer Tanks at Agra</div>
			<div class="col-lg-3 col-md-3 tcenter"><span class="year">2006</span><br>Manufacturing Plant for Transformers at<br>Bazpur (Uttaranchal) </div>
		<div class="clr"></div>
		</div>
		<div class="col-lg-10 col-md-10 col-md-offset-1 achievements">
			<div class="col-lg-3 col-md-3 tcenter"><span class="year">2007</span><br>ISO 14001:2004 Certification </div>
			<div class="col-lg-3 col-md-3 tcenter bcolor"><span class="year">2008</span><br>New Manufacturing Plant at Alwar</div>
			<div class="col-lg-3 col-md-3 tcenter"><span class="year">2008</span><br>Among the first manufacturers to get Bureau of Energy Efficiency<br>(BEE)</div>
			<div class="col-lg-3 col-md-3 tcenter bcolor"><span class="year">2009</span><br>First manufacturer to<br>get 5 star labeling from<br>Bureau of Energy Efficiency<br>for 25 kVA & 63kVA<br>
Transformers</div>
		<div class="clr"></div>
		</div>
		<div class="col-lg-10 col-md-10 col-md-offset-1 achievements">
			<div class="col-lg-3 col-md-3 tcenter bcolor"><span class="year">2013</span><br>Largest capacity wound<br>core Transformers<br>manufactured in India</div>
			<div class="col-lg-3 col-md-3 tcenter"><span class="year">2015</span><br>State-of-the-art Manufacturing Plant for 3D Core Transformers</div>
			<div class="col-lg-3 col-md-3 tcenter bcolor"><span class="year">2015</span><br>Development & Manufacturing of higher rating energy efficient<br>Amorphous Core<br>
Transformers  upto 5MVA</div>
			<div class="col-lg-3 col-md-3 tcenter"><span class="year">2015</span><br>Manufacturing Plant for<br>Transformer Tanks with<br>state-of-the-art Machinery<br>(Robotic  Welding, HD Plasma <br>Cutting & CNC Corrugation <br>Line) at Alwar</div>
		<div class="clr"></div>
		</div>
		<div class="col-lg-10 col-md-10 col-md-offset-1 achievements lastrow">
			<div class="col-lg-3 col-md-3 tcenter"><span class="year">2015</span><br>OHSAS 45001:2018 <br>Certification</div>
			<div class="col-lg-3 col-md-3 tcenter bcolor"><span class="year">2016</span><br>Penetrating into New <br>Technology Market with<br>Innovative & Energy Efficient <br>Transformers for Solar <br>& Wind Applications</div>
			<div class="col-lg-3 col-md-3 tcenter"><span class="year">2016</span><br>Transformers type tested from KEMA,<br>The Netherlands<br>• 1000 kVA - 11 kV 3D Design<br>
• 1000 kVA - 33 kV EU Eco Design<br>
• 1600 kVA - 11 KV</div>
<div class="col-lg-3 col-md-3 tcenter bcolor">&nbsp;</div>
		<div class="clr"></div>
		</div>
		
        </div>
      </div>
    </div>
	
<div class="clr60"></div>


	<div data-css-name="moto-container_content_564307da" class="moto-widget moto-widget-container moto-container_content_564307da" data-widget="container" data-container="container">
      <div class="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="laaa">
        <div class="moto-widget-spacer-block" style="height: 0px;"></div>
      </div>
      <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
        <div class="container-fluid">
          <div class="row">
		  
            <div class="moto-cell col-sm-4" data-container="container">
              <div data-grid-type="xs" class="moto-widget moto-widget-row" data-widget="row">
                <div class="container-fluid">
                  <div class="row">
                    <div class="moto-cell col-xs-6 col-xs-offset-3" data-container="container">
                      <div class="moto-widget moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto wow fadeInRight" data-preset="default" data-spacing="aasa" data-widget="image">
                        <div class="moto-widget-image-link"> <img src="./assets/images/turnover-oval-img.png" class="moto-widget-image-picture" data-id="94" title=""  alt="" draggable="false"> </div>
						<div class="clr20"></div>
						<div class="tcenter blue15">TURNOVER</div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
			
			
			
            <div class="moto-cell col-sm-4" data-container="container">
              <div data-grid-type="xs" class="moto-widget moto-widget-row" data-widget="row">
                <div class="container-fluid">
                  <div class="row">
                    <div class="moto-cell col-xs-6 col-xs-offset-3" data-container="container">
                      <div class="moto-widget moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto wow fadeInRight" data-preset="default" data-spacing="aasa" data-widget="image">
                        <div class="moto-widget-image-link"> <img src="./assets/images/grossfit-oval-img.png" class="moto-widget-image-picture" data-id="95" title=""  alt="" draggable="false"> </div>
						<div class="clr20"></div>
						<div class="tcenter blue15">GROSS FIXED ASSETS</div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
			
			
			
            <div class="moto-cell col-sm-4" data-container="container">
              <div data-grid-type="xs" class="moto-widget moto-widget-row" data-widget="row">
                <div class="container-fluid">
                  <div class="row">
                    <div class="moto-cell col-xs-6 col-xs-offset-3" data-container="container">
                      <div class="moto-widget moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto wow fadeInRight" data-preset="default" data-spacing="aasa" data-widget="image">
                        <div class="moto-widget-image-link"> <img src="./assets/images/netwoth-oval-img.png" class="moto-widget-image-picture" data-id="96" title=""  alt="" draggable="false"> </div>
						<div class="clr20"></div>
						<div class="tcenter blue15">NET WORTH</div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="laaa">
        <div class="moto-widget-spacer-block" style="height: 0px;"></div>
      </div>
      
      
    </div>
	
	
	
    
    </section>