import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-technical-consultancy',
  templateUrl: './technical-consultancy.component.html',
  styleUrls: ['./technical-consultancy.component.css']
})
export class TechnicalConsultancyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  	
  }

}
