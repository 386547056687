import { BrowserModule } from '@angular/platform-browser';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { MDBBootstrapModule } from 'angular-bootstrap-md';

import { LightboxModule } from 'ngx-lightbox';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { AmorphousCoreTransformersComponent } from './components/amorphous-core-transformers/amorphous-core-transformers.component';
import { ApplyComponent } from './components/apply/apply.component';
import { ApplyOnlineComponent } from './components/apply-online/apply-online.component';
import { AwardsComponent } from './components/awards/awards.component';
import { CareerComponent } from './components/career/career.component';
import { CertificatesComponent } from './components/certificates/certificates.component';
import { ChairmanMessageComponent } from './components/chairman-message/chairman-message.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { CurrentOpeningsComponent } from './components/current-openings/current-openings.component';
import { DownloadsComponent } from './components/downloads/downloads.component';
import { DryTypeTransformersComponent } from './components/dry-type-transformers/dry-type-transformers.component';
import { EnvironmentalComponent } from './components/environmental/environmental.component';
import { FonttestComponent } from './components/fonttest/fonttest.component';
import { MailwithattachmentComponent } from './components/mailwithattachment/mailwithattachment.component';
import { OurAchievementsComponent } from './components/our-achievements/our-achievements.component';
import { OurCustomersComponent } from './components/our-customers/our-customers.component';
import { OurManufacturingComponent } from './components/our-manufacturing/our-manufacturing.component';
import { OurPresenceComponent } from './components/our-presence/our-presence.component';
import { PowerTransformersComponent } from './components/power-transformers/power-transformers.component';
import { ProductsComponent } from './components/products/products.component';
import { QualityTestingAasurancesComponent } from './components/quality-testing-aasurances/quality-testing-aasurances.component';
import { SinglePhaseTransformersComponent } from './components/single-phase-transformers/single-phase-transformers.component';
import { SpecialPurposeTransformersComponent } from './components/special-purpose-transformers/special-purpose-transformers.component';
import { TechnicalConsultancyComponent } from './components/technical-consultancy/technical-consultancy.component';
import { ThreePhaseTransformersComponent } from './components/three-phase-transformers/three-phase-transformers.component';
import { D3CoreTransformersComponent } from './components/d3-core-transformers/d3-core-transformers.component';

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    HeaderComponent,
    FooterComponent,
    AboutUsComponent,
    AmorphousCoreTransformersComponent,
    ApplyComponent,
    ApplyOnlineComponent,
    AwardsComponent,
    CareerComponent,
    CertificatesComponent,
    ChairmanMessageComponent,
    ContactsComponent,
    CurrentOpeningsComponent,
    DownloadsComponent,
    DryTypeTransformersComponent,
    EnvironmentalComponent,
    FonttestComponent,
    MailwithattachmentComponent,
    OurAchievementsComponent,
    OurCustomersComponent,
    OurManufacturingComponent,
    OurPresenceComponent,
    PowerTransformersComponent,
    ProductsComponent,
    QualityTestingAasurancesComponent,
    SinglePhaseTransformersComponent,
    SpecialPurposeTransformersComponent,
    TechnicalConsultancyComponent,
    ThreePhaseTransformersComponent,
    D3CoreTransformersComponent
  ],
  imports: [
    BrowserModule,
    // BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ReactiveFormsModule,
    FormsModule,
    MDBBootstrapModule.forRoot(),
    LightboxModule,
    SlickCarouselModule,
    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
