import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'kotsons';

  onActivate(event) {
    window.scroll(0,0);
    $("#div-toggler").removeClass("moto-widget-menu-mobile-open");
    
  } 

  ngOnInit() {  		
  }
  
}
