import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-manufacturing',
  templateUrl: './our-manufacturing.component.html',
  styleUrls: ['./our-manufacturing.component.css']
})
export class OurManufacturingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  	
  }

}
