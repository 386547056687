<section id="section-content" class="content page-3 moto-section" data-widget="section" data-container="section">
	<div class="moto-widget moto-widget-row row-fixed" data-widget="row">
		<div class="container-fluid">
			<div class="row">
				<div class="moto-cell col-sm-12" data-container="container">
					<div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default                              wow fadeInRight moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="lala">
						<div class="moto-widget-text-content moto-widget-text-editable">
							<p style="text-align: center;" class="moto-text_system_4">CHAIRMAN MESSAGE<br>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="moto-widget moto-widget-row row-fixed" data-widget="row">
		<div class="container-fluid">
			<div class="row">
				<div class="moto-cell col-sm-6" data-container="container">
					<div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default                            wow fadeInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
						<div class="moto-widget-text-content moto-widget-text-editable">
							<p class="moto-text_system_9">GET SOME INFORMATION FROM CHAIRMAN OF THE COMPANY<br>
							</p>
						</div>
					</div>
					<div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default                              wow fadeInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
						<div class="moto-widget-text-content moto-widget-text-editable">
							<p class="moto-text_normal">With expertise spanning almost three decades in transforming power into efficient energy that’s safe for users and the environment, KOTSONS has emerged as the undisputed leader in providing quality and reliable transformer manufacturing. At par with latest trends and internationally certified quality standards, KOTSONS has been instrumental in formulating short and long term plans to contribute in the development and promotion of the Nation's key industries, particularly in the field of power and distribution. <br><br>
								KOTSONS with its cost effective yet premium products and Total Quality Management (TQM), backed by a strong R&D team of engineers, together pave the path of continuous development that in turn contributes to an ever increasing graph of both sales and profit. <br><br>
								An ever growing geographical reach, market size and product range, coupled with efficient technology transfer programs, enables KOTSONS to offer the most adapted solution for every need, everywhere and with the same top class level of quality.<br><br>
							Our aim at KOTSONS is to pick up these varied power requirements and transform them into convincing solutions with maximum quality.</p>
						</div>
					</div>
				</div>
				<div class="moto-cell col-sm-6" data-container="container">
					<div class="moto-widget moto-widget-image moto-preset-default moto-align-center moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto wow fadeInRight" data-preset="default" data-spacing="aasa" data-widget="image">
						<div class="moto-widget-image-link"> <img src="./assets/images/Chairman-Message.jpg" class="moto-widget-image-picture" data-id="134" title=""  alt="Chairman-Message" draggable="false"> </div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="moto-widget moto-widget-row" data-widget="row">
		<div class="container-fluid">
			<div class="row">
				<div class="moto-cell col-sm-12" data-container="container">
					<div class="moto-widget moto-widget-spacer moto-preset-default                            moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="laaa">
						<div class="moto-widget-spacer-block" style="height: 1px;"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
</section>