<section id="section-content" class="content page-3 moto-section" data-widget="section" data-container="section">
  	
	
	<div data-css-name="moto-container_content_56420832" class="moto-widget moto-widget-container moto-container_content_56420832" data-widget="container" data-container="container">
      <div class="moto-widget moto-widget-slider moto-preset-2 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-left-auto" data-widget="slider" data-preset="2" data-spacing="aala">
        <ul class="moto-widget-slider-list" data-moto-slider-options='{&quot;itemsCount&quot;:3,&quot;slideshowEnabled&quot;:true,&quot;slideshowDelay&quot;:5,&quot;slideshowAnimationType&quot;:&quot;fade&quot;,&quot;showNextPrev&quot;:false,&quot;showPaginationDots&quot;:true,&quot;showSlideCaptions&quot;:true}'>
          <li><img src="./assets/images/quality-testing-aasurances-bannerimg.jpg" style="visibility: visible;"></li>
          
        </ul>
      </div>
    </div>
	
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-12" data-container="container">
            <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default                              wow fadeInRight moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="lala">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p style="text-align: center;" class="moto-text_system_4">Quality Testing / Assurances<br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-6" data-container="container">
            
            <div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default                              wow fadeInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_normal">Kotsons has ISO 9001:2015, ISO 14001:2015 & OHSAS 45001:2018 certification. Kotsons has set up a complete quality management system to offer the best customer satisfaction. Nothing has a higher priority than quality in engineering, in manufacturing, in sales and in services. The KOTSONS total quality program is based on the philosophy of prevention, and not just on the detection and correction of problems after the occur. In its quest to exceed the customers expectation by meeting their specified and implied needs at competitive prices and to ensure products and services of agreed quality on time every time, Kotsons is equipped to manufacture transformers whether it is a Single / Three phase, Distribution & power transformer, Dry type nomex transformer, oil filled transformers or Package substation with a wide range of internationally recognised standards.<br><br>
All transformers are subjected to all routine tests in our sophisticated laboratory in factory confirming to latest national and international standard specifications. Tests are intended for use for the basic validation of design and the basis for performance, safety, and reliability of the transformers.</p><br><br>
		<img src="./assets/images/quality-testing-img2.jpg"><br><br>
		
		<p class="moto-text_normal">
			Kotsons is equipped with adequate digital readout measuring devices wherever required and the digital sampling techniques with computer calculations. Precision digital meters such as digital power analyser, WT -130 of Yokogawa make are used for measuring of load losses, Impedance Voltage, No load losses, and no load currents.<br><br>

<strong class="moto-color3">Routine Tests</strong><br>
Following Routine Tests are made on every unit.electrical equipment exporters India<br>
•	Insulation Resistance Test<br>
•	Resistance measurement of winding<br>
•	Turns Ratio Test<br>
•	Polarity and phase relationship test<br>
•	No Load losses measurement<br>
•	Excitation current measurement<br>
•	Impedance voltage test<br>
•	Load Loss measurement<br>
•	Insulation oil Test<br>
•	Dielectric tests includes applied voltage test and induced voltage test<br>
•	Leakage Test of transformer tank<br>
		</p>
              </div>
            </div>
          </div>
          <div class="moto-cell col-sm-6" data-container="container">
            <div class="moto-widget moto-widget-image moto-preset-default moto-align-center moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto wow fadeInRight" data-preset="default" data-spacing="aasa" data-widget="image">
              <div class="moto-widget-image-link"> <img src="./assets/images/quality-testing-img1.jpg" class="moto-widget-image-picture" data-id="134" title=""  alt="" draggable="false"> </div>
			  
			  
			  
			  
			  <div class="clr20"></div>
			  
			  <p class="moto-text_normal">
			  
			  	<strong class="moto-color3">Type Tests</strong><br>
Type tests are made to prove to withstand the all irregularties in transmission/distribution line. Kotsons have inhouse facility to undergo temperature rise and impulse test conforming to meet to latest national and international standard specifications.<br><br>
Other tests such as short circuit withstand tests (Dynamic & Thermal) are conducted at an independent govt. recognised testing laboratory at Central Power Research Institute, Bhopal/Banglore, INDIA. Type tests are special tests. The test charges are to be borne by the customer.<br>
•	Impulse Voltage Test<br>
•	Temperature rise test<br>
•	Short Circuit Test<br><br>

<strong class="moto-color3">Guarantee/ Warrantee of the Transformer</strong><br>
Kotsons hereby warrant that the transformers supplied by us shall have no defect arising from the design, Materials or workmanship that may develop under normal use of the transformers in the conditions prevailing at the site of installation. <br><br>
This warranty does not cover accessories like porcelain bushings and its metal parts and breather. <br><br>
This warranty will be valid if the installation and maintenance of the transformers is not done as per our "Manual of installation, operation and maintenance" provided with the transformer. <br><br>
The guarantee of the individual transformer to be supplied shall be for 12 (Twelve) months from the date of delivery against any delivery manufacturing defect on account of improper design, bad workmanship or poor quality of raw material used etc. and any such defects occurring within the above period will be repaired/replaced by us free of cost, provided the maintenance of transformer are done as per relevant ISS/CBIP specification.
			  </p>
            </div>
		</div>
		
		
		
		  
		  
		  
		  
        </div>
		
		
		
		
      </div>
    </div>
    <div class="moto-widget moto-widget-row" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-12" data-container="container">
            <div class="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="laaa">
              <div class="moto-widget-spacer-block" style="height: 1px;"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>