import { Component, OnInit } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

   	albums: any [] = [];
	slideConfig: any = {
		"slidesToShow": 7, 
		"slidesToScroll": 1,
		"autoplay": true,
		// "nextArrow":"<div class='nav-btn next-slide'></div>",
		// "prevArrow":"<div class='nav-btn prev-slide'></div>",
		// "dots":true,
		"infinite": true
	};
	client_slides: any[] = [];

  	constructor(private _lightbox: Lightbox) { 

	    this.albums = [
	      	{
	     		src: './assets/images/mt-0234-home-gallery-img1.jpg',
	         	caption: '',
	         	thumb: './assets/images/mt-0234-home-gallery-img1.jpg'
	      	},
	      	{
	     		src: './assets/images/mt-0234-home-gallery-img2.jpg',
	         	caption: '',
	         	thumb: './assets/images/mt-0234-home-gallery-img2.jpg'
	      	},
	      	{
	     		src: './assets/images/mt-0234-home-gallery-img3.jpg',
	         	caption: '',
	         	thumb: './assets/images/mt-0234-home-gallery-img3.jpg'
	      	},
	      	{
	     		src: './assets/images/mt-0234-home-gallery-img4.jpg',
	         	caption: '',
	         	thumb: './assets/images/mt-0234-home-gallery-img4.jpg'
	      	},
	      	{
	     		src: './assets/images/mt-0234-home-gallery-img5.jpg',
	         	caption: '',
	         	thumb: './assets/images/mt-0234-home-gallery-img5.jpg'
	      	},
	      	{
	     		src: './assets/images/mt-0234-home-gallery-img6.jpg',
	         	caption: '',
	         	thumb: './assets/images/mt-0234-home-gallery-img6.jpg'
	      	}
	  	]

  	}


  ngOnInit() {
  	
	  this.client_slides = [
	    {
	    	img: "./assets/images/clients/1.jpg"
	    },
	    {
	    	img: "./assets/images/clients/2.jpg"
	    },
	    {
	    	img: "./assets/images/clients/3.jpg"
	    },
	    {
	    	img: "./assets/images/clients/4.jpg"
	    },
	    {
	    	img: "./assets/images/clients/5.jpg"
	    },
	    {
	    	img: "./assets/images/clients/6.jpg"
	    },
	    {
	    	img: "./assets/images/clients/7.jpg"
	    },
	    {
	    	img: "./assets/images/clients/8.jpg"
	    },
	    {
	    	img: "./assets/images/clients/9.jpg"
	    },
	    {
	    	img: "./assets/images/clients/10.jpg"
	    }
	  ];	  

  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this.albums, index, { fitImageInViewPort: true, wrapAround: true, showImageNumberLabel: true, centerVertically: true });
  }
 
  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

}