import { Component, OnInit } from '@angular/core';

import { FormControl, FormGroup } from '@angular/forms';

import { DataService } from '../../services/data.service';

import * as $ from 'jquery';

@Component({
  	selector: 'app-apply-online',
  	templateUrl: './apply-online.component.html',
  	styleUrls: ['./apply-online.component.css']
})
export class ApplyOnlineComponent implements OnInit {

  	constructor(private dataService: DataService) { }

  	applyOnlineForm = new FormGroup ({

		name: new FormControl(),
		mobile: new FormControl(),
		email: new FormControl(),
		dob: new FormControl(),
		marital_status: new FormControl(),
		state: new FormControl(),
		city: new FormControl(),
		exp_year: new FormControl(),
		exp_month: new FormControl(),
		post_applied: new FormControl(),
		department: new FormControl(),
		education: new FormControl(),
		
	});

	applyLoader: boolean = false;
	uploadFileLoader: boolean = false;

	showSuccess: boolean = false;
	showError: boolean = false;

	successMsg: string;
	errorMsg: string;

	fileUrl: string;

  	ngOnInit() {

	  	
  	}

  	uploadFile(event) {

  		this.showError = false;
  		this.showSuccess = false;

  		let fileList: FileList = event.target.files;

      	console.log(fileList);

      	if( fileList.length > 0 ) {

      		this.uploadFileLoader = true;

     		  let file: File = fileList[0];

         	let formData:FormData = new FormData();
         	formData.append('file', file, file.name);

           console.log(formData);

         	this.dataService.uploadFile(formData)
         	.subscribe(data => {

         		this.uploadFileLoader = false;

         		this.showSuccess = true;
         		this.successMsg = "File uploaded successfully."

            	var profile_pic_response = data.response;
            	console.info(profile_pic_response);

            	this.fileUrl = profile_pic_response.file_url;

          
         	}, error => {

         		this.uploadFileLoader = false;
         		this.showError = true;
         		this.errorMsg = "An Error Occurred";
            	console.log(error);
            	
            
         	});


      	}

  	}

  	submitApplyOnline() {

  		this.showError = false;
  		this.showSuccess = false;

  		var apply_info = this.applyOnlineForm.value;
  		console.log(apply_info);

      if( apply_info.marital_status == null ) {
        alert("Please select marital status.")
        return;
      }

      if( this.fileUrl )
        apply_info.file = this.fileUrl

  		this.applyLoader = true;

		this.dataService.submitApplyOnline(apply_info)
		.subscribe(response => {

			var news_response = response;
			console.info(news_response);

			this.showSuccess = true;
			this.successMsg = 'Apply Successfully.';
			this.applyLoader = false;


		}, error => {
			console.log(error);
			this.showError = false;
			this.applyLoader = false;
			this.errorMsg = 'An Error Occurred';
			
		});


  	}

}
