
<!-- Modal -->
<div id="qualityModal" class="modal fade" role="dialog">
  <div class="modal-dialog modal-lg">

    <!-- Modal content-->
    <div class="modal-content">
      <button type="button" class="close" data-dismiss="modal">&times;</button>
      <div class="modal-body">
        <div class="qualitydialog">
          <div class="blueline"></div>
            <div class="yellowline"></div>
            <h2 class="tcenter">QUALITY, ENVIRONMENTAL & SAFETY POLICY</h2>
            <div><img src="./assets/images/quality-banner-img.jpg"></div>
            <p>
              “We at KOTSONS are committed to design, manufacture & supply transformers of agreed quality on time,
every time to the satisfaction of our customers<br><br>
KOTSONS is further committed to prevention of pollution in all forms from its operations and will comply to
all applicable legal requirements.<br><br>
KOTSONS cares for the occupational Health & Safety and is committed to prevention of injury and ill
health of its employees as well as other interested parties and will comply with all applicable legal
requirements<br><br>
KOTSONS shall continually improve the effectiveness of the integrated management system.”
            </p>
        </div>  
      </div>
    </div>
  </div>
</div>


<div id="chairmanMessage" class="modal fade" role="dialog">
  <div class="modal-dialog modal-lg">

    <!-- Modal content-->
    <div class="modal-content">
      <button type="button" class="close" data-dismiss="modal">&times;</button>
      <div class="modal-body">
        <div class="chairmanMessage">
          <div class="blueline"></div>
            <div class="yellowline"></div>
           
              <h2 class="tcenter">CHAIRMAN MESSAGE</h2>
                <div class="clr20"></div>
        <div class="row">
                  <div class="col-lg-4" style="text-align:center!important;">
                      <img src="./assets/images/Chairman-Message.jpg">
                    </div>
                    <div class="col-lg-8">
                      <p>
                          With expertise spanning almost four decades in transforming
power into efficient energy that’s safe for users and the
environment, KOTSONS has emerged as the undisputed
leader in providing quality and reliable transformer
manufacturing. At par with latest trends and internationally
certified quality standards, KOTSONS has been instrumental in
formulating short and long term plans to contribute in the
development and promotion of the Nation's key industries,
particularly in the field of power and distribution.<br><br>
KOTSONS with its cost effective yet premium products and
Total Quality Management (TQM), backed by a strong R&D
team of engineers, together pave the path of continuous
development that in turn contributes to an ever increasing
graph of both sales and profit.<br><br>
An ever growing geographical reach, market size and product
range, coupled with efficient technology transfer programs,
enables KOTSONS to offer the most adapted solution for every
need, everywhere and with the same top class level of quality.<br><br>
Our aim at KOTSONS is to pick up these varied power
requirements and transform them into convincing solutions with maximum quality
                        </p>
                    </div>
                </div>
           
        </div>  
      </div>
    </div>
  </div>
</div>


<div id="certificates" class="modal fade" role="dialog">
  <div class="modal-dialog modal-lg">

    <!-- Modal content-->
    <div class="modal-content">
      <button type="button" class="close" data-dismiss="modal">&times;</button>
      <div class="modal-body">
        <div class="certificates">
          <div class="blueline"></div>
            <div class="yellowline"></div>
           
              <h2 class="tcenter">CERTIFICATES</h2>
                <div class="clr20"></div>
        <div class="row">
                
                <div class="col-lg-4"><img src="./assets/images/certificates/certificate-img1.jpg"></div>
                <div class="col-lg-4"><img src="./assets/images/certificates/certificate-img2.jpg"></div>  
                <div class="col-lg-4"><img src="./assets/images/certificates/certificate-img3.jpg"></div>    
                
                <div class="clr30"></div>
                
                <div class="col-lg-4"><img src="./assets/images/certificates/certificate-img4.jpg"></div>
                <div class="col-lg-4"><img src="./assets/images/certificates/certificate-img5.jpg"></div>  
                <div class="col-lg-4"><img src="./assets/images/certificates/certificate-img6.jpg"></div>    
                
                <div class="clr30"></div>
                
                <div class="row">
                <div class="col-lg-4 col-md-offset-2"><img src="./assets/images/certificates/certificate-img7.jpg"></div>
                <div class="col-lg-4"><img src="./assets/images/certificates/certificate-img8.jpg"></div>  
                <div class="clr"></div>
                </div>
                
                
                </div>
        </div>  
      </div>
    </div>
  </div>
</div>


<div id="award" class="modal fade" role="dialog">
  <div class="modal-dialog modal-lg">

    <!-- Modal content-->
    <div class="modal-content" style="border:30px solid #ececec;">
      <button type="button" class="close" data-dismiss="modal">&times;</button>
      <div class="blueline"></div>
            <div class="yellowline"></div>
      <div class="modal-body">
        <div class="award">
          
           
              <h2 class="tcenter">AWARDS</h2>
                <div class="clr20"></div>
        <div class="row" >
                
                <div class="col-lg-4"><img src="./assets/images/awards/award-img1.jpg" style="
                  height:245px;"></div>
                <div class="col-lg-4"><img src="./assets/images/awards/award-img2.jpg" style="
                  height:245px;"></div>
                <div class="col-lg-4"><img src="./assets/images/awards/award-img3.jpg" style="
                  height:245px;"></div>  
                
                <div class="clr30"></div>
                
                 <!-- <div class="col-lg-4"><img src="./assets/images/awards/award-img4.jpg" style="height:245px;"></div> -->
                  <div class="col-lg-4"><img src="./assets/images/awards/Picture_026.jpg" style="
                  height:245px;"></div> 
                  
                 <!-- <div class="col-lg-4"><img src="./assets/images/awards/award-img5.jpg" style="
                  height:245px;"></div> -->
                  <div class="col-lg-4"><img src="./assets/images/awards/Siddharth_Jain.jpg" style="height:235px;"></div>  
                <!-- <div class="col-lg-4"><img src="./assets/images/awards/award-img6.jpg" style="
                  height:245px;"></div> -->  
                  <div class="col-lg-4"><img src="./assets/images/awards/Picture_003.jpg" style="
                  height:245px;"></div> 
                
                <div class="clr30"></div>
                
                <div class="col-lg-4"><img src="./assets/images/awards/award-img7.jpg" style="
                  height:245px;"></div>  
                  
<!--                 <div class="col-lg-4"><img src="./assets/images/awards/award1.png" style="
                  height:245px;"></div> -->  

<!--                 <div class="col-lg-4"><img src="./assets/images/awards/award2.png" style="
                  height:245px;"></div> -->

                <div class="clr30"></div>
                
<!--                 <div class="col-lg-4"><img src="./assets/images/awards/award3.png" style="
                  height:245px;"></div> -->

                <div class="col-lg-4"><img src="./assets/images/awards/award4.png" style="
                  height:245px;"></div>  
               <!--  <div class="col-lg-4"><img src="./assets/images/awards/award5.png" style="
                  height:245px;"></div> --> 
                  <div class="col-lg-4"><img src="./assets/images/awards/Picture_021.jpg" style="
                  height:245px;"></div>

                <div class="clr30"></div>
                
                <!-- <div class="col-lg-4"><img src="./assets/images/awards/award7.png" style="
                  height:245px;"></div>  --> 
                   <div class="col-lg-4"><img src="./assets/images/awards/Picture_007.jpg" style="
                  height:245px;"></div>  
                <!-- <div class="col-lg-4"><img src="./assets/images/awards/Awards.jpg" style="
                  height:245px;"></div> -->  
                  <div class="col-lg-4"><img src="./assets/images/awards/Picture_006.jpg" style="
                  height:245px;"></div>
                <div class="col-lg-4"><img src="./assets/images/awards/1.jpg" style="
                  height:245px;"></div>

                <div class="clr30"></div>
                
                <div class="col-lg-4"><img src="./assets/images/awards/award-img5.jpg" style="
                  height:245px;"></div>  
                <!-- <div class="col-lg-4"><img src="./assets/images/awards/MD_Receiving_Trophy_from_VP.jpg" style="
                  height:245px;"></div>   -->

<!--                    <div class="col-lg-4"><img src="./assets/images/awards/Picture_010.jpg" style="
                  height:245px;"></div> -->

                


                <!-- <div class="clr30"></div>
                
                  
               
               
                <div class="clr30"></div> -->
                
                <!-- <div class="col-lg-4"><img src="./assets/images/awards/Picture_011.jpg" style="
                  height:245px;"></div>  
                <div class="col-lg-4"><img src="./assets/images/awards/Picture_014.jpg" style="
                  height:245px;"></div>   -->
                

                <div class="clr30"></div>
                
                 
                
                


                </div>
        </div>  
      </div>
    </div>
  </div>
</div>

<div id="delivery" class="modal fade" role="dialog">
  <div class="modal-dialog modal-lg">

    <!-- Modal content-->
    <div class="modal-content">
      <button type="button" class="close" data-dismiss="modal">&times;</button>
      <div class="modal-body">
        <div class="qualitydialog">
          <div class="blueline"></div>
            <div class="yellowline"></div>
            <h2 class="tcenter">DELIVERY</h2>
            <div><img src="./assets/images/delivery-banner-img.jpg"></div>
            <p>
              
A basic requirement for any transformer company is to deliver power to the consumer efficiently. At Kotsons we use the best carriers in the business to make sure that the order gets delivered to our customers worldwide on time. Working together with customer our quality,  manufacturing and engineering groups work as a team to provide accurate, on time and damage free delivery of each Kotsons transformer. 



            </p>
        </div>  
      </div>
    </div>
  </div>
</div>


<div class="page">
  <header id="section-header" class="header moto-section" data-widget="section" data-container="section">
    <div class="moto-widget moto-widget-row" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-4" data-container="container">
            <div data-grid-type="xs" class="moto-widget moto-widget-row" data-widget="row">
              <div class="container-fluid">
                <div class="row">
                  <div class="moto-cell col-xs-10" data-container="container">
                    
                    <div class="moto-widget moto-widget-image moto-preset-default moto-align-right moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto " data-preset="default" data-spacing="saaa" data-widget="image"> <a class="moto-widget-image-link moto-link" href="" routerLink="/"   data-action="page"> <img src="./assets/images/logo.png" class="moto-widget-image-picture" data-id="84" title=""  alt="" draggable="false"> </a> </div>
                    <div class="moto-widget moto-widget-spacer moto-preset-default" data-widget="spacer" data-preset="default" data-spacing="">
                      <div class="moto-widget-spacer-block" style="height:10px;"></div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div class="moto-cell col-sm-2" data-container="container"></div>
          
        </div>
      </div>
    </div>
    <div moto-sticky="{ }" data-css-name="moto-container_header_56420099" class="moto-widget moto-widget-container moto-container_header_56420099" data-widget="container" data-container="container">
      <div id="div-toggler" class="moto-widget moto-widget-menu moto-preset-2 moto-align-left moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-preset="2" data-widget="menu" data-align="{&quot;desktop&quot;:&quot;left&quot;,&quot;tablet&quot;:&quot;&quot;,&quot;mobile-h&quot;:&quot;center&quot;,&quot;mobile-v&quot;:&quot;&quot;}" data-menu-id="1" data-menu-type="horizontal" data-spacing="aaaa"> <a href="#" class="moto-widget-menu-toggle-btn" id="btn-toggler"><i class="moto-widget-menu-toggle-btn-icon fa fa-bars"></i></a>
        <ul class="moto-widget-menu-list moto-widget-menu-list_horizontal">
          <li class="moto-widget-menu-item"> 
          		<a href="" routerLink="/" [class.moto-widget-menu-link-active]="current_state == ''"  data-action="page" class="moto-widget-menu-link moto-widget-menu-link-level-1 moto-widget-menu-link-submenu moto-widget-menu-link-active moto-link">HOME</a>
          </li>
          <li class="moto-widget-menu-item"> 
            <a href="" routerLink="/about-us" data-action="page" [class.moto-widget-menu-link-active]="current_state == 'about'" class="moto-widget-menu-link moto-widget-menu-link-level-1 moto-link">ABOUT US</a> </li>

          <li class="moto-widget-menu-item"> 

            <a href="" routerLink="/products" data-action="page" [class.moto-widget-menu-link-active]="current_state == 'products'" class="moto-widget-menu-link moto-widget-menu-link-level-1 moto-link">
              PRODUCTS<span class="fa moto-widget-menu-link-arrow"></span>
            </a> 

        		<ul class="moto-widget-menu-sublist">
              <li class="moto-widget-menu-item"> 
                <a href="" routerLink="/single-phase-transformers" data-action="page" class="moto-widget-menu-link moto-widget-menu-link-level-2 moto-link">CSP Distribution Transformers</a> 
              </li>

              <li class="moto-widget-menu-item"> 
                <a href="" routerLink="/three-phase-transformers" data-action="page" class="moto-widget-menu-link moto-widget-menu-link-level-2 moto-widget-menu-link-submenu moto-link">Three Phase Distribution Transformers</a>
              </li>

              <li class="moto-widget-menu-item"> 
                <a href="" routerLink="/power-transformers"  data-action="page" class="moto-widget-menu-link moto-widget-menu-link-level-2 moto-link">Power Transformers</a> 
              </li>

              <li class="moto-widget-menu-item"> 
                <a href="" routerLink="/amorphous-core-transformers" data-action="page" class="moto-widget-menu-link moto-widget-menu-link-level-2 moto-link">Amorphous Core Transformers</a> 
              </li>

              <li class="moto-widget-menu-item"> 
                <a href="" routerLink="/3d-core-transformers" data-action="page" class="moto-widget-menu-link moto-widget-menu-link-level-2 moto-link">3D Core Transformers</a> 
              </li>

              <li class="moto-widget-menu-item"> 
                <a href="" routerLink="/dry-type-transformers" data-action="page" class="moto-widget-menu-link moto-widget-menu-link-level-2 moto-link">Dry Type Transformers</a> 
              </li>

              <li class="moto-widget-menu-item"> 
                <a href="" routerLink="/special-purpose-transformers" data-action="page" class="moto-widget-menu-link moto-widget-menu-link-level-2 moto-link">Special Purpose Transformers</a> 
              </li>

            </ul>
          </li>
          
          <li class="moto-widget-menu-item"> 
            <a href="" routerLink="/downloads" [class.moto-widget-menu-link-active]="current_state == 'downloads'" data-action="page" class="moto-widget-menu-link moto-widget-menu-link-level-1 moto-link">DOWNLOADS</a> 
          </li>

          <li class="moto-widget-menu-item"> 
            
            <a data-action="page" class="moto-widget-menu-link moto-widget-menu-link-level-1 moto-link" style="cursor: pointer;">
              CAREER<span class="fa moto-widget-menu-link-arrow"></span>
            </a>

          		<ul class="moto-widget-menu-sublist">

              <li class="moto-widget-menu-item"> 
                <a href="" routerLink="/current-openings" [class.moto-widget-menu-link-active]="current_state == 'current'" data-action="page" class="moto-widget-menu-link moto-widget-menu-link-level-2 moto-link">Current Openings</a>
              </li>

              <li class="moto-widget-menu-item"> 
                <a href="" routerLink="/apply-online" [class.moto-widget-menu-link-active]="current_state == 'apply-online'" data-action="page" class="moto-widget-menu-link moto-widget-menu-link-level-2 moto-widget-menu-link-submenu moto-link">Apply Online</a>
              </li>

            </ul>
          </li>
          
          <!-- <li class="moto-widget-menu-item"> 
            <a href="#"  data-action="page" class="moto-widget-menu-link moto-widget-menu-link-level-1 moto-link">IN NEWS</a> 
          </li> -->
          
          <li class="moto-widget-menu-item"> 
            <a href="" routerLink="/contacts" [class.moto-widget-menu-link-active]="current_state == 'contacts'" class="moto-widget-menu-link moto-widget-menu-link-level-1 moto-link">CONTACT US</a> 
          </li>
        
<!-- <li class="moto-widget-menu-item" style="padding-left:300px; background-color:inherit;">
  <img src="http://hitwebcounter.com/counter/counter.php?page=6449846&style=0005&nbdigits=6&type=page&initCount=0" title="webs counters" Alt="webs counters"   border="0" >
  <br/>
  <a href="http://www.hitwebcounter.com" title="Total Count" 
  target="_blank" style="font-family: sans-serif, Arial, Helvetica; 
  font-size:9px; color: #6D6C72; text-decoration: none ;">
  </a>   
</li> -->

        

<!-- END: Powered by Supercounters.com -->  
        </ul>
      </div>
    </div>
  </header>

</div>