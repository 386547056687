<section id="section-content" class="content page-3 moto-section" data-widget="section" data-container="section">
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-12" data-container="container">
            <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default                              wow fadeInRight moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="lala">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p style="text-align: center;" class="moto-text_system_4">Amorphous Core Transformers<br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-6" data-container="container">
            <div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default wow fadeInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_normal">KOTSONS has set up state of the art amorphous metal core transformer manufacturing facility by importing the latest amorphous metal cores manufacturing equipment. This plant has the latest equipment in India to produce energy efficient amorphous metal transformer cores that are annealed under absolute inert atmosphere to get at most lower losses thereby enhancing the energy savings in comparison with the similar manufacturing facilities in India.<br><br>
Amorphous metal exhibits a unique random molecular structure unlike the rigid grain structure of the silicon steel. This, in turn, enables easy magnetization & demagnetization, thereby reducing hysteresis loss. Further processing of amorphous metal in very thin lamination (approximately 1/10th of silicon steel lamination thickness) enables significant reduction in eddy current losses.<br><br>

<strong class="moto-color3">Advantages of Amorphous Metal Transformers over Transformers with CRGO Silicon Steel :</strong><br>
1.	The thickness of Amorphous Metal is 0.025 mm against CRGO silicon 	sheet steel thickness 0.23-0.3 mm. Lesser in thickness in sheet results in lower eddy current loss<br>
2.	Random molecular structure of amorphous metal causes less friction 	than CRGO when a magnetic field is applied. This allows easy magnetization and demagnetization significantly lowers hysteresis 	losses, thus amorphous core significantly reduces core losses which is 	about 65-75%<br>
3.	Saves energy and therefore reduces greenhouse gases and other 	pollution<br>
4.	Excellent option to reduce distribution losses and improve efficiency<br>
5.	Superior electrical performance under harmonic condition. Possible to improve power quality and mitigate harmonics<br>
6.	Lower temperature rise, slower deterioration of insulations and hence longer life<br>
7.	Increase in use of power electronics has resulted in considerable amount of higher harmonics distortion in electrical power system.	Higher frequency harmonics lead to increase in transformer core losses whereas amorphous alloy provides lower loss under high frequency<br>
8.	Easy for repair and replacement of coils</p>
              </div>
            </div>
          </div>
          <div class="moto-cell col-sm-6" data-container="container">
            <div class="moto-widget moto-widget-image moto-preset-default moto-align-center moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto wow fadeInRight" data-preset="default" data-spacing="aasa" data-widget="image">
              <div class="moto-widget-image-link"> <img src="./assets/images/amorphous-img1.jpg" class="moto-widget-image-picture" data-id="134" title=""  alt="" draggable="false"> </div>
			  <div class="clr30"></div>
			  <div class="moto-widget-image-link"> <img src="./assets/images/amorphous-img2.jpg" class="moto-widget-image-picture" data-id="134" title=""  alt="" draggable="false"> </div>

            </div>
            
          </div>
        </div>
      </div>
    </div>
    <div class="clr30"></div>
    <div data-css-name="moto-container_content_56433db6" class="moto-widget moto-widget-container moto-container_content_56433db6" data-widget="container" data-container="container">
      <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
        <div class="container-fluid">
          <div class="row">
            <div class="moto-cell col-sm-12" data-container="container">
				<div class="clr50"></div>
              <p style="text-align: left;"><strong>A comparison between typical Silicon Steel Distribution Transformers, High-Efficiency Silicon Steel Distribution Transformers & Amorphous Metal Distribution Transformers (AMDT) :</strong><br>
                  </p>
				  <div class="clr50"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
        <div class="container">
          <div class="row">
          <table class="table table-striped amorphoustbl">
    <thead>
      <tr>
        <th width="25%">Transformer rating<br>3 Phase, 11 kV</th>
        <th width="25%">Core losses with best<br>grade of CRGO (Watts)</th>
        <th width="25%">Typical core loss with<br>Amorphous metal (Watts)</th>
		<th width="25%">% Loss reduction /<br>Energy saving</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>25 kVA</td>
        <td>80</td>
        <td>28</td>
		<td>65%</td>
      </tr>
      <tr>
        <td>100 kVA</td>
        <td>260</td>
        <td>66</td>
		<td>75%</td>
      </tr>
      <tr>
        <td>250 kVA</td>
        <td>520</td>
        <td>150</td>
		<td>71%</td>
      </tr>
	  <tr>
        <td>1000 kVA</td>
        <td>1800</td>
        <td>350</td>
		<td>77%</td>
      </tr>
	  <tr>
        <td>2500 kVA</td>
        <td>3000</td>
        <td>750</td>
		<td>75%</td>
      </tr>
	  <tr>
        <td>5000 kVA</td>
        <td>5000</td>
        <td>1200</td>
		<td>76%</td>
      </tr>
    </tbody>
  </table>  
            
            
          </div>
        </div>
      </div>
    </div>
	<div class="clr60"></div>
	<div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-6" data-container="container">
            <div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default wow fadeInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_normal"><strong class="moto-color3" style="color:#000">General Description</strong><br>
<strong class="moto-color3">Applicable Standards : IEC / ANSI / BS / IS / SABS / CENELEC / GOST</strong><br>
Wide range of transformers in accordance to above standards with different combinations can be manufactured. The transformers are generally designed and manufactured to comply with the IEC publications 60076. <br><br>
<strong class="moto-color3">Single Phase: 5kVA-167 kVA, Three Phase: 10 kVA-5000 kVA,<br>
Insulation level  £ 12 kV  £ 24 kV  £ 36 kV</strong><br>
•	Indoor / Outdoor installation<br>
•	Conductor: Copper or Aluminum as per customer's requirement<br>
•	Hermetically sealed type (with gas cushion / fully filled) / Conventional type<br> &nbsp;&nbsp;(with Conservator)<br>
•	Color : as per customer's requirement<br>
•	Cooling : ONAN/ONAF as per customer's requirement<br>
•	Cooling arrangement : Corrugation or Pressed steel Radiators<br>
•	Maximum ambient temperature : as per customer's specification<br>
•	Top oil Temperature rise : as per customer's specification</p>
              </div>
            </div>
          </div>
          <div class="moto-cell col-sm-6" data-container="container">
            <div class="moto-widget moto-widget-image moto-preset-default moto-align-center moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto wow fadeInRight" data-preset="default" data-spacing="aasa" data-widget="image">
              <div class="moto-widget-image-link"> <img src="./assets/images/amorphous-img3.jpg" class="moto-widget-image-picture" data-id="134" title=""  alt="" draggable="false"> </div>
			  

            </div>
            
          </div>
        </div>
      </div>
	  
	  
	  
	  
	  
    </div>
    
   
   <div class="clr60"></div>
    
    
  <div data-stellar-background-ratio="0.5" data-css-name="moto-container_content_amorphous" class="moto-widget moto-widget-container moto-parallax moto-container_content_amorphous" data-widget="container" data-container="container">
    <div class="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="lama">
      <div class="moto-widget-spacer-block" style="height: 35px;"></div>
    </div>
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-2" data-container="container"></div>
          <div class="moto-cell col-sm-8" data-container="container">
            <div data-animation="fadeIn" class="moto-widget moto-widget-text moto-preset-default wow fadeIn moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sasa">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p style="text-align: center;" class="moto-text_system_5">Quality service<br>
                </p>
                <p style="text-align: center;" class="moto-text_system_5">is our aim<br>
                </p>
              </div>
            </div>
          </div>
          <div class="moto-cell col-sm-2" data-container="container"></div>
        </div>
      </div>
    </div>
    <div class="moto-widget moto-widget-spacer moto-preset-default              moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="lama">
      <div class="moto-widget-spacer-block" style="height: 35px;"></div>
    </div>
  </div> 
    
   
    
  </section>