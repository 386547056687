<section id="section-content" class="content page-3 moto-section" data-widget="section" data-container="section">
  	
	
	<div data-css-name="moto-container_content_56420832" class="moto-widget moto-widget-container moto-container_content_56420832" data-widget="container" data-container="container">
      <div class="moto-widget moto-widget-slider moto-preset-2 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-left-auto" data-widget="slider" data-preset="2" data-spacing="aala">
        <ul class="moto-widget-slider-list" data-moto-slider-options='{&quot;itemsCount&quot;:3,&quot;slideshowEnabled&quot;:true,&quot;slideshowDelay&quot;:5,&quot;slideshowAnimationType&quot;:&quot;fade&quot;,&quot;showNextPrev&quot;:false,&quot;showPaginationDots&quot;:true,&quot;showSlideCaptions&quot;:true}'>
          <li><img src="./assets/images/our-presence-bannerimg.jpg" style="visibility: visible;"></li>
          
        </ul>
      </div>
    </div>
	
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-12" data-container="container">
            <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default                              wow fadeInRight moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="lala">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p style="text-align: center;" class="moto-text_system_4">Our Presence<br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          
          
		
		
		<div data-stellar-background-ratio="0.5" data-css-name="moto-container_content_564319e0" class="moto-widget moto-widget-container moto-parallax moto-container_content_564319e0" data-widget="container" data-container="container" style="overflow:hidden; height:100%;">
    <div id="container_video">
    		<video autoplay loop muted width="100%">
                    <source src="video/Kotsons_Map Part.mp4" type="video/mp4">
                </video>
      			
      </div>
      
    </div>
	
	<div class="countriesname">
		<div class="col-lg-2">
			<ul>EUROPE
				<li>United Kingdom </li>
				<li>Greece</li>
				<li>Spain</li>
				<li>Ireland</li>
			</ul>
		</div>
				<div class="col-lg-2">
			<ul>MIDDLE EAST
				<li>Dubai</li>
				<li>Qatar</li>
				<li>Iraq</li>
				<li>Jordan</li>
				<li>Oman</li>
				<li>Syria</li>
				<li>Yemen</li>
			</ul>
		</div>

		<div class="col-lg-2">
			<ul>AFRICA
				<li>Angola</li>
				<li>Benin</li>
				<li>Burkina Faso</li>
				<li>Ghana</li>
				<li>Gambia</li>
				<li>Guiana</li>
				<li>Ivory Coast</li>
				<li>Libya</li>
				<li>Malawi</li>
				<li>Mauritius</li>
				<li>Mozambique</li>
				<li>Nigeria</li>
				<li>Rwanda</li>
				<li>Senegal</li>
				<li>Seychelles</li>
				<li>Tanzania</li>
				<li>Togo</li>
				<li>Zambia</li>
				<li>Zimbabwe</li>
			</ul>
		</div>

		<div class="col-lg-2">
			<ul>ASIA
				<li>India</li>
				<li>Bangladesh</li>
				<li>Bhutan</li>
				<li>Japan</li>
				<li>Maldives</li>
				<li>Nepal</li>
				<li>Philippines</li>
				<li>Thailand</li>
			</ul>
		</div>

		<div class="col-lg-2">
			<ul>SOUTH AMERICA
				<li>Suriname</li>
			</ul>
		</div>

		<div class="col-lg-2">
			<ul>OCEANIA
				<li>Australia</li>
			</ul>
		</div>

	</div>
		  
		  
		  
		  
        </div>
		
		
		
		
      </div>
    </div>
    <div class="moto-widget moto-widget-row" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-12" data-container="container">
            <div class="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="laaa">
              <div class="moto-widget-spacer-block" style="height: 1px;"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
</section>