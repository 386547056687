import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dry-type-transformers',
  templateUrl: './dry-type-transformers.component.html',
  styleUrls: ['./dry-type-transformers.component.css']
})
export class DryTypeTransformersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  	
  }

}
