<section id="section-content" class="content page-3 moto-section" data-widget="section" data-container="section">
	
	<div class="container-fluid">
		
		<div data-css-name="moto-container_content_56432492" class="moto-widget moto-widget-container moto-container_content_56432492" data-widget="container" data-container="container">
			<div class="moto-widget moto-widget-row row-fixed" data-widget="row">
				<div class="container-fluid">
					<div class="row">
						<div class="moto-cell col-sm-12" data-container="container">
							<div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default                  wow fadeInRight moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="lama">
								<div class="moto-widget-text-content moto-widget-text-editable">
									<p style="text-align: center;" class="moto-text_system_4">CERTIFICATES<br>
									</p>
								</div>
							</div>
							<div class="moto-widget moto-widget-spacer moto-preset-default                           moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="masa">
								<div class="moto-widget-spacer-block" style="height: 0px;"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="moto-widget moto-widget-row row-fixed" data-widget="row">
				<div class="container-fluid">
					<div class="row">
						<div class="moto-cell col-sm-12" data-container="container">
							<div class="moto-widget moto-widget-grid-gallery moto-preset-default moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto " data-widget="grid_gallery" data-preset="default" data-spacing="mama">
								<div class="moto-widget-grid-gallery-items"  data-moto-grid-gallery-options='{&quot;rowHeight&quot;:260,&quot;fixedHeight&quot;:true,&quot;margins&quot;:30,&quot;lastRow&quot;:&quot;justify&quot;,&quot;showCaptions&quot;:true,&quot;enableLightbox&quot;:true}'> 
									<a href="./assets/images/certificates/certificate1.png" class="moto-widget-grid-gallery-item"> <img src="./assets/images/certificates/certificate1.png" alt="certificate1" class="moto-widget-grid-gallery-image">
										<div class="caption caption-empty">
											<p></p>
										</div>
									</a> <a href="./assets/images/certificates/certificate2.png" class="moto-widget-grid-gallery-item"> <img src="./assets/images/certificates/certificate2.png" alt="certificates" class="moto-widget-grid-gallery-image">
										<div class="caption caption-empty">
											<p></p>
										</div>
									</a> <a href="./assets/images/certificates/certificate3.png" class="moto-widget-grid-gallery-item"> <img src="./assets/images/certificates/certificate3.png" alt="certificate3" class="moto-widget-grid-gallery-image">
										<div class="caption caption-empty">
											<p></p>
										</div>
									</a> <a href="./assets/images/certificates/certificate4.png" class="moto-widget-grid-gallery-item"> <img src="./assets/images/certificates/certificate4.png" alt="certificate4" class="moto-widget-grid-gallery-image">
										<div class="caption caption-empty">
											<p></p>
										</div>
									</a> <a href="./assets/images/certificates/certificate5.png" class="moto-widget-grid-gallery-item"> <img src="./assets/images/certificates/certificate5.png" alt="certificate5" class="moto-widget-grid-gallery-image">
										<div class="caption caption-empty">
											<p></p>
										</div>
									</a> <a href="./assets/images/certificates/certificate6.png" class="moto-widget-grid-gallery-item"> <img src="./assets/images/certificates/certificate6.png" alt="certificate6" class="moto-widget-grid-gallery-image">
										<div class="caption caption-empty">
											<p></p>
										</div>
									</a>  </div>
								</div>
								<div class="moto-widget moto-widget-spacer moto-preset-default                           moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="masa">
									<div class="moto-widget-spacer-block" style="height: 0px;"></div>
								</div>
								<div class="moto-widget moto-widget-button moto-preset-4 moto-align-center moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto " data-widget="button" data-preset="4" data-spacing="mala"> <a href="" routerLink="/awards"  data-action="page" class="moto-widget-button-link moto-size-medium moto-link"
									data-anchor=""
									data-size="medium"><span class="fa moto-widget-theme-icon"></span> <span class="moto-widget-button-label">Awards</span></a> </div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</section>