import { Component, OnInit } from '@angular/core';

import { FormControl, FormGroup } from '@angular/forms';

import { DataService } from '../../services/data.service';

import * as $ from 'jquery';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {

  	constructor(private dataService: DataService) { }

  	addContactForm = new FormGroup ({

		name: new FormControl(),
		mobile: new FormControl(),
		email: new FormControl(),
		message: new FormControl()
		
	});

	contactLoader: boolean = false;
	showSuccess: boolean = false;
	showError: boolean = false;

	successMsg: string;
	errorMsg: string;

  	ngOnInit() {

	  	
  	}

  	submitContact() {

  		this.showError = false;
  		this.showSuccess = false;

  		var customer_info = this.addContactForm.value;
  		console.log(customer_info);

  		this.contactLoader = true;

		this.dataService.submitContact(customer_info)
		.subscribe(response => {

			var news_response = response;
			console.info(news_response);

			this.showSuccess = true;
			this.successMsg = 'Your Query has been sent successfully.';
			this.contactLoader = false;


		}, error => {
			console.log(error);
			this.showError = false;
			this.contactLoader = false;
			this.errorMsg = 'An Error Occurred';
			
		});


  	}

}
