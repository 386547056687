<div class="page">
  <section id="section-content" class="content page-1 moto-section" data-widget="section" data-container="section">

    <mdb-carousel class="carousel slide carousel-fade" [animation]="'slide'">
      
      <mdb-carousel-item>
        <div class="view w-100">
          <img class="d-block w-100" src="./assets/images/mt-0234-home-slide03.jpg" alt="Second slide">
          <div class="rgba-black-strong waves-light" mdbWavesEffect></div>
        </div>
        <div class="carousel-caption">
          <h1 class="h3-responsive" style="font-weight: bold;">ISO 9001:2015, ISO 14001:2015, OHSAS 45001:2018 Certified Company</h1>
        </div>
      </mdb-carousel-item>

      <mdb-carousel-item>
        <div class="view w-100">
          <img class="d-block w-100" src="./assets/images/mt-0234-home-slide02.jpg" alt="Second slide">
          <div class="rgba-black-strong waves-light" mdbWavesEffect></div>
        </div>
        <div class="carousel-caption">
          <h1 class="h3-responsive" style="font-weight: bold;">ISO 9001:2015, ISO 14001:2015, OHSAS 45001:2018 Certified Company</h1>
        </div>
      </mdb-carousel-item>
      <mdb-carousel-item>
        <div class="view w-100">
          <img class="d-block w-100" src="./assets/images/mt-0234-home-slide01.jpg" alt="Third slide">
          <div class="rgba-black-slight waves-light" mdbWavesEffect></div>
        </div>
        <div class="carousel-caption">
          <h1 class="h3-responsive" style="font-weight: bold;">Quality is our primary concern for your
          satisfaction</h1>
        </div>
      </mdb-carousel-item>

    </mdb-carousel>

    
    <!-- <div data-css-name="moto-container_content_56420832" class="moto-widget moto-widget-container moto-container_content_56420832" data-widget="container" data-container="container">
      <div class="moto-widget moto-widget-slider moto-preset-2 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto  moto-widget-slider-loader" data-widget="slider" data-preset="2" data-spacing="aala">
        <ul class="moto-widget-slider-list" data-moto-slider-options='{&quot;itemsCount&quot;:3,&quot;slideshowEnabled&quot;:true,&quot;slideshowDelay&quot;:5,&quot;slideshowAnimationType&quot;:&quot;fade&quot;,&quot;showNextPrev&quot;:false,&quot;showPaginationDots&quot;:true,&quot;showSlideCaptions&quot;:true}'>
          <li><img src="./assets/images/mt-0234-home-slide03.jpg"  title="ISO 9001:2008, ISO 14001:2004 &
OHSAS 45001:2018 Certified Company"  alt="mt-0234-home-slide01"/></li>
          <li><img src="./assets/images/mt-0234-home-slide02.jpg"  title="ISO 9001:2008, ISO 14001:2004 &
OHSAS 45001:2018 Certified Company"  alt="mt-0234-home-slide02"/></li>
          <li><img src="./assets/images/mt-0234-home-slide01.jpg"  title="Quality is our primary concern for your
satisfaction"  alt="mt-0234-home-slide03"/></li>
        </ul>
      </div>
    </div> -->
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row" style="margin-top: 80px !important;">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-12" data-container="container">
            <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default                                              wow fadeInRight moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aala">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p style="text-align: center;" class="moto-text_system_4">"TRANSFORMING ELECTRICITY
EMPOWERING WORLD"<br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-4" data-container="container">
            <div class="moto-widget moto-widget-image moto-preset-default moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto wow fadeInUp" data-preset="default" data-spacing="aama" data-widget="image">
              <div class="moto-widget-image-link"> <img src="./assets/images/mt-0234-home-img01.jpg" class="moto-widget-image-picture" data-id="91" title=""  alt="" draggable="false"> </div>
            </div>
            <div data-animation="fadeInUp" class="moto-widget moto-widget-text moto-preset-default wow fadeInUp moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="mama">
              <div class="clr20"></div>
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_system_9">QUALITY TESTING / ASSURANCES</p>
              </div>
            </div>
            <div data-animation="fadeInUp" class="moto-widget moto-widget-text moto-preset-default wow fadeInUp moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_normal">Kotsons has ISO 9001:2015, ISO 14001:2015 & OHSAS 45001:2018 certification. Kotsons has set up a complete quality management system to offer the best customer satisfaction. Nothing has a higher priority than quality in engineering, in manufacturing, in sales and in services. The KOTSONS total quality program is based on the philosophy of prevention, and not just on the detection and correction of problems</p>
              </div>
            </div>
            <div class="moto-widget moto-widget-button moto-preset-5 moto-align-left moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto wow fadeInUp" data-widget="button" data-preset="5" data-spacing="sama"> 
              <a href="" routerLink="/quality-testing-aasurances"   data-action="page" class="moto-widget-button-link moto-size-medium moto-link"
            data-anchor=""
         data-size="medium"><span class="fa moto-widget-theme-icon"></span> <span class="moto-widget-button-label">READ MORE</span></a> </div>
          </div>
          <div class="moto-cell col-sm-4" data-container="container">
            <div class="moto-widget moto-widget-image moto-preset-default moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto wow fadeInUp" data-preset="default" data-spacing="aama" data-widget="image">
              <div class="moto-widget-image-link"> <img src="./assets/images/mt-0234-home-img02.jpg" class="moto-widget-image-picture" data-id="92" title=""  alt="" draggable="false"> </div>
            </div>
            <div data-animation="fadeInUp" class="moto-widget moto-widget-text moto-preset-default wow fadeInUp moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="mama">
              <div class="clr20"></div>
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_system_9">NEW SUPPLIER / REGISTRATION</p>
              </div>
            </div>
            <div data-animation="fadeInUp" class="moto-widget moto-widget-text moto-preset-default wow fadeInUp moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_normal">KOTSONS is a manufacturers and exporters of distribution package substations, Dry Type Nomex, Oil Filled Transformer, Distribution & power transformers and Electrical Equipment in India. <br><br>
				
				Please <a href="http://kotsons.com/download/assesment_Form.pdf" target="_blank">Click here</a> to download Assesment Form.<br><br>
				
				If you do not have Adobe acrobat, please<br> <a href="https://get.adobe.com/reader/" target="_blank">Click here</a>
                </p>
              </div>
            </div>
            
          </div>
          <div class="moto-cell col-sm-4" data-container="container">
            <div class="moto-widget moto-widget-image moto-preset-default moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto wow fadeInUp" data-preset="default" data-spacing="aama" data-widget="image">
              <div class="moto-widget-image-link"> <img src="./assets/images/mt-0234-home-img03.jpg" class="moto-widget-image-picture" data-id="93" title=""  alt="" draggable="false"> </div>
            </div>
            <div data-animation="fadeInUp" class="moto-widget moto-widget-text moto-preset-default wow fadeInUp moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="mama">
              <div class="clr20"></div>
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_system_9">TECHNICAL CONSULTANCY</p>
              </div>
            </div>
            <div data-animation="fadeInUp" class="moto-widget moto-widget-text moto-preset-default wow fadeInUp moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_normal">We are providing Technical Consultancy to set-up Repair/Manufacturing shop for Power & Distribution Transformers. At present we are providing Technical Consultancy to many foreign companies to set-up Repair/Manufacturing shop. We undertake the whole project which includes following steps :<br><br><br>
                </p>
              </div>
            </div>
            <div class="moto-widget moto-widget-button moto-preset-5 moto-align-left moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto wow fadeInUp" data-widget="button" data-preset="5" data-spacing="sala"> 
              <a href="" routerLink="/technical-consultancy"  data-action="page" class="moto-widget-button-link moto-size-medium moto-link"
            data-anchor=""
         data-size="medium"><span class="fa moto-widget-theme-icon"></span> <span class="moto-widget-button-label">READ MORE</span></a> </div>
            <div class="moto-widget moto-widget-spacer moto-preset-default" data-widget="spacer" data-preset="default" data-spacing="">
              <div class="moto-widget-spacer-block" style="height: 6px;"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div data-css-name="moto-container_content_564307da" class="moto-widget moto-widget-container moto-container_content_564307da" data-widget="container" data-container="container">
      <div class="clr60"></div>
      <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
        <div class="container-fluid">
          <div class="row">
            <div class="moto-cell col-sm-4" data-container="container">
              <div data-grid-type="xs" class="moto-widget moto-widget-row" data-widget="row">
                <div class="container-fluid">
                  <div class="row">
                    <div class="moto-cell col-xs-5" data-container="container">
                      <div class="moto-widget moto-widget-image moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto wow fadeInRight" data-preset="default" data-spacing="aasa" data-widget="image">
                        <div class="moto-widget-image-link"> <img src="./assets/images/mt-0234-home-icon1.png" class="moto-widget-image-picture" data-id="94" title=""  alt="" draggable="false"> </div>
                      </div>
                    </div>
                    <div class="moto-cell col-xs-7" data-container="container">
                      <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default wow fadeInRight moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sasa">
                        <div class="moto-widget-text-content moto-widget-text-editable">
                          <p class="moto-text_system_9"><span class="moto-color2_5">OBJECTIVES</span><br>
                          </p>
                        </div>
                      </div>
                      <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default wow fadeInRight moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
                        <div class="moto-widget-text-content moto-widget-text-editable">
                          <div class="moto-text_system_10">
            						  	<ul class="objectives">
            								  <li>To provide competitiveness in 	price with continuous improvement in quality.</li>
            								  <li>Assurance to deliver goods on time.</li>
            								  <li>To provide optimum productivity through improved working methods and motivation of the employees.</li>
            								  <li>Ensure involvement and 	cooperation of our employees through effective communication and training on a regular basis.</li>
            							   </ul>
                          </div>
                        </div>
                      </div>
                      <div class="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="saaa">
                        <div class="moto-widget-spacer-block" style="height: 0px;"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="moto-cell col-sm-4" data-container="container">
              <div data-grid-type="xs" class="moto-widget moto-widget-row" data-widget="row">
                <div class="container-fluid">
                  <div class="row">
                    <div class="moto-cell col-xs-5" data-container="container">
                      <div class="moto-widget moto-widget-image moto-preset-default  moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto wow fadeInRight" data-preset="default" data-spacing="aasa" data-widget="image">
                        <div class="moto-widget-image-link"> <img src="./assets/images/mt-0234-home-icon2.png" class="moto-widget-image-picture" data-id="95" title=""  alt="" draggable="false"> </div>
                      </div>
                    </div>
                    <div class="moto-cell col-xs-7" data-container="container">
                      <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default wow fadeInRight moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sasa">
                        <div class="moto-widget-text-content moto-widget-text-editable">
                          <p class="moto-text_system_9"><span class="moto-color2_5">QUALITY, ENVIRONMENTAL & SAFETY POLICY</span><br>
                          </p>
                        </div>
                      </div>
                      <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default wow fadeInRight moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
                        <div class="moto-widget-text-content moto-widget-text-editable">
                          <p class="moto-text_system_10">“We at KOTSONS are committed to design, manufacture & supply transformers of agreed quality on time, every time to the satisfaction of our customers<br><br>

                          KOTSONS is further committed to prevention of pollution in all forms from its operations and will comply to all applicable legal requirements. <br><br> 
                          <a href="#qualityModal" data-toggle="modal" data-target="#qualityModal"><b>READ MORE >></b></a><br>

                          </p>
                        </div>
                      </div>
                      <div class="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="saaa">
                        <div class="moto-widget-spacer-block" style="height: 0px;"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="moto-cell col-sm-4" data-container="container">
              <div data-grid-type="xs" class="moto-widget moto-widget-row" data-widget="row">
                <div class="container-fluid">
                  <div class="row">
                    <div class="moto-cell col-xs-5" data-container="container">
                      <div class="moto-widget moto-widget-image moto-preset-default  moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto wow fadeInRight" data-preset="default" data-spacing="aasa" data-widget="image">
                        <div class="moto-widget-image-link"> <img src="./assets/images/mt-0234-home-icon3.png" class="moto-widget-image-picture" data-id="96" title=""  alt="" draggable="false"> </div>
                      </div>
                    </div>
                    <div class="moto-cell col-xs-7" data-container="container">
                      <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default wow fadeInRight moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sasa">
                        <div class="moto-widget-text-content moto-widget-text-editable">
                          <p class="moto-text_system_9"><span class="moto-color2_5">CHAIRMAN MESSAGE</span><br>
                          </p>
                        </div>
                      </div>
                      <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default moto-spacing-bottom-large                                wow fadeInRight moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
                        <div class="moto-widget-text-content moto-widget-text-editable">
                          <p class="moto-text_system_10">With expertise spanning almost four decades in transforming power into efficient energy that’s safe for users and the environment, KOTSONS has emerged as the undisputed leader <br><br> <a href="#qualityModal" data-toggle="modal" data-target="#chairmanMessage"><b>READ MORE >></b></a></p>
                        </div>
                      </div>
                      <div class="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="saaa">
                        <div class="moto-widget-spacer-block" style="height: 0px;"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
        <div class="container-fluid">
          <div class="row">
            <div class="moto-cell col-sm-4" data-container="container">
              <div data-grid-type="xs" class="moto-widget moto-widget-row" data-widget="row">
                <div class="container-fluid">
                  <div class="row">
                    <div class="moto-cell col-xs-5" data-container="container">
                      <div class="moto-widget moto-widget-image moto-preset-default  moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto wow fadeInRight" data-preset="default" data-spacing="aasa" data-widget="image">
                        <div class="moto-widget-image-link"> <img src="./assets/images/mt-0234-home-icon4.png" class="moto-widget-image-picture" data-id="97" title=""  alt="" draggable="false"> </div>
                      </div>
                    </div>
                    <div class="moto-cell col-xs-7" data-container="container">
                      <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default wow fadeInRight moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sasa">
                        <div class="moto-widget-text-content moto-widget-text-editable">
                          <p class="moto-text_system_9"><span class="moto-color2_5">DELIVERY</span><br>
                          </p>
                        </div>
                      </div>
                      <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default wow fadeInRight moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
                        <div class="moto-widget-text-content moto-widget-text-editable">
                          <p class="moto-text_system_10">A basic requirement for any transformer company is to deliver power to the consumer efficiently.
                          <br><br> <a href="#delivery" data-toggle="modal" data-target="#delivery"><b>READ MORE >></b></a>
                          </p>
                        </div>
                      </div>
                      <div class="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="saaa">
                        <div class="moto-widget-spacer-block" style="height: 0px;"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="moto-cell col-sm-4" data-container="container">
              <div data-grid-type="xs" class="moto-widget moto-widget-row" data-widget="row">
                <div class="container-fluid">
                  <div class="row">
                    <div class="moto-cell col-xs-5" data-container="container">
                      <div class="moto-widget moto-widget-image moto-preset-default  moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto wow fadeInRight" data-preset="default" data-spacing="aasa" data-widget="image">
                        <div class="moto-widget-image-link"> <img src="./assets/images/mt-0234-home-icon5.png" class="moto-widget-image-picture" data-id="98" title=""  alt="" draggable="false"> </div>
                      </div>
                    </div>
                    <div class="moto-cell col-xs-7" data-container="container">
                      <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default wow fadeInRight moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sasa">
                        <div class="moto-widget-text-content moto-widget-text-editable">
                          <p class="moto-text_system_9"><span class="moto-color2_5">CERTIFICATION</span><br>
                          </p>
                        </div>
                      </div>
                      <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default wow fadeInRight moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
                        <div class="moto-widget-text-content moto-widget-text-editable">
                          <p class="moto-text_system_10">Kotsons has ISO 9001:2015, ISO 14001:2015 & OHSAS 45001:2018 certification<br><br> <a href="#certificates" data-toggle="modal" data-target="#certificates"><b>READ MORE >></b></a></p>
                        </div>
                      </div>
                      <div class="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="saaa">
                        <div class="moto-widget-spacer-block" style="height: 0px;"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="moto-cell col-sm-4" data-container="container">
              <div data-grid-type="xs" class="moto-widget moto-widget-row" data-widget="row">
                <div class="container-fluid">
                  <div class="row">
                    <div class="moto-cell col-xs-5" data-container="container">
                      <div class="moto-widget moto-widget-image moto-preset-default  moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto wow fadeInRight" data-preset="default" data-spacing="aasa" data-widget="image">
                        <div class="moto-widget-image-link"> <img src="./assets/images/mt-0234-home-icon6.png" class="moto-widget-image-picture" data-id="99" title=""  alt="" draggable="false"> </div>
                      </div>
                    </div>
                    <div class="moto-cell col-xs-7" data-container="container">
                      <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default                                      wow fadeInRight moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sasa">
                        <div class="moto-widget-text-content moto-widget-text-editable">
                          <p class="moto-text_system_9"><span class="moto-color2_5">AWARDS</span></p>
                        </div>
                      </div>
					  
                      <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default wow fadeInRight moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
                        <div class="moto-widget-text-content moto-widget-text-editable">
                          <p class="moto-text_system_10">Excellence Award for Export for the year of 2003-2004 UP State Export Award for the year 2001-2002
Highest Prestigious Export.<br><br> <a href="#award" data-toggle="modal" data-target="#award"><b>READ MORE >></b></a>
                          </p>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div class="clr30"></div>
		  </div>
        </div>
      </div>
      
    </div>
    <div class="clr40"></div>
    <div style="display:none;" class="moto-widget moto-widget-row row-fixed" data-widget="row"><p class="moto-text_system_4" style="text-align: center;">TESTIMONIALS</p>
	<div class="clr30"></div>
      <div class="container-fluid" style="display:none;">
        <div class="row">
          <div class="moto-cell col-sm-12" data-container="container">
            <div data-css-name="moto-container_content_56430fe8" class="moto-widget moto-widget-container moto-container_content_56430fe8" data-widget="container" data-container="container">
              
              <div class="moto-widget moto-widget-row" data-widget="row">
                <div class="container-fluid">
                  
				  
				 
				  <div class="row">
				  	
                    <div class="testimonial">
						<div>
							<div class="moto-cell col-sm-3" data-container="container">
						  <div class="tcenter moto-widget moto-widget-image moto-preset-default moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto " data-preset="default" data-spacing="aama" data-widget="image">
							<div class="moto-widget-image-link"> <img src="./assets/images/mt-0234-home-img1.jpg" class="moto-widget-image-picture" data-id="103" title=""  alt="" draggable="false"> </div>
						  </div>
						</div>
							<div class="moto-cell col-sm-9" data-container="container">
						  <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
							
						  </div>
						  <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
							<div class="moto-widget-text-content moto-widget-text-editable">
							  <p class="moto-text_system_10"><span class="moto-color5_3">Kotsons has ISO 9001:2015, ISO 14001:2015 & OHSAS 45001:2018 certification. Kotsons has set up a complete quality management system to offer the best customer satisfaction.<br><br>Kotsons has ISO 9001:2015, ISO 14001:2015 & OHSAS 45001:2018 certification. Kotsons has set up a complete quality management system to offer the best customer satisfaction.</span><br>
							  </p>
							</div>
						  </div>
						  
						  
						</div>
						</div>
						
						<div>
							<div class="moto-cell col-sm-3" data-container="container">
						  <div class="tcenter moto-widget moto-widget-image moto-preset-default moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto " data-preset="default" data-spacing="aama" data-widget="image">
							<div class="moto-widget-image-link"> <img src="./assets/images/mt-0234-home-img1.jpg" class="moto-widget-image-picture" data-id="103" title=""  alt="" draggable="false"> </div>
						  </div>
						</div>
							<div class="moto-cell col-sm-9" data-container="container">
						  <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
							
						  </div>
						  <div class="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
							<div class="moto-widget-text-content moto-widget-text-editable">
							  <p class="moto-text_system_10"><span class="moto-color5_3">Kotsons has ISO 9001:2015, ISO 14001:2015 & OHSAS 45001:2018 certification. Kotsons has set up a complete quality management system to offer the best customer satisfaction.<br><br>Kotsons has ISO 9001:2015, ISO 14001:2015 & OHSAS 45001:2018 certification. Kotsons has set up a complete quality management system to offer the best customer satisfaction.</span><br>
							  </p>
							</div>
						  </div>
						  
						  
						</div>
						</div>
					</div>
					
                  </div>
				  
                </div>
              </div>
            </div>
            <div class="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="lasa">
              <div class="moto-widget-spacer-block" style="height: 1px;"></div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <div data-stellar-background-ratio="0.5" data-css-name="moto-container_content_564319e0" class="moto-widget moto-widget-container moto-parallax moto-container_content_564319e0" data-widget="container" data-container="container" style="overflow:hidden; height:450px;">
    <div id="container_video">
    		<video autoplay loop muted width="100%">
                    <source src="./assets/video/KotsonsWebsite1.mp4" type="video/mp4">
                    <source src="./assets/video/KotsonsWebsite1.webm" type="video/webm">
                </video>
      			<div class="content_video">
                    We make CRGO Steel and<br> Amorphous Metal Transformers
                </div>
      </div>
      
    </div>
    <div class="clr60"></div>
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-5" data-container="container">
            <div class="moto-widget moto-widget-image moto-preset-default moto-align-center_mobile-h moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto wow fadeInLeft" data-preset="default" data-spacing="maaa" data-widget="image">
              <div class="moto-widget-image-link"> <img src="./assets/images/mt-0234-home-img2.jpg" class="moto-widget-image-picture" data-id="107" title=""  alt="" draggable="false"> </div>
            </div>
          </div>
          <div class="moto-cell col-sm-7" data-container="container">
            <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default wow fadeInRight moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_system_4"><span class="moto-color1_3">Latest Innovation</span><br>
                </p>
              </div>
            </div>
            <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default wow fadeInRight moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_normal">KOTSONS Pvt. Ltd., as a part of their continuous efforts towards promotion of energy efficient and green technology environment has added a new product to their portfolio, the 3D Core transformers.<br><br>
3D Core is a structure with 3 identical single frame continuously wound by several trapezoid tape; three pillars of the core shape a equilateral triangle and the 3 phases are symmetrical. 3-phase magnetic circuits of the core are completely balanced; the magnetization direction
                </p>
              </div>
            </div>
            <div class="moto-widget moto-widget-button moto-preset-3 moto-align-left moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto wow fadeInRight" data-widget="button" data-preset="3" data-spacing="sala"> 
              <a href="" routerLink="/3d-core-transformers"  data-action="page" class="moto-widget-button-link moto-size-medium moto-link"
            data-anchor=""
         data-size="medium"><span class="fa moto-widget-theme-icon"></span> <span class="moto-widget-button-label">CLICK HERE</span></a> </div>
            <div class="moto-widget moto-widget-spacer moto-preset-default                                  moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="saaa">
              <div class="moto-widget-spacer-block" style="height: 1px;"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
	<div class="clr60"></div>
    <div data-css-name="moto-container_content_56431b79" class="moto-widget moto-widget-container moto-container_content_56431b79" data-widget="container" data-container="container">
      <div class="moto-widget moto-widget-spacer moto-preset-default  moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="mama">
        <div class="moto-widget-spacer-block" style="height: 35px;"></div>
      </div>
      <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
        <div class="container-fluid">
          <div class="row">
            <div class="moto-cell col-sm-8" data-container="container">
              <div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default wow fadeInLeft moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sama">
                <div class="moto-widget-text-content moto-widget-text-editable">
                  <p class="moto-text_system_4"><span class="moto-color1_3">Our Manufacturing</span><br>
                  </p>
                </div>
              </div>
              <div class="moto-widget moto-widget-row" data-widget="row">
                <div class="container-fluid">
                  <div class="row">
                    <div class="moto-cell col-sm-11" data-container="container">
                      <div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default wow fadeInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
                        <div class="moto-widget-text-content moto-widget-text-editable">
                          <p class="moto-text_normal">Kotsons has ISO 9001:2015, ISO 14001:2015 & OHSAS 45001:2018 certification, is setting new benchmarks in quality and versatility. The state-of-the-art KOTSONS manufacturing units are fully equipped with the latest automated machinery and backed by a proficient team of well experienced engineers practicing processes that produce best in class quality, leaving almost no room for insufficiencies. Whether Single/Three phase, Distribution & Power Transformers, <br>
                          </p>
                        </div>
                      </div>
                      <div class="moto-widget moto-widget-button moto-preset-3 moto-align-left moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto wow fadeInLeft" data-widget="button" data-preset="3" data-spacing="sala"> 
                        <a href="" routerLink="/our-manufacturing"  data-action="page" class="moto-widget-button-link moto-size-medium moto-link" data-anchor="" data-size="medium"><span class="fa moto-widget-theme-icon"></span> <span class="moto-widget-button-label">CLICK HERE</span></a> </div>
                      <div class="moto-widget moto-widget-spacer moto-preset-default                                 moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="saaa">
                        <div class="moto-widget-spacer-block" style="height: 1px;"></div>
                      </div>
                    </div>
                    <div class="moto-cell col-sm-1" data-container="container"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="moto-cell col-sm-4" data-container="container">
              <div class="moto-widget moto-widget-image moto-preset-5 moto-align-center moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto wow fadeInRight" data-preset="5" data-spacing="aala" data-widget="image">
                <div class="moto-widget-image-link"> <img src="./assets/images/mt-0234-home-img3.jpg" class="moto-widget-image-picture" data-id="108" title=""  alt="" draggable="false"> </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div data-stellar-background-ratio="0.5" data-css-name="moto-container_content_56431e91" class="moto-widget moto-widget-container moto-parallax moto-container_content_56431e91" data-widget="container" data-container="container" style="overflow:hidden; height:450px;">
    
    <div id="container_video">
    		<video autoplay loop muted width="100%">
                    <source src="video/KotsonsWebsite2.mp4" type="video/mp4">
                    <source src="video/KotsonsWebsite2.webm" type="video/webm">
                </video>
      			<div class="content_video">
                    Inner control standards <br>in the manufacturing process 
                </div>
      </div>
      
      
      
      
      
      <div class="moto-widget moto-widget-spacer moto-preset-default  moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="lama">
        <div class="moto-widget-spacer-block" style="height: 35px;"></div>
      </div>
    </div>
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-12" data-container="container">
            <div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default                     wow fadeInLeft moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="lala">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p style="text-align: center;" class="moto-text_system_4">OUR STRENGTH<br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-4" data-container="container">
            <div data-animation="fadeInUp" class="moto-widget moto-widget-text moto-preset-default wow fadeInUp moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aasa">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_system_6">OUR PRESENCE<br>
                </p>
              </div>
            </div>
            <div data-animation="fadeInUp" class="moto-widget moto-widget-text moto-preset-default wow fadeInUp moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sama">
              <div class="presense moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_normal" align="justify"><strong>5</strong> Manufacturing units in India<br> <strong>35+</strong> years of Experience<br> <strong>5+</strong> Lakh Transformers in Network<br> <strong>40+</strong> Countries with Network of Agents across the Globe<br> <strong>10+</strong> Awards for Highest Exports from India<br> Turnover INR 200-250 cr. / USD 40-45 million.</p>
              </div>
            </div>
            <div class="moto-widget moto-widget-button moto-preset-4 moto-align-left moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto wow fadeInUp" data-widget="button" data-preset="4" data-spacing="aama"> 
              <a href="" routerLink="/our-presence" data-action="page" class="moto-widget-button-link moto-size-medium moto-link"
            data-anchor=""
         data-size="medium"><span class="fa moto-widget-theme-icon"></span> <span class="moto-widget-button-label">CLICK HERE</span></a> </div>
          </div>
          <div class="moto-cell col-sm-4" data-container="container">
            <div data-animation="fadeInUp" class="moto-widget moto-widget-text moto-preset-default wow fadeInUp moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aasa">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_system_6">OUR CUSTOMERS<br>
                </p>
              </div>
            </div>
            <div data-animation="fadeInUp" class="moto-widget moto-widget-text moto-preset-default wow fadeInUp moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sama">
              <div class="moto-widget-text-content moto-widget-text-editable presense">
                <p class="moto-text_normal" align="justify">We at Kotsons are committed to design, manufacture and supply transformers of agreed quality on time, every time to the satisfaction of our customers.<br>
                </p>
              </div>
            </div>
            <div class="moto-widget moto-widget-button moto-preset-4 moto-align-left moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto wow fadeInUp" data-widget="button" data-preset="4" data-spacing="aama"> 
              <a href="" routerLink="/our-customers" data-action="page" class="moto-widget-button-link moto-size-medium moto-link"
            data-anchor=""
         data-size="medium"><span class="fa moto-widget-theme-icon"></span> <span class="moto-widget-button-label">CLICK HERE</span></a> </div>
          </div>
          <div class="moto-cell col-sm-4" data-container="container">
            <div data-animation="fadeInUp" class="moto-widget moto-widget-text moto-preset-default wow fadeInUp moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aasa">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_system_6">OUR ACHIEVEMENTS<br>
                </p>
              </div>
            </div>
            <div data-animation="fadeInUp" class="moto-widget moto-widget-text moto-preset-default wow fadeInUp moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sama">
              <div class="moto-widget-text-content moto-widget-text-editable presense">
                <p class="moto-text_normal" align="justify">With Expertise spanning almost four decades in transforming power into efficient energy that's safe for users and the environment, Kotsons has emerged as the undisputed leader in providing quality and reliable transformer manufacturing.<br>
                </p>
              </div>
            </div>
            <div class="moto-widget moto-widget-button moto-preset-4 moto-align-left moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto wow fadeInUp" data-widget="button" data-preset="4" data-spacing="aala"> 
              <a href="" routerLink="our-achievements"  data-action="page" class="moto-widget-button-link moto-size-medium moto-link"
            data-anchor=""
         data-size="medium"><span class="fa moto-widget-theme-icon"></span> <span class="moto-widget-button-label">CLICK HERE</span></a> </div>
            <div class="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="saaa">
              <div class="moto-widget-spacer-block" style="height: 1px;"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div data-css-name="moto-container_content_56432492" class="moto-widget moto-widget-container moto-container_content_56432492" data-widget="container" data-container="container">
      <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
        <div class="container-fluid">
          <div class="row">
            <div class="moto-cell col-sm-12" data-container="container">
              <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default wow fadeInRight moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="lama">
                <div class="moto-widget-text-content moto-widget-text-editable">
                  <p style="text-align: center;" class="moto-text_system_4">GALLERY<br>
                  </p>
                </div>
              </div>
              <div class="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="masa">
                <div class="moto-widget-spacer-block" style="height: 0px;"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
        <div class="container-fluid">
          <div class="row">
            <div class="moto-cell col-sm-12" data-container="container">


               <div class="container-fluid">
                  <div class="row">
                    <div class="col-sm-4" *ngFor="let image of albums; let i=index" style="margin-bottom: 30px;">
                      <div class="image-box">
                        <img [src]="image.thumb" (click)="open(i)" class="moto-widget-grid-gallery-image" style="cursor: pointer;" />
                        <i class="fa fa-expand fa-2x" style="display: none; color: white;"></i>
                        <!-- <div class="caption caption-empty">
                          <p></p>
                        </div> -->
                      </div>
                    </div>
                  </div>
                  
                </div>  

              <div class="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="masa">
                <div class="moto-widget-spacer-block" style="height: 0px;"></div>
              </div>

<!--               <div class="tcenter moto-widget moto-widget-button moto-preset-4 moto-align-center moto-spacing-top-medium moto-spacing-right-auto moto-spacing-left-auto " data-widget="button" data-preset="4" data-spacing="mala"> 
                <a href="#"   data-action="page" class="moto-widget-button-link moto-size-medium moto-link" data-anchor="" data-size="medium"><span class="fa moto-widget-theme-icon"></span> <span class="moto-widget-button-label">VIEW ALL</span></a>
              </div>
 -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="moto-widget moto-widget-row" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-12" data-container="container">
            <div class="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="mama">
              <div class="moto-widget-spacer-block" style="height: 20px;"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="moto-widget moto-widget-row row-fixed carousel-row" data-widget="row" style="margin-bottom: 24px;">
      <div class="container-fluid">
	  	
          <ngx-slick-carousel class="carousel" 
            #slickModal="slick-carousel" 
            [config]="slideConfig" >
              <div ngxSlickItem *ngFor="let slide of client_slides" class="slide">
              <img src="{{ slide.img }}" alt="" width="100%">
              </div>
          </ngx-slick-carousel>
		
      </div>
    </div>

  </section>

</div>