import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomepageComponent } from './components/homepage/homepage.component';

import { AboutUsComponent } from './components/about-us/about-us.component';
import { AmorphousCoreTransformersComponent } from './components/amorphous-core-transformers/amorphous-core-transformers.component';
import { ApplyComponent } from './components/apply/apply.component';
import { ApplyOnlineComponent } from './components/apply-online/apply-online.component';
import { AwardsComponent } from './components/awards/awards.component';
import { CareerComponent } from './components/career/career.component';
import { CertificatesComponent } from './components/certificates/certificates.component';
import { ChairmanMessageComponent } from './components/chairman-message/chairman-message.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { CurrentOpeningsComponent } from './components/current-openings/current-openings.component';
import { DownloadsComponent } from './components/downloads/downloads.component';
import { DryTypeTransformersComponent } from './components/dry-type-transformers/dry-type-transformers.component';
import { EnvironmentalComponent } from './components/environmental/environmental.component';
import { FonttestComponent } from './components/fonttest/fonttest.component';
import { MailwithattachmentComponent } from './components/mailwithattachment/mailwithattachment.component';
import { OurAchievementsComponent } from './components/our-achievements/our-achievements.component';
import { OurCustomersComponent } from './components/our-customers/our-customers.component';
import { OurManufacturingComponent } from './components/our-manufacturing/our-manufacturing.component';
import { OurPresenceComponent } from './components/our-presence/our-presence.component';
import { PowerTransformersComponent } from './components/power-transformers/power-transformers.component';
import { ProductsComponent } from './components/products/products.component';
import { QualityTestingAasurancesComponent } from './components/quality-testing-aasurances/quality-testing-aasurances.component';
import { SinglePhaseTransformersComponent } from './components/single-phase-transformers/single-phase-transformers.component';
import { SpecialPurposeTransformersComponent } from './components/special-purpose-transformers/special-purpose-transformers.component';
import { TechnicalConsultancyComponent } from './components/technical-consultancy/technical-consultancy.component';
import { ThreePhaseTransformersComponent } from './components/three-phase-transformers/three-phase-transformers.component';
import { D3CoreTransformersComponent } from './components/d3-core-transformers/d3-core-transformers.component';


const routes: Routes = [
	
	{
		path: '',
		component: HomepageComponent,
		data: { stateName: '' }
	},
	{
		path: 'about-us',
		component: AboutUsComponent,
		data: { stateName: 'about' }
	},
	{
		path: 'amorphous-core-transformers',
		component: AmorphousCoreTransformersComponent,
		data: { stateName: 'amorphous' }
	},
	{
		path: 'apply',
		component: ApplyComponent,
		data: { stateName: 'apply' }
	},
	{
		path: 'apply',
		component: ApplyComponent,
		data: { stateName: '' }
	},
	{
		path: 'apply-online',
		component: ApplyOnlineComponent,
		data: { stateName: 'apply-online' }
	},
	{
		path: 'awards',
		component: AwardsComponent,
		data: { stateName: 'awards' }
	},
	{
		path: 'career',
		component: CareerComponent,
		data: { stateName: 'career' }
	},
	{
		path: 'certificates',
		component: CertificatesComponent,
		data: { stateName: 'certificates' }
	},
	{
		path: 'chairman-message',
		component: ChairmanMessageComponent,
		data: { stateName: 'chairman' }
	},
	{
		path: 'contacts',
		component: ContactsComponent,
		data: { stateName: 'contacts' }
	},
	{
		path: 'current-openings',
		component: CurrentOpeningsComponent,
		data: { stateName: 'current' }
	},
	{
		path: '3d-core-transformers',
		component: D3CoreTransformersComponent,
		data: { stateName: '3d' }
	},
	{
		path: 'downloads',
		component: DownloadsComponent,
		data: { stateName: 'downloads' }
	},
	{
		path: 'dry-type-transformers',
		component: DryTypeTransformersComponent,
		data: { stateName: 'dry' }
	},
	{
		path: 'environmental',
		component: EnvironmentalComponent,
		data: { stateName: 'environmental' }
	},
	{
		path: 'fonttest',
		component: FonttestComponent,
		data: { stateName: 'fonttest' }
	},
	{
		path: 'mail-with-attachment',
		component: MailwithattachmentComponent,
		data: { stateName: 'mail' }
	},
	{
		path: 'our-achievements',
		component: OurAchievementsComponent,
		data: { stateName: 'achievements' }
	},
	{
		path: 'our-customers',
		component: OurCustomersComponent,
		data: { stateName: 'customers' }
	},
	{
		path: 'our-manufacturing',
		component: OurManufacturingComponent,
		data: { stateName: 'manufacturing' }
	},
	{
		path: 'our-presence',
		component: OurPresenceComponent,
		data: { stateName: 'presence' }
	},
	{
		path: 'power-transformers',
		component: PowerTransformersComponent,
		data: { stateName: 'transformers' }
	},
	{
		path: 'products',
		component: ProductsComponent,
		data: { stateName: 'products' }
	},
	{
		path: 'quality-testing-aasurances',
		component: QualityTestingAasurancesComponent,
		data: { stateName: 'quality' }
	},
	{
		path: 'single-phase-transformers',
		component: SinglePhaseTransformersComponent,
		data: { stateName: 'single' }
	},
	{
		path: 'special-purpose-transformers',
		component: SpecialPurposeTransformersComponent,
		data: { stateName: 'special' }
	},
	{
		path: 'technical-consultancy',
		component: TechnicalConsultancyComponent,
		data: { stateName: 'technical' }
	},
	{
		path: 'three-phase-transformers',
		component: ThreePhaseTransformersComponent,
		data: { stateName: 'three' }
	}




];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
