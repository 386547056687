import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-three-phase-transformers',
  templateUrl: './three-phase-transformers.component.html',
  styleUrls: ['./three-phase-transformers.component.css']
})
export class ThreePhaseTransformersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
