<section id="section-content" class="content page-3 moto-section" data-widget="section" data-container="section">
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-12" data-container="container">
            <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default                              wow fadeInRight moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="lala">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p style="text-align: center;" class="moto-text_system_4">Special Purpose Transformers<br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-6" data-container="container">
            <div data-animation="fadeInLeft" class="moto-widget moto-widget-text moto-preset-default wow fadeInLeft moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_normal">Special Purpose Transformers (SPMs) are explained by its name itself, these are made for a specific purpose / application. From designing to manufacturing everything is special and specific. Few types of SPMs are listed below :<br><br>
•	Dual Ratio Transformers<br>
•	Auto Transformers<br>
•	Rectifier Transformers<br>
•	Reactor Transformers<br>
•	Earthing Transformers<br>
•	LT – LT Transformers<br>
•	Dual Secondary Transformer for Solar PV Application<br>
•	Package Sub Station Transformer with RMU & LVDB<br><br>
KOTSONS designs and manufactures custom designed special transformers for industrial and power applications. We offer customized maintenance and service solutions for transformers with state-of-the-art machinery & equipment and testing facilities.<br><br>
In KOTSONS each transformer is designed as a new product and customized to comply with specific customer requirements. The KOTSONS engineering team covers the complete design of a transformer, from designing of core and coils to the tank and the supporting structures with proper cooling system and full protection. <br><br>
Transformer engineering begins from electromagnetic and thermal design of core and windings. This activity is performed by means of standardized calculation sheets developed by our Design Department over the years. 2D and 3D FEM software tools are used for particular studies, such as: short-circuit impedances of phase-shifted multi-winding transformers, eddy </p>
              </div>
            </div>
          </div>
          <div class="moto-cell col-sm-6" data-container="container">
            <div class="moto-widget moto-widget-image moto-preset-default moto-align-center moto-align-center_mobile-h moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto wow fadeInRight" data-preset="default" data-spacing="aasa" data-widget="image">
              <div class="moto-widget-image-link"> <img src="./assets/images/specialpurpose-img1.jpg" class="moto-widget-image-picture" data-id="134" title=""  alt="" draggable="false"> </div>
			  <div class="clr20"></div>
			  <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_normal">current and stray losses calculation due to harmonics, hot-spots calculation and localization on windings and carpentry, mechanical stresses due to short-circuit, insulation assessment.<br><br>
Transformer mechanical design is carried out using 3D software tools allowing dimensions optimization, mechanical stress evaluation and taking into account special external conditions (Seismic Zone, Low Noise Level).<br><br>
Each transformer is supplied with a set of basic documents describing its features and functionalities, such as fabrication drawings, HV/LV connection drawings, auxiliary circuit / schematic wiring diagrams, cooling system drawings, user's manual.</p>
              </div>
			  

            </div>
            
          </div>
        </div>
      </div>
    </div>
    
   <div class="clr60"></div>
    
    
  <div data-stellar-background-ratio="0.5" data-css-name="moto-container_content_specialpurpose" class="moto-widget moto-widget-container moto-parallax moto-container_content_specialpurpose" data-widget="container" data-container="container">
    <div class="moto-widget moto-widget-spacer moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="lama">
      <div class="moto-widget-spacer-block" style="height: 35px;"></div>
    </div>
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-2" data-container="container"></div>
          <div class="moto-cell col-sm-8" data-container="container">
            <div data-animation="fadeIn" class="moto-widget moto-widget-text moto-preset-default wow fadeIn moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-small moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sasa">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p style="text-align: center;" class="moto-text_system_5">Quality service<br>
                </p>
                <p style="text-align: center;" class="moto-text_system_5">is our aim<br>
                </p>
              </div>
            </div>
          </div>
          <div class="moto-cell col-sm-2" data-container="container"></div>
        </div>
      </div>
    </div>
    <div class="moto-widget moto-widget-spacer moto-preset-default              moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="spacer" data-preset="default" data-spacing="lama">
      <div class="moto-widget-spacer-block" style="height: 35px;"></div>
    </div>
  </div> 
    
   
    
  </section>