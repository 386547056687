<section id="section-content" class="content page-9 moto-section" data-widget="section" data-container="section">
    <div class="moto-widget moto-widget-row row-fixed" data-widget="row">
      <div class="container-fluid">
        <div class="row">
          <div class="moto-cell col-sm-12" data-container="container">
            <div data-animation="fadeInRight" class="moto-widget moto-widget-text moto-preset-default       wow moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto fadeInRight" data-widget="text" data-preset="default" data-spacing="lala">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p style="text-align: center;" class="moto-text_system_4">ENVIRONMENTAL POLICY<br>
                </p>
              </div>
            </div>
            <div class="moto-widget moto-widget-text moto-preset-default      moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_system_9"><img src="./assets/images/Environmental-Policy.jpg" alt="Environmental-Policy"> <br><br><br>
                </p>
              </div>
            </div>
            <div class="moto-widget moto-widget-text moto-preset-default      moto-spacing-top-auto moto-spacing-right-auto  moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aala">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_normal">“We at KOTSONS are committed to design, manufacture & supply transformers of agreed quality on time, every time to the satisfaction of our customers<br><br>
                </p>
              </div>
            </div>
            <div class="moto-widget moto-widget-text moto-preset-default      moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_system_9">KOTSONS is further committed to prevention of pollution in all forms from its operations and will comply to all applicable legal requirements. <br>
                </p>
              </div>
            </div>
            <div class="moto-widget moto-widget-text moto-preset-default      moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aala">
              <div class="moto-widget-text-content moto-widget-text-editable">
                <p class="moto-text_normal">KOTSONS shall continually improve the effectiveness of the integrated management system.”<br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>