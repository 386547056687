import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-environmental',
  templateUrl: './environmental.component.html',
  styleUrls: ['./environmental.component.css']
})
export class EnvironmentalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  	
  }

}
