import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quality-testing-aasurances',
  templateUrl: './quality-testing-aasurances.component.html',
  styleUrls: ['./quality-testing-aasurances.component.css']
})
export class QualityTestingAasurancesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  	
  }

}
