import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fonttest',
  templateUrl: './fonttest.component.html',
  styleUrls: ['./fonttest.component.css']
})
export class FonttestComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  	
  }

}
